import React from 'react'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { PrivateRoute } from './private.route'

// import { SignUp, SignIn } from './components'
import SignIn from '@pages/account/signin'
import SignUp from '@pages/account/signup'
import SigninViaJWT from '@pages/account/signinviajwt'
import Dashboard from '@pages/dashboard'
import Overview from '@pages/account/overview'
import ForgotPassword from '@pages/account/resetpassword'
import UpdatePassword from '@pages/account/updatepassword'
import SiteManagement from '@pages/sites/sitemanagement'
import SiteContent from '@pages/sites/content/sitecontent'
import Setting from '@pages/sites/setting/setting'
import Build from '@pages/sites/deploy/build'
import Plan from '@pages/sites/plan/plan'
import FormManagement from '@pages/sites/forms/formmanagement'
import FormSubmission from '@pages/sites/forms/formsubmission'
import Analytics from '@pages/sites/analytics/analytics'
import AnalyticsData from '@pages/sites/analytics/analyticsdata'
import Payment from '@pages/payment/payment'
import Help from './pages/help/help'
import Page404 from '@pages/404'

// Styles and Plugins Section
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './animation.css'
import './media.css'
import './App.css'

const cache = new InMemoryCache()

const link = new HttpLink({
  cache,
  uri: process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:4000/',
  headers: {
    authorization: `Bearer ${localStorage.token}`
  }
})

const client = new ApolloClient({
  link,
  cache
})

function App(props) {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <main>
            <Switch>
              <Redirect exact from="/" to={localStorage.token ? '/dashboard' : '/signin'} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <Route path="/reset-password" component={ForgotPassword} />
              <Route exact path="/signin/jwt/:token" component={SigninViaJWT} />
              <Route path="/signup" component={SignUp} />
              <Route path="/signin" component={SignIn} />
              <PrivateRoute path="/account/update-password" component={UpdatePassword} />
              <PrivateRoute path="/account/overview" component={Overview} />
              <PrivateRoute path="/account/payment_methods" component={Payment} />
              <PrivateRoute exact path="/sites/:siteId" component={SiteManagement} />
              <PrivateRoute exact path="/sites/:siteId/content" component={SiteContent} />
              <PrivateRoute exact path={`/sites/:siteId/forms`} component={FormManagement} />
              <PrivateRoute
                exact
                path={`/sites/:siteId/forms/:formId/submissions`}
                component={FormSubmission}
              />
              <PrivateRoute exact path="/sites/:siteId/settings" component={Setting} />
              <PrivateRoute exact path="/sites/:siteId/deploys" component={Build} />
              <PrivateRoute exact path="/sites/:siteId/analytics" component={Analytics} />
              <PrivateRoute
                exact
                path="/sites/:siteId/analytics/webriq-analytics"
                component={AnalyticsData}
              />
              <PrivateRoute exact path="/sites/:siteId/plans" component={Plan} />
              <PrivateRoute exact path="/help" component={Help} />
              <Route component={Page404} />
            </Switch>
          </main>
        </div>
      </Router>
    </ApolloProvider>
  )
}

export default App
