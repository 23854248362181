import React from 'react'
import { Modal } from 'react-bootstrap'
import './loading-modal.css'

import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { size } from 'polished'

import { ReactComponent as Logo } from '../../images/logo.svg'
import { colors } from './loading-emotion-styles'

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Loading = styled(Logo)(size(98), {
	display: 'block',
	margin: 'auto',
	fill: colors.blue,
	path: {
		transformOrigin: 'center',
		animation: `${spin} 1s linear infinite`
	}
})

function LoadingModal(props) {
	return (
		<>
			<Modal size="sm" show={true} centered animation={false} className="loading-modal">
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent">
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="form-container border-0">
						<div className="form-heading mb-4 text-center">
							<h6 className="font-weight-normal">{props.text}</h6>
							<Loading />
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default LoadingModal
