import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { size } from 'polished'

import { ReactComponent as Logo } from '../../images/logo.svg'
import { colors } from './loading-emotion-styles'

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Loading = styled(Logo)(size(98), {
	display: 'block',
	margin: 'auto',
	marginTop: '21%',
	fill: colors.blue,
	path: {
		transformOrigin: 'center',
		animation: `${spin} 1s linear infinite`
	}
})

export default Loading
