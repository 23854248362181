import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { Container } from 'react-bootstrap'
import { Layout, PageHeader, Loading, AllSitesDropdown, Panel } from '@app/index'
import {
  // SiteGitHubAuthorizationSetupPanel,
  SiteManageContentViaSanityOrStrapiPanel,
  ManageContentViaWebriQCMSPanel
  // ManageContentOfShopPanel
} from '@app/index'

// import { ErrorPanel } from '@app/index'

import '@fortawesome/fontawesome-free/css/all.css'
import './sitecontent.css'

const get = require('lodash.get')

function SiteContent(props) {
  const { siteId } = useParams()

  const { data: siteData, loading: siteLoading, error: siteError } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  // console.log('siteData', siteData)

  if (siteLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <Layout>
      <PageHeader
        pageName="Site Management / Content"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Content', url: `/sites/${siteId}/content` }
        ]}
      />

      <section id="site-managment" className="component pt-3">
        <Container>
          <div className="row">
            <div className="col-12">
              {/*(get(siteData, 'site.content.error') ||
                siteError ||
                get(siteData, 'site.content.data.type', null) === 'shops') && ()*/}

              {/*get(siteData, 'site.content.data.type', null) === 'shops' && (
                <ManageContentOfShopPanel siteData={siteData} />
              )*/}

              {(get(siteData, 'site.content.data.webriq_api', false) && (
                <ManageContentViaWebriQCMSPanel siteData={siteData} />
              )) ||
                (get(siteData, 'site.content.data.is_strapi', false) &&
                  get(siteData, 'site.content.data.is_sanity', false) && (
                    <SiteManageContentViaSanityOrStrapiPanel data={siteData} />
                  )) || (
                  <Panel
                    title="Manage Content"
                    className="accordion-setting bg-transparent"
                    classButton="text-left h6 w-100 shadow-none m-0 border-0"
                  >
                    <div>
                      <br />
                      <div className="col-md-12">
                        <div className="note js-note green">
                          <h3 className="note-title2">Manage Content via Old App</h3>
                          <br />
                          <p>
                            Unfortunately at the moment this request can't be handled here. Please
                            go back by the button below:
                          </p>
                          <br />
                          <a
                            href={`https://app.webriq.com/sites/${siteId}/content`}
                            className="btn btn-primary"
                          >
                            Manage Content
                          </a>
                        </div>
                      </div>
                    </div>
                  </Panel>
                )}
            </div>
          </div>

          <AllSitesDropdown />
        </Container>
      </section>
    </Layout>
  )
}

export default SiteContent

const GET_SITE_BY_ID = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      primaryOwner
      role
      primaryDomain
      subdomain
      url
      isActive
      isSecure
      createdAt
      updatedAt
      content
    }
  }
`
