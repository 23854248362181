import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
// import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_FORM, GET_FORMS_BY_SITE_ID } from '../../../graphql/graphql'

function UpdateForm(props) {
	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = () => setShow(false)

	const { siteId, formId } = props

	const [testUrls, setTestUrls] = useState(props.testUrls)
	const [subject, setSubject] = useState(props.subject)
	const [emailTo, setEmailTo] = useState(props.emailTo)
	const [webhookUrl, setWebhookUrl] = useState(props.webhooks)

	const [updateForm, { loading, error }] = useMutation(UPDATE_FORM)

	console.log(props.subject)

	if (loading) {
		return (
			<div className="mt-0 mb-4">
				<Button>
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
					&nbsp;Update Site
				</Button>
			</div>
		)
	}

	if (error) {
		return <small>Error</small>
	}

	const updateFormSubmit = e => {
		e.preventDefault()
		updateForm({
			variables: { siteId, formId, testUrls, subject, emailTo, webhookUrl },
			refetchQueries: [{ query: GET_FORMS_BY_SITE_ID, variables: { siteId, formId } }]
		})
		setShow(false)
	}

	const updateEmailTo = e => {
		const recipient = e.target.value.split(',').map(item => item.trim())
		setEmailTo(recipient)
		return recipient
	}

	const updateTestUrls = e => {
		const testurl = e.target.value.split(',').map(item => item.trim())
		setTestUrls(testurl)
		return testurl
	}

	return (
		<div>
			<div className="mt-0 mb-4">
				<Button type="submit" onClick={handleShow}>
					Update Site
				</Button>
			</div>

			<Modal size="md" show={show} onHide={handleClose} centered animation={false}>
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					{' '}
					<div className="form-container bg-white border-0">
						<div className="form-heading mb-4 text-center">
							<h4 className="font-weight-normal">Update Form Details </h4>
						</div>
						<div className="text-left">
							<Form>
								<Form.Group className={subject ? 'active' : null}>
									<Form.Label>{subject ? 'Subject:' : 'Please add subject'}</Form.Label>
									<Form.Control
										type="text"
										onChange={e => setSubject(e.target.value)}
										value={subject}
									/>
								</Form.Group>
								<Form.Group className={emailTo ? 'active' : null}>
									<Form.Label>
										{emailTo ? (
											<div>
												Recipient: <small>separated with comma</small>
											</div>
										) : (
											<div>Please add email recipients separated with comma</div>
										)}
									</Form.Label>
									<Form.Control type="text" onChange={e => updateEmailTo(e)} value={emailTo} />
								</Form.Group>
								<Form.Group className={webhookUrl ? 'active' : null}>
									<Form.Label>{webhookUrl ? 'Webhooks:' : 'Please add webhook'}</Form.Label>
									<Form.Control
										type="text"
										onChange={e => setWebhookUrl(e.target.value)}
										value={webhookUrl}
									/>
								</Form.Group>
								<Form.Group className={testUrls ? 'active' : null}>
									<Form.Label>
										{testUrls ? (
											<div>
												Test URLs: <small>separated with comma</small>
											</div>
										) : (
											<div>Please add test urls separated with comma</div>
										)}
									</Form.Label>
									<Form.Control type="text" onChange={e => updateTestUrls(e)} value={testUrls} />
								</Form.Group>
								<Form.Group className="text-center mt-4 mb-0">
									<Button onClick={e => updateFormSubmit(e)}>Save</Button>
									<Button type="submit" variant="link" onClick={handleClose}>
										Cancel
									</Button>
								</Form.Group>
							</Form>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default UpdateForm
