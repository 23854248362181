import React from 'react'
import { Col } from 'react-bootstrap' //Form, Button
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import Moment from 'react-moment'
import { GET_FORMS_BY_SITE_ID } from '../../../graphql/graphql'
import { Link } from 'react-router-dom'

import { Panel } from '../../index'
import { LoadingPanel, ErrorPanel } from '@app/index'

function FormSubmissionsComponent(props) {
  const { data, loading, error } = useQuery(GET_FORMS_BY_SITE_ID, {
    variables: { siteId: props.siteId }
  })

  if (loading) {
    return (
      <Col md={`${6} mb-5 mb-md-0`}>
        <LoadingPanel
          title="Recent Form Submissions"
          subTitle="Latest form submissions across different site forms in your site..."
        />
      </Col>
    )
  }

  if (error) {
    return (
      <ErrorPanel
        title="Recent Form Submissions"
        subTitle="Latest form submissions across different site forms in your site..."
        error={error}
      />
    )
  }

  const formsWithSubmissions =
    (data &&
      data.forms &&
      data.forms &&
      data.forms
        .filter(data => data && data.submissions && data.submissions.length > 0)
        .reverse()) ||
    null

  return (
    <Col md={`${6} mb-5 mb-md-0`}>
      <Panel
        title="Recent Form Submissions"
        subTitle="Latest form submissions across different site forms in your site..."
        className="accordion-setting bg-transparent"
        classButton="text-left h6 w-100 shadow-none m-0 border-0"
      >
        {formsWithSubmissions && formsWithSubmissions.length > 0 ? (
          formsWithSubmissions.map(submission => (
            <div className="row py-2" key={submission.id}>
              <div className="item-code col-10 col-sm-2">
                <p className="text-truncate text-primary">
                  <strong>{get(submission, 'id')}</strong>
                  <br />
                </p>
              </div>
              <div className="item-option col-2 col-sm-1">
                <p>
                  <strong>{get(submission, 'submissions.length')}</strong>
                </p>
              </div>
              <div className="item-info col-10 col-sm-7">
                <p className="mb-1">
                  <strong>{get(submission, 'name', 'No Form Name')}</strong>
                </p>
                {/*<p className="text-muted mb-1">Eleazar Junsan</p>*/}
                <p className="mb-1">{get(submission, 'notifications.email.to', 'No Recipients')}</p>
                <p className="text-date">
                  <Moment format="MMM DD, YYYY hh:mm A" withTitle>
                    {get(submission, 'createdAt')}
                  </Moment>
                  {/*21 Aug, 2019 - 2:00pm
                  {get(submission, 'createdAt')}*/}
                </p>
              </div>
              <div className="item-action col-2">
                <Link
                  to={`/sites/${props.siteId}/forms/${submission.id}/submissions`}
                  className="text-primary btn-tool"
                >
                  <i className="fas fa-external-link-alt" />
                </Link>
              </div>
            </div>
          ))
        ) : (
          <p className="text-muted">
            No submissions yet as of the moment.
            <br />
          </p>
        )}
        {/*
        <div className="row py-2">
          <div className="item-code col-10 col-sm-2">
            <p className="text-truncate text-warning">
              <strong>6dec3fdcx34fdad340x9e4aa99e8</strong>
              <br />
            </p>
          </div>
          <div className="item-option col-2 col-sm-1">
            <p>
              <strong>1</strong>
            </p>
          </div>
          <div className="item-info col-10 col-sm-7">
            <p className="mb-1">
              <strong>Inactive Form</strong>
            </p>
            <p className="text-muted mb-1">Eljun Galicia</p>
            <p className="mb-1">eljunz@gmail.com</p>
            <p className="text-date">21 Aug, 2019 - 2:00pm</p>
          </div>
          <div className="item-action col-2">
            <a className="text-primary btn-tool" href="/">
              <i className="fas fa-external-link-alt" />
            </a>
          </div>
        </div>
        */}
      </Panel>
    </Col>
  )
}

export default FormSubmissionsComponent
