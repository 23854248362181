import React from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { CustomForm, Layout } from '../../components'

class UpdatePasswordForm extends React.Component {
  render() {
    return (
      <Layout>
        <section id="update-password" className="component">
          <Container fluid className="px-md-5">
            <Row className="justify-content-center">
              <Col md={5} xl={4}>
                <div className="form-container shadow bg-white border-0">
                  <div className="form-heading text-center mb-4">
                    <h3 className="font-weight-normal">Login and security</h3>
                    <p className="text-secondary pb-3">
                      Enter current password and new password to update.
                    </p>
                  </div>
                  <CustomForm>
                    <Form.Group>
                      <Form.Control type="password" required autoFocus={true} />
                      <label>Current password</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control type="password" required />
                      <label>New password</label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control type="password" required />
                      <label>Re-type new password</label>
                    </Form.Group>
                    <Form.Group className="mb-0 text-center">
                      <Button type="submit">Update Password</Button>
                    </Form.Group>
                  </CustomForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default UpdatePasswordForm
