import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Container, Row, Col } from 'react-bootstrap'
import { Layout, AddPaymentMethod, LoadingModal, CustomCard, Error } from '../../components'
import { GET_ALL_BILLINGS, ADD_PAYMENT_METHOD, DELETE_PAYMENT_METHOD } from '../../graphql/graphql'
import './payment.css'

function Payment(props) {
  const { data, loading, error } = useQuery(GET_ALL_BILLINGS)

  const [addBilling, { loading: addBillingLoading }] = useMutation(ADD_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_ALL_BILLINGS }]
  })

  const [removeBilling, { loading: removeBillingLoading }] = useMutation(DELETE_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_ALL_BILLINGS }]
  })

  if (loading)
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  if (addBillingLoading)
    return (
      <Container>
        <LoadingModal text="Adding Payment Method" />
      </Container>
    )
  if (removeBillingLoading)
    return (
      <Container>
        <LoadingModal text="Deleting Payment Method" />
      </Container>
    )

  if (error) {
    return <Error />
  }

  return (
    <Layout>
      <section id="payment" className="component">
        <Container fluid className="px-md-5">
          <Row className="justify-content-center">
            <Col md={9}>
              <div className="form-container shadow bg-white border-0">
                <div className="form-heading mb-4">
                  <h3 className="font-weight-normal">Payment Methods</h3>
                  <p className="text-secondary pb-3">
                    List of all payment methods you added. The <strong>DEFAULT</strong> will be used
                    for future billing cycles.
                  </p>
                </div>
                <Row>
                  <CustomCard>
                    <div>
                      <AddPaymentMethod
                        addBilling={addBilling}
                        html=<i className="fas fa-plus iconize-blue mx-auto" />
                      />
                      <span className="d-block">
                        <strong>Add</strong>
                        <br />
                      </span>
                      <p>New payment method</p>
                    </div>
                  </CustomCard>
                  {data &&
                    data.billings &&
                    data.billings.map(billing => (
                      <CustomCard
                        key={billing.id}
                        payment={true}
                        billingId={billing.id}
                        removeBilling={removeBilling}
                      >
                        <div className="w-100">
                          <i className="fas fa-wallet iconize-blue mx-auto cursor-pointer" />
                          <span className="d-block">
                            <strong>Name: </strong>
                            {billing.name}
                          </span>
                          <span className="d-block">
                            <strong>Last 4 Digit: </strong>
                            {billing.last4.slice(-4)}
                          </span>
                          <span className="d-block">
                            <strong>Expiry Date: </strong>
                            {`${billing.expMonth}/${billing.expYear.toString().slice(-2)}`}
                          </span>
                        </div>
                      </CustomCard>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Payment
