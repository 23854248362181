import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Container, Row, Col } from 'react-bootstrap'
import {
  Panel,
  Layout,
  AllSitesDropdown,
  PageHeader,
  FakeForm,
  Error,
  FormDetails,
  FormSubmissionsData,
  DeleteAllFormSubmissions
} from '../../../components'
import { GET_SITE_BY_ID, GET_FORMS_SUBMISSIONS } from '../../../graphql/graphql'
import './formsubmission.css'

export default function FormSubmission(props) {
  const { siteId, formId } = props.match.params
  // Get Site URl
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  // Get all form submissions
  const {
    data: formSubmissionsData,
    loading: formSubmissionsLoading,
    error: formSubmissionsError
  } = useQuery(GET_FORMS_SUBMISSIONS, {
    variables: {
      siteId,
      formId
    }
  })

  if (formSubmissionsError) {
    return <Error />
  }

  return (
    <Layout>
      <PageHeader
        pageName="Form Submissions"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Forms', url: `/sites/${siteId}/forms` },
          { order: 4, name: 'Submissions', url: `/sites/${siteId}/forms/submissions/${formId}` }
        ]}
      />
      <section id="form-inbox" className="component pt-3">
        <Container>
          <Row>
            <Col xs={12}>
              <Panel
                title="Form Details"
                className="accordion-setting bg-transparent"
                classButton="text-left h6 w-100 shadow-none m-0 border-0"
              >
                <FormDetails siteId={siteId} formId={formId} />
              </Panel>
              <Panel
                title="Form Submissions"
                className="accordion-setting bg-transparent"
                classButton="text-left h6 w-100 shadow-none m-0 border-0"
              >
                <div className="d-flex align-items-center row py-2">
                  <div className="item-option col-8 col-md-4">
                    <div className="form-check form-check-inline" />
                  </div>
                  <div className="d-none d-sm-none d-md-inline-block item-info col-md-5" />
                  <div className="d-inline-block item-action col-4 col-md-3">
                    <DeleteAllFormSubmissions siteId={siteId} formId={formId} />
                  </div>
                </div>

                {formSubmissionsLoading ? <FakeForm /> : null}

                {!formSubmissionsLoading &&
                formSubmissionsData &&
                formSubmissionsData.formSubmissions.length > 0 ? null : (
                  <p>No submissions yet. Check back later!</p>
                )}

                {!formSubmissionsLoading &&
                  formSubmissionsData.formSubmissions.map(submission => {
                    return (
                      <FormSubmissionsData
                        submission={submission}
                        key={submission.id}
                        siteId={siteId}
                        formId={formId}
                      />
                    )
                  })}
              </Panel>
            </Col>
          </Row>
          <AllSitesDropdown />
        </Container>
      </section>
    </Layout>
  )
}
