import React from 'react'
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import { LoadingPanel, ErrorPanel, Panel, CustomForm } from '@app/index'
import ContentLoader from 'react-content-loader'
import { Link } from 'react-router-dom'
import { ExternalIcon } from '../../../images'

export default function ManageContentViaSanityOrStrapiPanel({ siteId }) {
  return (
    <Panel
      title="Manage Content"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div style={{ marginTop: `20px` }}>
        <br />

        <h5>Hooray, just click the button below to login and manage your content.</h5>
        <br />

        <p className="note-description">
          Subdomain:{' '}
          <a href="{{ $build_env['API_URL'] }}" target="_blank">
            $build_env['API_URL']
          </a>
        </p>
        <br />
        <div className="row col-md-6">
          <a href="{{ $build_env['API_URL'] }}/admin" data-href="" className="btn btn-primary">
            <i className="fa fa-pencil-square-o"></i> Manage site now!
          </a>
        </div>

        <div className="clearfix mb-4"></div>
      </div>
    </Panel>
  )
}
