import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ENABLE_ANALYTICS, GET_ANALYTICS_BY_SITE_ID } from '../../../graphql/graphql'
import ContentLoader from 'react-content-loader'

const EnableWebriqAnalytics = props => {
	const [enableAnalytics, { loading }] = useMutation(ENABLE_ANALYTICS)

	const handleEnableAnalytics = e => {
		enableAnalytics({
			awaitRefetchQueries: true,
			variables: { siteId: props.siteId },
			refetchQueries: [{ query: GET_ANALYTICS_BY_SITE_ID, variables: { siteId: props.siteId } }]
		})
	}

	if (loading) {
		return (
			<Col md={4} lg={3}>
				<Card className="border-1">
					<div>
						<Link className="item-link-wrapper" to="/" />
						<div className="card-resizer" />
						<div className="invisible card-heading" />
						<div className="card-body text-center">
							<div className="item-logo">
								<Link className="text-primary item-logo-text" to="/">
									W
								</Link>
							</div>
							<div className="item-name">
								<h6 className="text-truncate">
									<ContentLoader height={30}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="30" />
									</ContentLoader>
								</h6>
							</div>
							<div className="item-domain">
								<p>
									<ContentLoader height={20}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
									</ContentLoader>
									<ContentLoader height={20}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
									</ContentLoader>
									<ContentLoader height={20}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
									</ContentLoader>
								</p>
							</div>
							<div className="item-status">
								<p className="text-muted text-small">
									<ContentLoader height={20}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
									</ContentLoader>
								</p>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12 text-right text-md-center">
									<ContentLoader height={50}>
										<rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
									</ContentLoader>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</Col>
		)
	}

	return (
		<Col md={4} lg={3}>
			<Card className="border-1">
				<div>
					<div className="card-resizer" />
					<div className="invisible card-heading" />
					<div className="card-body">
						<div className="item-logo">
							<span className="text-primary item-logo-text">W</span>
						</div>
						<div className="item-name">
							<h6 className="text-truncate">Webriq Analytics</h6>
						</div>
						<div className="item-domain">
							<p>
								Powered by Matomo, we bring you a much simpler way to view your site analytics data.
							</p>
						</div>
						<div className="item-status">
							<p className="text-muted text-small">
								<i className="fas fa-circle text-small text-warning mr-1" />
								Analytics is inactive
							</p>
						</div>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-12 text-right text-md-center">
								<button className="btn btn-primary shadow" onClick={e => handleEnableAnalytics(e)}>
									Enable Now
								</button>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</Col>
	)
}

export default EnableWebriqAnalytics
