import React, { useState } from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert, ProgressBar } from 'react-bootstrap'
import {
	SIGNIN_MUTATION,
	GET_ALL_SITES,
	GET_SITE_TEMPLATES,
	GET_SERVICES
} from '../../graphql/graphql'
import { GithubIcon } from '../../images'

function SignIn(props) {
	const client = useApolloClient()

	const [signin, { loading, error }] = useMutation(SIGNIN_MUTATION, {
		onCompleted(signin) {
			localStorage.setItem('token', signin.login)
			client.writeData({ data: { isLoggedIn: true } })
			props.history.push('/dashboard')
		},
		refetchQueries: [{ query: GET_ALL_SITES, GET_SITE_TEMPLATES, GET_SERVICES }]
	})

	if (loading) return <SignInForm signin={signin} loading={true} />
	if (error) return <SignInForm signin={signin} error={true} />
	return <SignInForm signin={signin} />
}

// SignIn Form
function SignInForm(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const onSignin = e => {
		e.preventDefault()
		props.signin({ variables: { email, password } })
	}

	return (
		<section id="signin-form" className="component">
			<Container fluid className="px-md-5">
				<Row className="justify-content-center">
					<Col md={5} xl={4}>
						<div className="form-container shadow bg-white border-0">
							<div className="form-heading text-center mb-4">
								<h3 className="font-weight-normal">Sign in</h3>
								<p className="text-secondary pb-3">to continue to Dashboard</p>
							</div>
							<Form onSubmit={onSignin}>
								{props.error && (
									<Alert variant="danger">Username/password is incorrect. Please try again!</Alert>
								)}
								{props.loading && <ProgressBar animated now={100} className="mb-4" />}

								<Form.Group className="active">
									<Form.Control
										type="email"
										name="email"
										autoFocus={true}
										placeholder="johndoe@example.com"
										data-testid="login-input"
										value={email}
										onChange={e => setEmail(e.target.value)}
										required
									/>
									<Form.Label>Email address</Form.Label>
								</Form.Group>
								<Form.Group className="active">
									<Form.Control
										required
										type="password"
										name="password"
										placeholder="Enter your password"
										data-testid="login-input"
										value={password}
										onChange={e => setPassword(e.target.value)}
									/>
									<Form.Label>Password</Form.Label>
								</Form.Group>
								<Row>
									<Col md={`${6} text-left`}>
										<label className="text-secondary">
											<input type="checkbox" name="checkbox" value="value" />
											&nbsp; Remember Me
										</label>
										{/*<Form.Group controlId="remember">
											<Form.Check type="checkbox" label="Remember Me" />
										</Form.Group>*/}
									</Col>
								</Row>

								<Row className="justify-content-between pt-4">
									<Col md={6} className="order-1 order-md-2 text-center">
										<Button type="submit" className="w-100 d-block mb-2">
											Sign In
										</Button>
										<Link className="text-secondary small" to="#">
											or signin <img src={GithubIcon} alt="github" width={20} /> with GitHub
										</Link>
									</Col>
									<Col md={`${6} order-md-1 pt-2 text-center text-md-left`}>
										<Link className="text-base" to="/signup">
											Create account.
										</Link>
									</Col>
								</Row>
							</Form>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default SignIn
