import React from 'react'

import { Panel } from '@app/index'

function LoadingPanel(props) {
  return (
    <Panel
      {...props}
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content mb-4">
        <div className="card-body card-body d-flex align-items-center justify-content-center">
          <div className="spinner-grow text-primary align-middle" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </Panel>
  )
}

export default LoadingPanel
