import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button, Spinner } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { CreateIcon, DeleteIcon } from '../../../images'
import { Panel } from '@app/index'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { LoadingPanel, ErrorPanel, CustomForm } from '@app/index'

export default function CustomScripts({ siteId }) {
  // Modal functionality
  const [showCustomScriptModal, setShowCustomScriptModal] = useState(false)
  const handleShowCustomScriptModal = e => {
    e.preventDefault()
    setShowCustomScriptModal(true)
  }
  const handleCloseCustomScriptModal = () => setShowCustomScriptModal(false)

  // New Custom Script
  const [
    newCustomScript,
    { loading: newCustomScriptLoading, networkStatus: newCustomScriptNetworkStatus }
  ] = useMutation(ADD_NEW_CUSTOM_SCRIPT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_PROVIDER_CUSTOM_SCRIPTS,
        variables: { siteId }
      }
    ]
  })

  let newCustomScriptTitle, newCustomScriptBody
  const handleSubmitCustomScript = e => {
    e.preventDefault()

    setShowCustomScriptModal(false)
    newCustomScript({
      variables: {
        siteId,
        data: {
          title: newCustomScriptTitle.value,
          content: newCustomScriptBody.value,
          position: 'BEFORE_BODY_CLOSING_TAG'
        }
      }
    })
    newCustomScriptTitle.value = ''
    newCustomScriptBody.value = ''
  }

  // Delete custom script
  const [customScriptId, setCustomScriptId] = useState(null)
  const [
    removeCustomScript,
    { loading: removeCustomScriptLoading, error: removeCustomScriptError }
  ] = useMutation(REMOVE_CUSTOM_SCRIPT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_PROVIDER_CUSTOM_SCRIPTS,
        variables: { siteId }
      }
    ]
  })
  const handleDelete = (e, customScriptId) => {
    e.preventDefault()

    setCustomScriptId(customScriptId)
    removeCustomScript({ variables: { siteId, customScriptId } })
  }
  useEffect(() => {
    if (!removeCustomScriptLoading) {
      setCustomScriptId(null)
    }
  }, [removeCustomScriptLoading])

  // Get custom scripts
  const { data, loading, error } = useQuery(GET_SITE_PROVIDER_CUSTOM_SCRIPTS, {
    variables: {
      siteId
    }
  })

  if (error) {
    return <ErrorPanel title="Custom Scripts" error={error} />
  }

  let providerCustomScripts = []
  if (data) {
    providerCustomScripts = data && data.site && data.site.providerCustomScripts
  }

  return (
    <React.Fragment>
      <Row className="pb-4">
        <Col xs={9}>
          <p className="font-weight-bold mb-0">Custom Scripts</p>
          <span className="d-block text-muted small mb-4">
            Add custom JS scripts to your site on the fly. Useful for such as analytics and other
            3rd party widgets.
          </span>

          {(loading || newCustomScriptLoading) && !providerCustomScripts.length > 0 && (
            <span className="d-block text-muted small">No custom scripts</span>
          )}

          {!loading &&
            providerCustomScripts.length > 0 &&
            providerCustomScripts.map(customScript => (
              <Row className="pb-3 align-items-center" key={customScript.id}>
                <Col xs={12} md={8}>
                  <span className="hyphens-auto">{customScript.title}</span>
                </Col>
                <Col className="col-2 text-right">
                  <a href="/" onClick={e => handleDelete(e, customScript.id)}>
                    {(customScriptId && customScriptId === customScript.id && (
                      <Spinner animation="border" variant="primary" />
                    )) || (
                      <img
                        alt="delete"
                        className="img-fluid"
                        src={DeleteIcon}
                        width="24px"
                        height="24px"
                      />
                    )}
                  </a>
                </Col>
              </Row>
            ))}

          {(loading || newCustomScriptLoading) && (
            <Row className="pb-3 align-items-center">
              <Col xs={12} md={8}>
                <ContentLoader height="20">
                  <rect x="0" y="0" rx="3" ry="3" width="80%" height="20" />
                </ContentLoader>
              </Col>
              <Col className="text-right">
                <ContentLoader height="50">
                  <rect x="80" y="0" rx="3" ry="3" width="25%" height="85%" />
                </ContentLoader>
              </Col>
            </Row>
          )}
        </Col>

        <Col xs={`${3} text-right`}>
          <a href="/" onClick={e => handleShowCustomScriptModal(e)}>
            <img alt="add" className="mb-0" src={CreateIcon} width={30} height={30} />
          </a>
        </Col>
      </Row>

      <Modal
        show={showCustomScriptModal}
        onHide={handleCloseCustomScriptModal}
        centered
        animation={false}
      >
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-4 text-center">
              <h4 className="font-weight-normal">Add custom script to your site</h4>
              <p className="text-muted">
                The script you are about to add will be automatically loaded to your site once
                saved. It'll inserted before the end body section. Be careful for not all scripts
                are safe. Make sure it came from a trusted source and you at least understand how it
                works.
              </p>
            </div>
            <CustomForm onSubmit={handleSubmitCustomScript}>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  ref={node => {
                    newCustomScriptTitle = node
                  }}
                />
                <label>Script Title</label>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="4"
                  ref={node => {
                    newCustomScriptBody = node
                  }}
                  required
                />
                <label>Script Body</label>
              </Form.Group>
              <Form.Group className="text-center mt-4 mb-0">
                <Button type="submit">Confirm Save</Button>
              </Form.Group>
            </CustomForm>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

const GET_SITE_PROVIDER_CUSTOM_SCRIPTS = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      providerCustomScripts
    }
  }
`

const ADD_NEW_CUSTOM_SCRIPT = gql`
  mutation newProviderCustomScript($siteId: ID!, $data: CustomScriptInput!) {
    newProviderCustomScript(siteId: $siteId, data: $data)
  }
`

const REMOVE_CUSTOM_SCRIPT = gql`
  mutation deleteProviderCustomScript($siteId: ID!, $customScriptId: ID!) {
    deleteProviderCustomScript(siteId: $siteId, customScriptId: $customScriptId)
  }
`
