import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout, LoadingModal, Error, ProfileUpdate } from '../../components'
import { GET_USER_PROFILE } from '../../graphql/graphql'

function Overview(props) {
  const { data, loading, error } = useQuery(GET_USER_PROFILE)
  if (loading) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }
  if (error) {
    return <Error />
  }

  return (
    <Layout>
      <section id="account-overview" className="component">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="border-0 mb-4">
                <div className="form-heading mb-4">
                  <h3>Overview</h3>
                  <p className="text-muted pb-3">
                    Here you can view and update your personal information.
                  </p>
                </div>
                <Row>
                  <Col md={4}>
                    <div className="shadow bg-white border-0 p-5 text-center mb-4">
                      <div className="profile-img mb-4">
                        {data && data.userProfile.firstname.charAt(0)}
                      </div>
                      <div className="profile-joined">
                        <h4>{data && data.userProfile && data.userProfile.firstname}</h4>
                        <p className="text-secondary mb-0">
                          Joined WebriQ on Feb 1, 2016 (4 years ago).
                        </p>
                      </div>
                      <div className="mt-5">
                        <ProfileUpdate profileInfo={data && data.userProfile} />
                      </div>
                    </div>

                    <div className="shadow bg-white border-0 p-5 text-center">
                      <h6 className="mb-3">Security</h6>
                      <Button role="button" aria-label="button">
                        Change Password
                      </Button>

                      <h6 className="mb-3 mt-4">Manage</h6>
                      <Link to="/account/payment_methods" className="btn btn-primary">
                        Payment Methods
                      </Link>
                    </div>
                  </Col>
                  <Col md={`${7} pl-md-4`}>
                    <div className="p-5 shadow bg-white border-0">
                      <div className="form-heading mb-4">
                        <h6>Personal Information</h6>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          Full Name
                        </p>
                        <p className="overview-detail">{`${(data &&
                          data.userProfile &&
                          data &&
                          data.userProfile.firstname) ||
                          null} ${(data &&
                          data.userProfile &&
                          data &&
                          data.userProfile.middlename) ||
                          null} ${(data && data.userProfile && data && data.userProfile.lastname) ||
                          null}`}</p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          Email Address
                        </p>
                        <p className="overview-detail">
                          {(data && data.userProfile.email) || null}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          Phone
                        </p>
                        <p className="overview-detail">
                          {(data && data.userProfile && data && data.userProfile.phoneNumber) ||
                            'N/A'}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          Street Address
                        </p>
                        <p className="overview-detail text-muted">
                          {(data && data.userProfile && data && data.userProfile.address) || 'N/A'}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          City
                        </p>
                        <p className="overview-detail text-muted">
                          {(data && data.userProfile && data && data.userProfile.city) || 'N/A'}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          State
                        </p>
                        <p className="overview-detail text-muted">
                          {(data && data.userProfile && data && data.userProfile.state) || 'N/A'}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          Country
                        </p>
                        <p className="overview-detail text-muted">
                          {(data && data.userProfile && data && data.userProfile.countryId) ||
                            'N/A'}
                        </p>
                      </div>
                      <div className="overview-item mb-3">
                        <p className="overview-label font-weight-medium text-base text-small mb-1">
                          ZIP Code
                        </p>
                        <p className="overview-detail text-muted">
                          {(data && data.userProfile && data && data.userProfile.zipCode) || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Overview
