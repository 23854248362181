import React from 'react'
import { Container } from 'react-bootstrap'
import { Layout } from '@app/index'
import { Link } from 'react-router-dom'

const NoMatch = ({ location }) => {
	return (
		<Layout>
			<section id="404" className="component" location={location}>
				<Container>
					<h1>Not Found</h1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
					<Link to="/" className="btn btn-primary btn-outline">
						Take Me Home
					</Link>
				</Container>
			</section>
		</Layout>
	)
}

export default NoMatch
