import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { GET_SITE_BY_ID } from '../../graphql/graphql'
import { Container, Row, Col, Button } from 'react-bootstrap'
import {
  Layout,
  PageHeader,
  LoadingModal,
  // AllSitesDropdown,
  FormSubmissionComponent,
  RecentTicketsComponent,
  AnalyticsSection
} from '../../components'
import '@fortawesome/fontawesome-free/css/all.css'
import './sitemanagement.css'

function SiteManagement(props) {
  const handleOnClick = () => {
    window.ZohoHCAsap.Action('open', { tab: 'Tickets' })
  }
  const { siteId } = useParams()

  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })

  if (siteLoading) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }

  return (
    <Layout>
      <PageHeader
        pageName="Site Management"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` }
        ]}
      />
      <section id="site-managment" className="component pt-3">
        <Container>
          <div id="options" className="action-section">
            <Row className="row">
              <Col xs={6} md={3}>
                <div className="card b-colored">
                  <div className="card-resizer" />
                  <Link className="text-center py-4 card-text-block" to={`${siteId}/content`}>
                    <i className="far fa-edit iconize iconize-inverted mx-auto" />
                    <span className="text-white d-block">
                      <strong>Edit </strong>
                      <br />
                      <strong>Content</strong>
                    </span>
                  </Link>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="card">
                  <div className="card-resizer" />
                  <Link className="text-center py-4 card-text-block" to={`/sites/${siteId}/forms`}>
                    <i className="far fa-file-alt iconize mx-auto" />
                    <span className="d-block">
                      <strong>Manage</strong>
                      <br />
                      <strong>Form</strong>
                    </span>
                  </Link>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="card">
                  <div className="card-resizer" />
                  <Button onClick={handleOnClick} className="text-center py-4 card-text-block">
                    <i className="far fa-flag iconize mx-auto" />
                    <span className="d-block">
                      <strong>Submit a </strong>
                      <br />
                      <strong>ticket</strong>
                    </span>
                  </Button>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="card">
                  <div className="card-resizer" />
                  <Link
                    className="text-center py-4 card-text-block"
                    to={`/sites/${siteId}/analytics`}
                  >
                    <i className="far fa-chart-bar iconize mx-auto" />
                    <span className="d-block">
                      <strong>Site</strong>
                      <br />
                      <strong>Analytics</strong>
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="data-section">
            <Row>
              <FormSubmissionComponent siteId={siteId} />
              <RecentTicketsComponent />
            </Row>
          </div>
          <AnalyticsSection siteId={siteId} />
          {/*<AllSitesDropdown />*/}
        </Container>
      </section>
    </Layout>
  )
}

export default SiteManagement
