import gql from 'graphql-tag'

// MUTATIONS SECTION

// AUTH
// Sign Up
export const SIGNUP_MUTATION = gql`
  mutation SignUpMutation($email: String!, $password: String!) {
    signup(email: $email, password: $password) {
      id
      verificationCode
      token
    }
  }
`
// Sign In
export const SIGNIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`
//Logout
export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation($token: String!) {
    logout(token: $token)
  }
`

// Refresh Token
export const REFRESH_TOKEN = gql`
  mutation {
    refreshToken
  }
`

export const GET_MEMBER_INVITATIONS = gql`
  query getMemberInvitations {
    me {
      memberInvitations {
        id
        token
        status
        role {
          name
        }
        site {
          id
          subdomain
          primaryDomain
          subscription {
            id
            subscriptionPlan {
              id
              slug
            }
          }
        }
      }
    }
  }
`

export const GET_ACCOUNT_SANDOX = gql`
  query GetAccountSandbox {
    me {
      id
      isVerified
      sandbox {
        id
        editorProviderId
        websiteProviderId
        websiteProviderDetails
        editorProviderDetails
        status
        createdAt
        updatedAt
      }
    }
  }
`

// Accept Member Invite
export const ACCEPT_SITE_MEMBER_INVITE = gql`
  mutation AcceptSiteMemberInvite($siteId: ID!, $token: String) {
    acceptSiteMemberInvite(siteId: $siteId, token: $token)
  }
`

// Accept Member Invite
export const DECLINE_SITE_MEMBER_INVITE = gql`
  mutation DeclineSiteMemberInvite($siteId: ID!, $token: String) {
    declineSiteMemberInvite(siteId: $siteId, token: $token)
  }
`

// Check Site Name
export const CHECK_SITE_NAME = gql`
  mutation CheckSiteName($siteName: String!) {
    checkSiteName(siteName: $siteName)
  }
`

// Create new site
export const CREATE_NEW_SITE = gql`
  mutation CreateNewSite($type: String!, $siteName: String!, $siteTemplateId: String!) {
    newSite(type: $type, siteName: $siteName, siteTemplateId: $siteTemplateId) {
      id
      primaryOwner
      role
      primaryDomain
      subdomain
      domains {
        id
      }
      url
      isActive
      isSecure
      createdAt
      updatedAt
    }
  }
`

// // Invite new site member
// export const INVITE_SITE_MEMBER = gql``

// // Accept site member
// export const ACCEPT_SITE_MEMBER = gql``

// // Decline site member
// export const DECLINE_SITE_MEMBER = gql``

// Update User Profile
export const UPDATE_USER_PROFILE = gql`
  mutation updateProfile(
    $firstname: String
    $lastname: String
    $middlename: String
    $address: String
    $city: String
    $state: String
    $phoneNumber: String
    $countryId: String
    $zipCode: String
  ) {
    updateProfile(
      firstname: $firstname
      lastname: $lastname
      middlename: $middlename
      address: $address
      city: $city
      state: $state
      phoneNumber: $phoneNumber
      countryId: $countryId
      zipCode: $zipCode
    ) {
      firstname
      lastname
      middlename
      address
      city
      state
      phoneNumber
      countryId
      zipCode
    }
  }
`
// Add New Form
export const ADD_NEW_FORM = gql`
  mutation newForm($siteId: String, $name: String) {
    newForm(siteId: $siteId, name: $name) {
      id
      name
      siteUrls
      testUrls
      tags
      recaptcha {
        isDefault
        version
        key
        secret
      }
      uploadSize
      notifications
      isTest
      createdAt
      updatedAt
    }
  }
`

// Add Payment Method
export const ADD_PAYMENT_METHOD = gql`
  mutation newBilling(
    $number: String
    $expMonth: Int
    $cvc: String
    $expYear: String
    $name: String!
  ) {
    newBilling(number: $number, expMonth: $expMonth, cvc: $cvc, expYear: $expYear, name: $name) {
      id
      name
      object
      brand
      country
      customer
      cvcCheck
      expMonth
      expYear
      fingerPrint
      funding
      last4
    }
  }
`

// Delete Payment Method
export const DELETE_PAYMENT_METHOD = gql`
  mutation removeBilling($cardNum: String) {
    removeBilling(cardNum: $cardNum)
  }
`

// Resend Verification Code
export const RESEND_VERIFICATION = gql`
  mutation {
    resendVerification
  }
`

export const UPDATE_FORM = gql`
  mutation updateForm(
    $siteId: String
    $formId: String
    $subject: String
    $emailTo: [String]
    $testUrls: [String]
    $webhookUrl: String
  ) {
    updateForm(
      siteId: $siteId
      formId: $formId
      subject: $subject
      emailTo: $emailTo
      testUrls: $testUrls
      webhookUrl: $webhookUrl
    ) {
      id
      name
      siteUrls
      testUrls
      tags
      recaptcha {
        isDefault
        version
        key
        secret
      }
      uploadSize
      notifications
      isTest
      count
      createdAt
      updatedAt
    }
  }
`

export const NEW_BUILD = gql`
  mutation newBuild($siteId: String) {
    newBuild(siteId: $siteId)
  }
`

export const DELETE_ALL_FORM_SUBMISSIONS = gql`
  mutation deleteAllSubmissions($siteId: String, $formId: String) {
    deleteAllSubmissions(siteId: $siteId, formId: $formId)
  }
`

export const DELETE_FORM_SUBMISSION = gql`
  mutation deleteFormSubmission($siteId: String, $formId: String, $submissionId: String) {
    deleteFormSubmission(siteId: $siteId, formId: $formId, submissionId: $submissionId)
  }
`

export const ENABLE_ANALYTICS = gql`
  mutation enableAnalytics($siteId: String!) {
    enableAnalytics(siteId: $siteId)
  }
`

// *********************************************** QUERY SECTIONS ************************************************ //

// Search Sites
export const SITES_SEARCH_QUERY = gql`
  query sitesSearch($keyword: String!) {
    searchSite(keyword: $keyword) {
      id
      primaryDomain
      subdomain
    }
  }
`

// Get All Sites
export const GET_ALL_SITES = gql`
  query siteLists($pageSize: Int, $after: String) {
    sites(pageSize: $pageSize, after: $after) {
      cursor
      hasMore
      sites {
        id
        primaryOwner
        role
        primaryDomain
        subdomain
        domains {
          id
          name
          isPrimary
          isSSL
        }
        subscription {
          id
        }
        url
        isActive
        isSecure
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`

export const GET_SITE_BY_ID = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      primaryOwner
      role
      primaryDomain
      subdomain
      url
      isActive
      isSecure
      createdAt
      updatedAt
    }
  }
`

export const GET_SITE_PROVIDER_DETAILS = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      providerDetails
    }
  }
`

// Get All Site Services
export const GET_SERVICES = gql`
  query servicesLists {
    services {
      id
      name
      slug
      description
      created
      updated
    }
  }
`

// Get All Site Templates
export const GET_SITE_TEMPLATES = gql`
  query siteServicesAndSiteTemplates {
    siteTemplates {
      id
      serviceId
      name
      description
      sourceUrl
      demoUrl
      screenshotUrl
      compiler
      buildCmd
      buildDir
      tags
      created
      updated
    }
  }
`

// Get All User Profile
export const GET_USER_PROFILE = gql`
  query userProfile {
    userProfile {
      id
      firstname
      middlename
      lastname
      email
      photo
      phoneNumber
      address
      city
      state
      countryId
      zipCode
      isVerified
    }
  }
`

//  Get All Countries
export const GET_ALL_COUNTRIES = gql`
  query countries {
    countries {
      id
      name
      isoCode
      isoCode3
      numCode
      phoneCode
      createdAt
      updatedAt
    }
  }
`

// Get Forms by Site ID
export const GET_FORMS_BY_SITE_ID = gql`
  query forms($siteId: String!) {
    forms(siteId: $siteId) {
      id
      name
      siteUrls
      testUrls
      tags
      submissions
      recaptcha {
        isDefault
        version
        key
        secret
      }
      uploadSize
      notifications
      isTest
      count
      createdAt
      updatedAt
    }
  }
`

export const GET_FORM_BY_SITE_ID_FORM_ID = gql`
  query form($siteId: String, $formId: String) {
    form(siteId: $siteId, formId: $formId) {
      id
      name
      siteUrls
      testUrls
      tags
      submissions
      recaptcha {
        isDefault
        version
        key
        secret
      }
      uploadSize
      notifications
      isTest
      count
      createdAt
      updatedAt
    }
  }
`

export const GET_FORMS_SUBMISSIONS = gql`
  query formSubmissoins($siteId: String!, $formId: String!) {
    formSubmissions(siteId: $siteId, formId: $formId)
  }
`

export const GET_ALL_BILLINGS = gql`
  query billings {
    billings {
      id
      name
      object
      brand
      country
      customer
      cvcCheck
      expMonth
      expYear
      fingerPrint
      funding
      last4
    }
  }
`

export const GET_BUILDS_BY_SITE_ID = gql`
  query builds($siteId: String) {
    builds(siteId: $siteId) {
      id
      branch
      message
      commitRef
      commitUrl
      publishedAt
      githubUrl
      sslUrl
      deployUrl
      state
      errorMessage
      createdAt
      updatedAt
    }
  }
`

export const GET_ANALYTICS_BY_SITE_ID = gql`
  query analytics($siteId: String!) {
    analytics(siteId: $siteId) {
      visitsSummary
      visitsOverTime
      events
      referrers
    }
  }
`

export const GET_SITE_DOMAIN_DETAILS = gql`
  query site($siteId: ID!) {
    me {
      isSuperAdmin
    }
    site(id: $siteId) {
      id
      subscription {
        id
      }
      domains {
        id
        name
        isPrimary
        isSSL
      }
    }
  }
`
