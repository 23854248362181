import React, { useState } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'

const Panel = props => {
  const [menuActive, setMenuState] = useState('h-100')
  function toggleClass() {
    setMenuState(menuActive === 'h-100' ? '' : 'h-100')
  }

  return (
    <Accordion defaultActiveKey={0} className={props.className}>
      <Card>
        <Card.Header bsPrefix="card-heading bg-white border-0">
          <Accordion.Toggle
            onClick={toggleClass}
            as={Button}
            className={`p-0 ${props.classButton}`}
            variant="none"
            eventKey={0}
          >
            {props.title}
            {props.subTitle && (
              <React.Fragment>
                <br />
                <small className="font-weight-light text-muted" style={{ fontSize: `12px` }}>
                  {props.subTitle}
                </small>
              </React.Fragment>
            )}
          </Accordion.Toggle>
          {props.extraButton}
        </Card.Header>
        <Accordion.Collapse eventKey={0}>
          <Card.Body className="pt-0">{props.children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default Panel
