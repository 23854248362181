import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useMutation, useLazyQuery, useQuery, useApolloClient } from '@apollo/react-hooks'
import { LOGOUT_MUTATION, SITES_SEARCH_QUERY, GET_USER_PROFILE } from '../../graphql/graphql'
import CreateSite from '../Sites/CreateSite'
import { Col, Dropdown, Form } from 'react-bootstrap'
import { LoadingModal } from '../../components'
import { MenuIcon, CreateIcon } from '../../images'
import './mainmenu.css'

function MainMenu(props) {
  const client = useApolloClient()
  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT_MUTATION, {
    variables: { token: localStorage.token },
    onCompleted({ logout }) {
      localStorage.clear()
      sessionStorage.clear()
      client.clearStore()
      props.history.push('/signin')
    },
    onError(err) {
      localStorage.clear()
      sessionStorage.clear()
      client.clearStore()
      props.history.push('/signin')
    }
  })

  function doLogout(e) {
    e.preventDefault()
    logout()
  }

  const [showSitesSearched, setShowSitesSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [getSitesSearch, { loading, data }] = useLazyQuery(SITES_SEARCH_QUERY)
  const { data: userProfile, loading: userProfileLoading } = useQuery(GET_USER_PROFILE)

  const doSearch = e => {
    const currentSearch = e.target
    setIsSearching(true)
    setIsSearching &&
      setTimeout(function() {
        getSitesSearch({
          variables: { keyword: currentSearch.value }
        })
        setIsSearching(false)
      }, 2000)
  }

  useEffect(() => {
    if (data && data.searchSite.length > 0) {
      setShowSitesSearched(true)
    }
  }, [data])

  if (userProfileLoading) {
    return <LoadingModal />
  }

  if (logoutLoading) {
    return <LoadingModal />
  }

  return (
    <>
      <Col xs="auto" xl={5}>
        <div className="search-container">
          <Form id="search-form">
            <Form.Group controlId="formSearch" className="mb-0">
              <div className="row">
                <div className="col-sm-1 d-flex justify-content-center align-items-center">
                  <div className={isSearching || loading ? 'show' : 'hide'}>
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-11">
                  <Form.Control
                    className="shadow-none border-0 mb-0"
                    type="search"
                    placeholder="Search for a site..."
                    onClick={() => setShowSitesSearched(true)}
                    onChange={e => doSearch(e)}
                  />
                  <div className="list-group sitesSearchResults">
                    {showSitesSearched &&
                      data &&
                      data.searchSite.map(site => (
                        <Link
                          to={`/sites/${site.id}`}
                          className="list-group-item list-group-item-action"
                          onClick={() => setShowSitesSearched(false)}
                        >
                          {site.subdomain} {site.primaryDomain ? `(${site.primaryDomain})` : ``}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </Col>
      <Col xs="auto ml-auto" xl={5}>
        <div className="d-flex justify-content-end align-items-center">
          <CreateSite
            html={
              <>
                <img className="mb-0" src={CreateIcon} width={25} height={25} alt="create" />
                <span className="d-none d-md-inline-block ml-1 mr-2">Create/Add New Services</span>
              </>
            }
          />
          <Dropdown>
            <Dropdown.Toggle className="shadow-none bg-transparent border-0 p-0 m-0">
              <div className="avatar float-left">
                {userProfileLoading ? (
                  <img
                    className="rounded-circle mb-0"
                    src="https://i.pravatar.cc/50"
                    alt="user"
                    width={50}
                    height={50}
                  />
                ) : (
                  <div className="header-profile-logo">
                    <span className="item-logo-text text-primary">
                      {(userProfile &&
                        userProfile.userProfile &&
                        userProfile.userProfile.firstname &&
                        userProfile.userProfile.firstname.charAt(0)) ||
                        'W'}
                    </span>
                  </div>
                )}
              </div>
              <img
                className="img-fluid"
                style={{ margin: '10px 0 10px 0' }}
                src={MenuIcon}
                alt="menu"
                width={30}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Link className="dropdown-item" to="/dashboard">
                Dashboard
              </Link>
              <Link className="dropdown-item" to="/account/overview">
                Overview
              </Link>
              {/*
              <Link className="dropdown-item" to="/password/update">
                Security
              </Link>
              */}
              <Link className="dropdown-item" to="/account/payment_methods">
                Payment Methods
              </Link>
              <Link className="dropdown-item" to="/help">
                Help Me
              </Link>
              <Dropdown.Divider />
              <button className="btn aslink shadow-none px-3 py-1 mb-0" onClick={e => doLogout(e)}>
                Logout
              </button>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
    </>
  )
}

export default withRouter(MainMenu)
