import React from 'react';
import { Button } from "react-bootstrap"
import { createBrowserHistory } from "history";
const history = createBrowserHistory()

const GoBackButton =(props)=>(
  <Button variant="default shadow" onClick={() => history.goBack()}><i className="fas fa-arrow-left"></i><span className="d-none d-md-inline-block">&nbsp;Go Back</span></Button>
)

export default GoBackButton
