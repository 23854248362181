import React from 'react'
import ContentLoader from 'react-content-loader'

const FakeFormDetails = () => {
	return (
		<div className="row py-2 email-list">
			<div className="item-content col-6 mb-3">
				<p className="font-weight-bold mb-1">
					<ContentLoader height={14}>
						<rect x="0" y="0" rx="3" ry="3" width="200" height="14" />
					</ContentLoader>
				</p>
				<p className="font-weight-bold mb-1">
					<ContentLoader height={14}>
						<rect x="0" y="0" rx="3" ry="3" width="80" height="14" />
					</ContentLoader>
				</p>
				<p className="font-weight-bold mb-1">
					<ContentLoader height={60}>
						<rect x="0" y="0" rx="3" ry="3" width="300" height="60" />
					</ContentLoader>
				</p>
				<p className="font-weight-bold mb-2">
					<ContentLoader height={14}>
						<rect x="0" y="0" rx="3" ry="3" width="150" height="14" />
					</ContentLoader>
				</p>
			</div>
			<div className="item-content col-6 mb-3">
				<p className="font-weight-bold mb-1">
					<ContentLoader height={14}>
						<rect x="0" y="0" rx="3" ry="3" width="150" height="14" />
					</ContentLoader>
				</p>
				<p className="font-weight-bold mb-2">
					<ContentLoader height={30}>
						<rect x="0" y="0" rx="3" ry="3" width="300" height="30" />
					</ContentLoader>
				</p>

				<p className="font-weight-bold mb-1">
					<ContentLoader height={30}>
						<rect x="0" y="0" rx="3" ry="3" width="300" height="30" />
					</ContentLoader>
				</p>
			</div>
		</div>
	)
}

export default FakeFormDetails
