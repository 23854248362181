import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { CreateIcon } from '../../../images'
import { CustomForm } from '../../../components'

function NewForm(props) {
	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = () => setShow(false)
	const [formName, setFormName] = useState('')

	const handleSubmit = e => {
		e.preventDefault()
		props.createForm({
			variables: {
				siteId: props.siteId,
				name: formName
			},
			onCompleted(createForm) {
				props.history.push(`/sites/${props.siteId}/forms`)
			},

			awaitRefetchQueries: true
		})
		setShow(false)
	}

	return (
		<>
			<img
				src={CreateIcon}
				alt="create"
				width={70}
				height={70}
				onClick={handleShow}
				style={{ cursor: 'pointer' }}
			/>

			<Modal size="md" show={show} onHide={handleClose} centered animation={false}>
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					{' '}
					<div className="form-container bg-white border-0">
						<div className="form-heading mb-4 text-center">
							<h4 className="font-weight-normal">Create New Form</h4>
						</div>

						<CustomForm>
							<Form.Group>
								<Form.Control
									type="text"
									name="formName"
									value={formName}
									onChange={e => setFormName(e.target.value)}
								/>
								<Form.Label>Form Name</Form.Label>
							</Form.Group>
							<Form.Group className="text-center mt-4 mb-0">
								<Button onClick={handleSubmit}>Save</Button>
								<Button type="submit" variant="link">
									Cancel
								</Button>
							</Form.Group>
						</CustomForm>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default NewForm
