import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumblist(props) {
	return (
		<>
			{(props &&
				props.list &&
				props.list.map(siteData => (
					<li className="breadcrumb-item" key={siteData.url}>
						<Link to={siteData.url}>
							<span
								className={
									props.list.length === siteData.order ? 'font-weight-medium' : 'text-secondary'
								}
							>
								{siteData.name}
							</span>
						</Link>
					</li>
				))) ||
				null}
		</>
	)
}

export default Breadcrumblist
