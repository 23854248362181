import React from 'react'
import ContentLoader from 'react-content-loader'

export default function SiteFakeCard(props) {
  return (
    <div className={`card shadow border-0 ${props.sitePrefix}`}>
      <div className="card-resizer"></div>
      <div className="card-heading">
        <a className="item-plan" href="/manage/plan">
          <ContentLoader height={10}>
            <rect x="0" y="0" rx="3" ry="3" width="50" height="60" />
          </ContentLoader>
        </a>
      </div>
      <div className="card-body">
        <div class="item-logo"></div>
      </div>
    </div>
  )
}
