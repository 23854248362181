import React from 'react'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { DeletePaymentMethod } from '../'

function CustomCard(props) {
	return (
		<Col md={3}>
			<HelpCard>
				<InnerCard className="align-self-center">{props.children}</InnerCard>
				{props.payment ? (
					<DeletePaymentMethod billingId={props.billingId} removeBilling={props.removeBilling} />
				) : null}
			</HelpCard>
		</Col>
	)
}

export default CustomCard

const HelpCard = styled.div`
	position: relative;
	height: 339px;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	margin: 8px;
`

const InnerCard = styled.div`
	text-align: center;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 20px;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transition: 0.3s;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	&:hover {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
		background-color: #fff;
		width: 105%;
		height: 105%;
		margin: 5px;
		left: -12px;
		top: -13px;
	}
`
