import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

function Notification(props) {
  console.log('props', props)
  // const [show, setShow] = useState(true);
  const {
    sitePlan,
    siteImgData,
    siteTitle,
    siteLinkData,
    siteData,
    handleAccept,
    handleDecline,
    siteMemberInvitation,
    memberInvitationAction
  } = props
  console.log('siteMemberInvitation', siteMemberInvitation)

  const {
    token,
    site: { id: siteId }
  } = siteMemberInvitation
  let status
  if (siteData.siteStatus === 'Compiling error') {
    status = (
      <React.Fragment>
        <div className="text-truncate item-domain">
          <a
            className="text-primary"
            href={`//${siteLinkData.linkUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {siteLinkData.linkUrl}
          </a>
        </div>
        <div className="item-status">
          <p className="text-secondary text-small">
            <i className="fas fa-circle text-small text-danger" />
            &nbsp; {siteData.siteStatus}
          </p>
        </div>
      </React.Fragment>
    )
  } else if (siteData.siteStatus === 'Building site') {
    status = (
      <React.Fragment>
        <div className="text-truncate item-domain">
          <a
            className="text-secondary"
            href={`//${siteLinkData.linkUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {siteLinkData.linkUrl}
          </a>
        </div>
        <div className="item-status">
          <p className="text-primary text-small">
            <i className="fas fa-circle text-small text-primary" />
            &nbsp; {siteData.siteStatus}...
          </p>
        </div>
      </React.Fragment>
    )
  } else if (siteData.siteStatus === 'Site not live') {
    status = (
      <React.Fragment>
        <div className="text-truncate item-domain">
          <a
            className="text-secondary"
            href={`//${siteLinkData.linkUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {siteLinkData.linkUrl}
          </a>
        </div>
        <div className="item-status">
          <p className="text-secondary text-small">
            <i className="fas fa-circle text-small text-warning" />
            &nbsp; {siteData.siteStatus}
          </p>
        </div>
      </React.Fragment>
    )
  } else {
    status = (
      <React.Fragment>
        <div className="text-truncate item-domain">
          <a
            className="text-primary"
            href={`//${siteLinkData.linkUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {siteLinkData.linkUrl}
          </a>
        </div>
        <div className="item-status">
          <p className="text-secondary text-small">
            <i className="fas fa-circle text-small text-success" />
            &nbsp; {siteData.siteStatus}
          </p>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="card invitation">
        <div className="card-resizer" />
        <div className="card-heading">
          <Link className="item-plan" to="/sites/plan">
            <span
              className={`badge float-left mt-2 ${
                sitePlan === 'Premium' || sitePlan === 'Free' ? 'badge-success' : 'badge-primary'
              }`}
            >
              {sitePlan}
            </span>
          </Link>
        </div>
        <div className="card-body">
          <div className="item-logo">
            <Link className="item-logo-text text-primary" to={siteLinkData.innerUrl}>
              {siteImgData.imgSrc ? (
                <img
                  className="logo img-fluid"
                  src={siteImgData.imgSrc}
                  width={siteImgData.width ? siteImgData.width : '80px'}
                  height={siteImgData.height ? siteImgData.height : '80px'}
                  alt={siteImgData.imgAlt}
                />
              ) : (
                siteTitle.charAt(0)
              )}
            </Link>
          </div>
          <div className="item-name">
            <p className="mb-2">
              You have been invited to join
              <strong>&nbsp;{siteTitle}&nbsp;</strong>
            </p>
          </div>
          {status}
        </div>
        <div className="card-footer border-0 bg-white">
          <div className="row">
            {(!memberInvitationAction ||
              (memberInvitationAction && memberInvitationAction === 'ACCEPTING')) && (
              <div className="col text-right text-md-center my-2">
                <Button
                  variant="primary shadow"
                  type="button"
                  disabled={memberInvitationAction ? true : false}
                  onClick={e => handleAccept(e, { token, siteId })}
                >
                  {memberInvitationAction === 'ACCEPTING' ? 'Accepting...' : 'Accept'}
                </Button>
              </div>
            )}
            {(!memberInvitationAction ||
              (memberInvitationAction && memberInvitationAction === 'DECLINING')) && (
              <div className="col text-left text-md-center my-2">
                <Button
                  variant="default shadow"
                  type="button"
                  disabled={memberInvitationAction ? true : false}
                  onClick={e => handleDecline(e, { token, siteId })}
                >
                  {memberInvitationAction === 'DECLINING' ? 'Declining...' : 'Decline'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Notification
