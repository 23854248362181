import React, { useState, useEffect, Fragment } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import {
  Layout,
  SiteCard,
  SiteFakeCard,
  Filter,
  CreateSite,
  Notification,
  LoadingModal
} from '@app/index'
import {
  SandboxImage
  // Pcs
} from '@images/index'
import {
  GET_ALL_SITES,
  GET_MEMBER_INVITATIONS,
  ACCEPT_SITE_MEMBER_INVITE,
  DECLINE_SITE_MEMBER_INVITE,
  GET_ACCOUNT_SANDOX
} from '../graphql/graphql'

const get = require('lodash.get')

function Dashboard(props) {
  // Interim fix for unauthorized calls because token has not been passed to Apollo client context
  if (!localStorage.hasOwnProperty('wappHasLoggedIn')) {
    window.localStorage.setItem('wappHasLoggedIn', 1)
    window.location.reload()
  }

  // Get all sites
  const { data, error, loading, networkStatus, fetchMore } = useQuery(GET_ALL_SITES, {
    notifyOnNetworkStatusChange: true,
    variables: { pageSize: 10 }
  })

  // Interim fix to automatically logout user when fetching sites result ot unauthorized two times
  const client = useApolloClient()
  let history = useHistory()
  if (error) {
    localStorage.setItem(
      'WebriqAppErrorCount',
      parseInt(localStorage.getItem('WebriqAppErrorCount')) + 1 || 0
    )
  }
  if (error && parseInt(localStorage.getItem('WebriqAppErrorCount')) > 2) {
    localStorage.clear()
    sessionStorage.clear()
    client.clearStore()
    history.push('/signin')
  }

  // Get all member invitations
  const {
    data: memberInvitations,
    loading: memberInvitationsLoading,
    error: memberInvitationsError
  } = useQuery(GET_MEMBER_INVITATIONS)
  let activeMemberInvitations = []
  if (!memberInvitationsLoading && memberInvitations) {
    activeMemberInvitations = memberInvitations.me.memberInvitations.filter(
      memberInvitation => memberInvitation.status === 'sent'
    )
  }

  // Handle accept or declining invitations
  const [memberInvitationAction, setMemberInvitationAction] = useState(null)
  const refetchOptions = {
    refetchQueries: [
      {
        query: GET_MEMBER_INVITATIONS
      }
    ]
  }

  // Accept invitation
  const [
    acceptSiteMemberInvite,
    { loading: acceptSiteMemberInviteLoading, error: acceptSiteMemberInviteError }
  ] = useMutation(ACCEPT_SITE_MEMBER_INVITE, refetchOptions)
  function handleAccept(e, { token, siteId }) {
    e.preventDefault()
    setMemberInvitationAction('ACCEPTING')
    acceptSiteMemberInvite({ variables: { siteId, token } })
  }

  // Decline invitation
  const [
    declineSiteMemberInvite,
    { loading: declineSiteMemberInviteLoading, error: declineSiteMemberInviteError }
  ] = useMutation(DECLINE_SITE_MEMBER_INVITE, refetchOptions)
  function handleDecline(e, { token, siteId }) {
    e.preventDefault()
    setMemberInvitationAction('DECLINING')
    declineSiteMemberInvite({ variables: { siteId, token } })
  }

  useEffect(() => {
    if (!acceptSiteMemberInviteLoading || !declineSiteMemberInviteLoading) {
      setMemberInvitationAction(null)
    }
  }, [acceptSiteMemberInviteLoading, declineSiteMemberInviteLoading])

  // Retrieve account sandbox
  const {
    data: accountSandbox,
    error: accountSandboxError,
    loading: accountSanboxLoading
  } = useQuery(GET_ACCOUNT_SANDOX)

  if (networkStatus === 4) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }

  return (
    <Layout>
      <Fragment>
        <Filter
          breadcrumb={{
            current: 'Dashboard',
            currentUrl: '/dashboard'
          }}
        />

        <section
          location={props.location.pathname}
          id="dashboard"
          className="site-list component pt-0"
        >
          <Container>
            {activeMemberInvitations.length > 0 && (
              <div id="notifications">
                <Row>
                  <Col md={4} lg={3}>
                    {activeMemberInvitations.map(memberInvitation => (
                      <Notification
                        key={memberInvitation.id}
                        siteId={memberInvitation.id}
                        siteTitle={get(memberInvitation, 'site.primaryDomain', 'No Name')
                          .split('.')[0]
                          .toUpperCase()}
                        siteText=""
                        sitePrefix=""
                        sitePlan="Mad"
                        siteImgData={{
                          imgSrc: '',
                          imgAlt: '',
                          width: '',
                          height: ''
                        }}
                        siteLinkData={{
                          linkUrl:
                            get(memberInvitation, 'site.primaryDomain') ||
                            get(memberInvitation, 'site.subdomain'),
                          modalLink: '',
                          innerUrl: '/manage/site'
                        }}
                        siteData={{
                          siteStatus: 'Live',
                          analytics: '',
                          currentAnalytics: '',
                          formSubmissions: '',
                          currentSubmissions: ''
                        }}
                        memberInvitationAction={memberInvitationAction}
                        siteMemberInvitation={memberInvitation}
                        handleAccept={handleAccept}
                        handleDecline={handleDecline}
                      />
                    ))}
                  </Col>
                </Row>
              </div>
            )}

            <div id="dashboard-content">
              <Row>
                {accountSanboxLoading && (
                  <Col md={4} lg={3} key={111}>
                    <SiteFakeCard sitePrefix="b-colored" />
                  </Col>
                )}

                {!accountSanboxLoading && (
                  <Col md={4} lg={3}>
                    <SiteCard
                      siteId="sandbox"
                      siteTitle={accountSandbox ? 'Open Sandbox' : 'Create Sandbox'}
                      siteText="Try a playground to experience the power of headless content management."
                      sitePrefix="b-colored"
                      sitePlan=""
                      siteImgData={{
                        imgSrc: SandboxImage,
                        imgAlt: 'sandbox',
                        width: 70,
                        height: 70
                      }}
                      siteLinkData={{
                        linkUrl: accountSandbox
                          ? `app.webriq.com/playground`
                          : `webriq.com/headless-cms-sandbox`,
                        modalLink: '',
                        innerUrl: ''
                      }}
                      siteData={{
                        siteStatus: '',
                        analytics: '',
                        currentAnalytics: '',
                        formSubmissions: '',
                        currentSubmissions: ''
                      }}
                    />
                  </Col>
                )}

                <Col md={4} lg={3}>
                  <SiteCard
                    siteId="create"
                    siteTitle="New Site"
                    siteText="Create/add new services"
                    sitePrefix=""
                    sitePlan=""
                    siteImgData={{
                      imgSrc: '',
                      imgAlt: '',
                      width: '',
                      height: ''
                    }}
                    siteLinkData={{
                      linkUrl: '',
                      linkTarget: '',
                      linkRel: '',
                      modalLink: <CreateSite />,
                      innerUrl: ''
                    }}
                    siteData={{
                      siteStatus: '',
                      analytics: '',
                      currentAnalytics: '',
                      formSubmissions: '',
                      currentSubmissions: ''
                    }}
                  />
                </Col>

                {error && (
                  <Col md={8} lg={6}>
                    <div class="alert alert-danger" role="alert">
                      Fetching sites returned an error.{' '}
                      <a href="/" class="alert-link">
                        Click here to try again.
                      </a>
                    </div>
                  </Col>
                )}

                {data &&
                  data.sites &&
                  data.sites.sites.map(site => (
                    <Col md={4} lg={3} key={site.id}>
                      <SiteCard
                        siteId={site.id}
                        siteUrlId={site.id}
                        siteTitle={site.url}
                        siteText=""
                        sitePrefix=""
                        sitePlan={!!site && site.subscription ? 'Premium' : 'Free'}
                        siteImgData={{
                          imgSrc: '',
                          imgAlt: 'logo',
                          width: 70,
                          height: 70
                        }}
                        siteLinkData={{
                          linkUrl: site.url,
                          modalLink: '',
                          innerUrl: `/sites/${site.id}`
                        }}
                        siteData={{
                          siteStatus: site.isActive ? 'Site is Live' : 'Site not Live',
                          analytics: 100,
                          currentAnalytics: 50,
                          formSubmissions: 50,
                          currentSubmissions: 5
                        }}
                        siteRole={site && site.role}
                      />
                    </Col>
                  ))}

                {loading &&
                  [...Array(!data ? 10 : 12).keys()].map(site => (
                    <Col md={4} lg={3} key={site}>
                      <SiteFakeCard />
                    </Col>
                  ))}
              </Row>

              {!loading && (data && data.sites.hasMore) && (
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() =>
                      fetchMore({
                        query: GET_ALL_SITES,
                        variables: { after: data.sites.cursor, pageSize: 12 },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          const previousSites = previousResult.sites
                          const newSites = fetchMoreResult.sites.sites
                          const newCursor = fetchMoreResult.sites.cursor

                          return {
                            sites: {
                              cursor: newCursor,
                              hasMore: fetchMoreResult.sites.hasMore,
                              totalCount: fetchMoreResult.sites.totalCount,
                              sites: [...previousSites.sites, ...newSites],
                              __typename: previousSites.__typename
                            }
                          }
                        }
                      })
                    }
                  >
                    Load More Sites
                  </button>
                </div>
              )}
            </div>
          </Container>
        </section>
      </Fragment>
    </Layout>
  )
}

export default Dashboard
