import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Panel } from '@app/index'
import gql from 'graphql-tag'

import CustomScripts from './CustomScripts'
import BuildHooks from './BuildHooks'
import AssetOptimizations from './AssetOptimizations'
import BuildEnvironmentVariables from './BuildEnvironmentVariables'

export default function SiteDeveloperSettingsPanel({ siteId }) {
  return (
    <Panel
      title="Developer Settings"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content">
        <Row>
          <Col md={12} lg={6}>
            <CustomScripts siteId={siteId} />
            <BuildHooks siteId={siteId} />
          </Col>

          <Col md={`${12} pl-lg-5`} lg={6}>
            <AssetOptimizations siteId={siteId} />
            <BuildEnvironmentVariables siteId={siteId} />
          </Col>
        </Row>
      </div>
    </Panel>
  )
}
