import React, { useState } from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import {
  SIGNIN_MUTATION,
  GET_ALL_SITES,
  GET_SITE_TEMPLATES,
  GET_SERVICES
} from '../../graphql/graphql'
import { GithubIcon } from '../../images'

function SignIn(props) {
  const client = useApolloClient()
  const [signin, { loading, error }] = useMutation(SIGNIN_MUTATION, {
    onCompleted(signin) {
      localStorage.setItem('token', signin.login)
      client.writeData({ data: { isLoggedIn: true } })
      props.history.push('/dashboard')
    },
    refetchQueries: [{ query: GET_ALL_SITES, GET_SITE_TEMPLATES, GET_SERVICES }]
  })
  if (loading) return <SignInForm signin={signin} loading={true} />
  if (error) return <SignInForm signin={signin} error={true} />
  return <SignInForm signin={signin} />
}

// SignIn Form
function SignInForm(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <section id="signin-form" className="component">
      <Container fluid className="px-md-5">
        <Row className="justify-content-center">
          <Col md={5} xl={4}>
            <div className="form-container shadow bg-white border-0">
              <div className="form-heading text-center mb-4">
                <h3 className="font-weight-normal">Sign in</h3>
                <p className="text-secondary pb-3">to continue to Dashboard</p>
              </div>
              <form>
                {props.error && (
                  <Alert variant="danger">That username/password is incorrect. Try again!</Alert>
                )}
                {props.loading && <Alert color="light">Please wait!</Alert>}
                <Form.Group className="active">
                  <Form.Control
                    type="email"
                    name="email"
                    autoFocus={true}
                    placeholder="Email"
                    data-testid="login-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <Form.Label>Email address</Form.Label>
                </Form.Group>
                <Form.Group className="active">
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    placeholder="password"
                    data-testid="login-input"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Form.Label>Password</Form.Label>
                </Form.Group>
                <Row>
                  <Col md={`${6} text-left`}>
                    <Form.Group className="mt-0">
                      <Form.Check className="pl-0">
                        <Form.Control
                          bsPrefix="form-check-input ml-0"
                          type="checkbox"
                          id="formCheck-1"
                          defaultValue="accept-tou"
                        />
                        <Form.Label bsPrefix="form-check-label">Remember me</Form.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                  <Col md={`${6} text-md-right`}>
                    <Link className="text-secondary" to="/password/email">
                      Forgot password?
                    </Link>
                  </Col>
                </Row>

                <Row className="justify-content-between pt-4">
                  <Col md={6} className="order-1 order-md-2 text-center">
                    <Button
                      onClick={e => props.signin({ variables: { email, password } })}
                      className="w-100 d-block mb-2"
                    >
                      Sign In
                    </Button>
                    <Link className="text-secondary small" to="#">
                      or signin <img src={GithubIcon} alt="github" width={20} /> with GitHub
                    </Link>
                  </Col>
                  <Col md={`${6} order-md-1 pt-2 text-center text-md-left`}>
                    <Link className="text-base" to="/account/signup">
                      Create account.
                    </Link>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SignIn
