import React from 'react'
import { CustomCard, Layout } from '../../components'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

const Section = styled.section`
	margin-top: 100px;
`

function Help(props) {
	const handleOnClick = () => {
		window.ZohoHCAsap.Action('open', { tab: 'Tickets' })
	}

	const message = () => {
		window.$zoho.salesiq.floatwindow.visible('show')
	}

	return (
		<Layout>
			<Section className="help">
				<Row className="justify-content-center">
					<Col md={9}>
						<div className="form-container shadow bg-white border-0">
							<div className="form-heading mb-4">
								<h3 className="font-weight-normal">Help</h3>
								<p className="text-secondary pb-3">
									You can directly ask for help from us by <strong>Submit a Ticket</strong> or{' '}
									<strong>Message Us Directly</strong>.
								</p>
							</div>
							<Row>
								<CustomCard>
									<div onClick={handleOnClick}>
										<i className="far fa-flag iconize-blue mx-auto" />
										<span className="d-block">
											<strong>Submit a </strong>
											<br />
											<strong>ticket</strong>
										</span>
									</div>
								</CustomCard>
								<CustomCard>
									<div onClick={message}>
										<i className="far fa-envelope iconize-blue mx-auto" />
										<span className="d-block">
											<strong>Chat us</strong>
											<br />
											<strong>now</strong>
										</span>
									</div>
								</CustomCard>
							</Row>
						</div>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export default Help
