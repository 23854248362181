import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import {
  LoadingModal,
  Layout,
  PageHeader,
  Error,
  NewForm,
  Panel
  // FormSubmissionsCount
} from '../../../components'
import { GET_FORMS_BY_SITE_ID, GET_SITE_BY_ID, ADD_NEW_FORM } from '../../../graphql/graphql'
import { FormUsage } from '@images/index'

function FormManagement(props) {
  const { siteId } = props.match.params

  const { data, loading, error } = useQuery(GET_FORMS_BY_SITE_ID, { variables: { siteId } })
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })

  const [createForm, { loading: createFormLoading }] = useMutation(ADD_NEW_FORM, {
    refetchQueries: [{ query: GET_FORMS_BY_SITE_ID, variables: { siteId: siteId } }]
  })

  if (loading)
    return (
      <Container>
        <LoadingModal />
      </Container>
    )

  if (error) return <Error />

  // console.log(data)

  return (
    <Layout>
      <PageHeader
        pageName="Form Submissions"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Forms', url: `/sites/${siteId}/forms` }
        ]}
      />
      <Container>
        <Panel
          title="Form Usage"
          className="accordion-setting bg-transparent"
          classButton="text-left h6 w-100 shadow-none m-0 border-0"
        >
          <Row>
            <Col xs={12} md={5}>
              <p className="text-left mt-5">
                <strong>
                  Manage forms and submissions without any server-side code or JavaScript.
                </strong>{' '}
                <br />
                <br /> Code an HTML form into any page on your site, add a webriq attribute to the
                form tag, and you’ll receive submissions in your Webriq dashboard. HTML files are
                parsed directly at deploy time, so there’s no need for you to make an API call or
                include extra JavaScript on your site.
              </p>
            </Col>
            <Col xs={12} md={7}>
              <img src={FormUsage} className="img-fluid" alt="Form Usage Details" />
            </Col>
          </Row>
        </Panel>
      </Container>
      <section id="dashboard" className="component pt-3">
        <Container className="container site-list">
          <Row>
            <Col md={4} lg={3}>
              <Card>
                <div className="card-resizer" />
                <Card.Header bsPrefix="card-heading invisible">
                  <div style={{ height: '60px' }} />
                </Card.Header>
                <Card.Body>
                  <div className="item-logo">
                    <NewForm siteId={siteId} createForm={createForm} />
                  </div>
                  <div className="item-name">
                    <h6 className="text-truncate">
                      <Link to="/forms/submissions">Create</Link>
                    </h6>
                  </div>
                  <div className="text-truncate item-domain">
                    <p>New Form</p>
                  </div>
                  {/*
                  <div className="item-status invisible">
                    <p className="text-muted text-small">
                      <i className="fas fa-circle text-small text-success" />
                      &nbsp; Form is live
                    </p>
                  </div>
                  */}
                </Card.Body>
                <Card.Footer>
                  <div className="row invisible mt-3" style={{ height: '60px' }}>
                    <div className="col-12">
                      <h6 className="mb-0">
                        <Link to={'/'}>10</Link>
                      </h6>
                      <p className="text-truncate text-muted text-small mb-0">Forms Submissions</p>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            {createFormLoading ? (
              <Col md={4} lg={3}>
                <Card>
                  <div className="card-resizer" />
                  <Card.Header bsPrefix="card-heading invisible">
                    <div style={{ height: '60px' }} />
                  </Card.Header>
                  <Card.Body>
                    <div className="item-logo" />
                    <div className="item-name">
                      <h6 className="text-truncate text-center">
                        <ContentLoader height={40}>
                          <rect x="0" y="0" rx="3" ry="3" width="400" height="40" />
                        </ContentLoader>
                      </h6>
                    </div>
                    <div className="text-truncate item-domain">
                      <p>
                        <ContentLoader height={30}>
                          <rect x="0" y="0" rx="3" ry="3" width="400" height="30" />
                        </ContentLoader>
                      </p>
                    </div>
                    {/*
                      <div className="item-status invisible">
                        <p className="text-muted text-small">
                          <i className="fas fa-circle text-small text-success" />
                          &nbsp; Form is live
                        </p>
                      </div>
                      */}
                  </Card.Body>
                  <Card.Footer>
                    <div className="row mt-3" style={{ height: '60px' }}>
                      <div className="col-12">
                        <h6 className="mb-0">
                          <ContentLoader height={30}>
                            <rect x="0" y="0" rx="3" ry="3" width="50" height="30" />
                          </ContentLoader>
                        </h6>
                        <p className="text-truncate text-muted text-small mb-0">
                          <ContentLoader height={20}>
                            <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
                          </ContentLoader>
                        </p>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ) : null}

            {(!error &&
              !loading &&
              data &&
              data.forms &&
              data.forms.map(form => (
                <Col md={4} lg={3} key={form.id}>
                  <Card>
                    <div className="card-resizer" />
                    <Card.Header bsPrefix="card-heading invisible">
                      <div style={{ height: '60px' }} />
                    </Card.Header>
                    <Card.Body>
                      <div className="item-logo">
                        <Link
                          className="text-primary item-logo-text"
                          to={`/sites/${siteId}/forms/${form.id}/submissions`}
                        >
                          {form.name.charAt(0).toUpperCase()}
                        </Link>
                      </div>
                      <div className="item-name">
                        <h6 className="text-truncate">
                          <Link to={`/sites/${siteId}/forms/${form.id}/submissions`}>
                            {form.name}
                          </Link>
                        </h6>
                      </div>
                      <div className="text-truncate item-domain">
                        <p>{form.id}</p>
                      </div>
                      {/*
                      <div className="item-status invisible">
                        <p className="text-muted text-small">
                          <i className="fas fa-circle text-small text-success" />
                          &nbsp; Form is live
                        </p>
                      </div>
                      */}
                    </Card.Body>
                    <Card.Footer>
                      <div className="row mt-3" style={{ height: '60px' }}>
                        <div className="col-12">
                          <h6 className="mb-0">
                            <Link to={`/`}>
                              {(form && form.submissions && form.submissions.length) || '0'}
                            </Link>
                            {console.log(
                              (form && form.submissions && form.submissions.length) || '0'
                            )}
                          </h6>
                          <p className="text-truncate text-muted text-small mb-0">
                            Forms Submissions
                          </p>
                        </div>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              ))) ||
              null}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default FormManagement
