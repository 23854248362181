import React from 'react'
import gql from 'graphql-tag'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { LoadingModal } from '@app/index'

export default function SignInViaJWT() {
  let history = useHistory()
  let { token } = useParams()
  let client = useApolloClient()

  const { data, error } = useQuery(GET_ME_QUERY, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })
  // loading

  if (error) {
    return (
      <Container>
        <h2 className="mb-5">Unable to automatically log you in.</h2>
        <Link to={'/'} className="btn btn-primary">
          Click here to manually login
        </Link>
      </Container>
    )
  }

  if (data) {
    localStorage.setItem('token', token)
    client.writeData({ data: { isLoggedIn: true } })
    history.push('/dashboard')
  }

  return (
    <Container>
      <React.Fragment>
        <LoadingModal text="Logging you in..." />
      </React.Fragment>
    </Container>
  )
}

const GET_ME_QUERY = gql`
  {
    me {
      id
      email
    }
  }
`
