import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'

function DeletePaymentMethod(props) {
	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = () => setShow(false)

	const handleSubmit = e => {
		e.preventDefault()
		props.removeBilling({
			variables: { cardNum: props.billingId },
			onCompleted(removeBilling) {
				props.history.push('/payment')
			}
		})
		setShow(false)
	}

	return (
		<div>
			<div className="mt-5 billing-status">
				<span className="p-2">
					<i className="fas fa-trash-alt" onClick={handleShow} />
				</span>
			</div>
			<Modal size="sm" show={show} onHide={handleClose} centered animation={false}>
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="form-container bg-white border-0">
						<div className="form-heading mb-4 text-center">
							<h5 className="font-weight-normal">Delete this Payment Method?</h5>
						</div>
						<Form.Group className="text-center mt-4 mb-0">
							<Button type="submit" className="mr-1" onClick={handleSubmit}>
								Yes
							</Button>
							<Button type="submit" className="ml-1" onClick={handleClose}>
								No
							</Button>
						</Form.Group>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default DeletePaymentMethod
