import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Layout, PageHeader, LoadingModal, EnableWebriqAnalytics } from '../../../components'
import { GET_SITE_BY_ID, GET_ANALYTICS_BY_SITE_ID } from '../../../graphql/graphql'
import './analytics.css'

const WebriQAnalytics = () => {
  const { siteId } = useParams()
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  const { data: analyticsData, loading: analyticsLoading } = useQuery(GET_ANALYTICS_BY_SITE_ID, {
    variables: {
      siteId
    }
  })

  const { visitsSummary } = (analyticsData && analyticsData.analytics) || []

  if (analyticsLoading) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }

  return (
    <Layout>
      <PageHeader
        pageName="Analytics"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Analytics', url: `/sites/${siteId}/analytics` }
        ]}
      />
      <section id="analytics" className="component pt-3">
        <Container className="site-list">
          <Row>
            {!analyticsLoading && visitsSummary ? (
              <Col md={4} lg={3}>
                <Card className="border-1">
                  <div>
                    <Link
                      className="item-link-wrapper"
                      to={`/sites/${siteId}/analytics/webriq-analytics`}
                    />
                    <div className="card-resizer" />
                    <div className="invisible card-heading" />
                    <div className="card-body">
                      <div className="item-logo">
                        <Link
                          className="text-primary item-logo-text"
                          to={`/sites/${siteId}/analytics/webriq-analytics`}
                        >
                          W
                        </Link>
                      </div>
                      <div className="item-name">
                        <h6 className="text-truncate">
                          <Link to={`/sites/${siteId}/analytics/webriq-analytics`}>
                            Webriq Analytics
                          </Link>
                        </h6>
                      </div>
                      <div className="item-domain">
                        <p>
                          Powered by Matomo, we bring you a much simpler way to view your site
                          analytics data.
                        </p>
                      </div>
                      <div className="item-status">
                        <p className="text-muted text-small">
                          <i className="fas fa-circle text-small text-success mr-1" />
                          Analytics is active
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="d-flex w-100">
                          <div className="col-6">
                            <h6 className="mb-0">
                              {visitsSummary.nb_visits || 0}
                              {/*<span className={`text-success visible`}>+12</span>*/}
                            </h6>
                            <p className="text-muted text-small mb-0">Visits</p>
                          </div>
                          <div className="col-6">
                            <h6 className="mb-0">
                              {visitsSummary.nb_pageviews || 0}
                              {/*<span className="text-success">+ 12</span>*/}
                            </h6>
                            <p className="text-muted text-small mb-0">Page Views</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <EnableWebriqAnalytics siteId={siteId} />
            )}

            {/* Google Analytics Page. To activate later.
              <Col md={4} lg={3}>
                <Card className="border-1">
                  <div>
                    <Link className="item-link-wrapper" to="/" />
                    <div className="card-resizer" />
                    <div className="invisible card-heading" />
                    <div className="card-body">
                      <div className="item-logo">
                        <Link className="text-primary item-logo-text" to="">
                          W
                        </Link>
                      </div>
                      <div className="item-name">
                        <h6 className="text-truncate">
                          <Link to="/">Google Analytics</Link>
                        </h6>
                      </div>
                      <div className="item-domain">
                        <p>
                          Sign in to your Google account using the button below to view your site
                          analytics data.
                        </p>
                      </div>
                      <div className="item-status">
                        <p className="text-muted text-small">
                          <i className="fas fa-circle text-small text-success mr-1" />
                          Analytics is inactive
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-12 text-right text-md-center">
                          <a className="btn btn-primary shadow" href="/">
                            Sign In
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              */}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default WebriQAnalytics
