import React from 'react'
import { Link } from 'react-router-dom'
import { Form, DropdownButton, Dropdown } from 'react-bootstrap'

class AllSitesDropdown extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			site: [
				{ siteName: 'WebriQ Glue', siteLink: 'https://glue.webriq.com' },
				{
					siteName: 'WebriQ Goes Mad',
					siteLink: 'https://webriqgoesmad.webriq.com'
				}
			],
			value: ''
		}
		this.handleChange = this.handleChange.bind(this)
	}
	handleChange(e) {
		this.setState({ value: e.target.value.toLowerCase().trim() })
	}

	render() {
		const { value } = this.state
		const sites = this.state.site.filter(data => {
			return data.siteName.toLowerCase().includes(value) || data.siteName.includes(value)
		})

		return (
			<div className="allsites my-5">
				<DropdownButton
					drop="up"
					title="All Sites"
					id="dropdown-button-drop-up"
					key="up"
					variant="warning fixed-bottom"
				>
					<Form.Control
						className="shadow-none border-0 pl-4 pr-2 outline-none"
						onChange={this.handleChange}
						type="search"
						placeholder="Search Site..."
					/>
					<Dropdown.Divider />
					{sites.map((data, index) => (
						<Link to={data.siteLink} className="dropdown-item" key={index}>
							{data.siteName}
						</Link>
					))}
				</DropdownButton>
			</div>
		)
	}
}

export default AllSitesDropdown
