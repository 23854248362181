import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { UPDATE_USER_PROFILE, GET_ALL_COUNTRIES, GET_USER_PROFILE } from '../../graphql/graphql'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { LoadingModal, Error } from '../'
import '../Common/CreateModal.css'

function UpdateProfileModal(props) {
  const { data: allCountries, loading: countryLoading } = useQuery(GET_ALL_COUNTRIES)
  const [updateProfile, { error, loading: updateProfileLoading }] = useMutation(
    UPDATE_USER_PROFILE,
    { awaitRefetchQueries: true, refetchQueries: [{ query: GET_USER_PROFILE }] }
  )

  if (countryLoading) return <LoadingModal />

  if (updateProfileLoading) return <LoadingModal />

  if (error) return <Error />

  return (
    <OverviewForm
      updateProfile={updateProfile}
      allCountries={allCountries}
      profileInfo={props.profileInfo}
    />
  )
}

function OverviewForm(props) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const [firstname, setFirstName] = useState(props.profileInfo.firstname)
  const [middlename, setMiddleName] = useState(props.profileInfo.middlename)
  const [lastname, setLastName] = useState(props.profileInfo.lastname)
  const [address, setAddress] = useState(props.profileInfo.address)
  const [city, setCity] = useState(props.profileInfo.city)
  const [state, setState] = useState(props.profileInfo.state)
  const [phoneNumber, setPhoneNumber] = useState(props.profileInfo.phoneNumber)
  const [countryId, setCountryId] = useState(props.profileInfo.countryId)
  const [zipCode, setZipCode] = useState(props.profileInfo.zipCode)

  const handleSubmit = e => {
    e.preventDefault()
    props.updateProfile({
      variables: {
        firstname,
        lastname,
        middlename,
        address,
        city,
        state,
        phoneNumber,
        countryId,
        zipCode
      },
      onCompleted(createSite) {
        props.history.push('/overview')
      }
    })
    setShow(false)
  }

  return (
    <div>
      <Button onClick={handleShow} role="button" aria-label="button">
        Update Profile
      </Button>
      <Modal size="md" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-5 text-center">
              <h4 className="font-weight-normal">Update Profile</h4>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="active">
                <Form.Control
                  type="text"
                  name="firstname"
                  value={firstname}
                  onChange={e => setFirstName(e.target.value)}
                />
                <Form.Label>First Name</Form.Label>
              </Form.Group>
              <Form.Group className="active">
                <Form.Control
                  type="text"
                  name="middlename"
                  value={middlename}
                  onChange={e => setMiddleName(e.target.value)}
                />
                <Form.Label>Middle Name</Form.Label>
              </Form.Group>
              <Form.Group className="active">
                <Form.Control
                  type="text"
                  name="lastname"
                  value={lastname}
                  onChange={e => setLastName(e.target.value)}
                />
                <Form.Label>Last Name</Form.Label>
              </Form.Group>

              <Form.Group className={address ? 'active' : null}>
                <Form.Control
                  type="text"
                  name="address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
                <Form.Label>Address</Form.Label>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className={city ? 'active' : null}>
                    <input
                      className="form-control"
                      type="text"
                      onChange={e => setCity(e.target.value)}
                      value={city}
                      name="city"
                    />
                    <label>City</label>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className={state ? 'active' : null}>
                    <input
                      className="form-control"
                      type="text"
                      onChange={e => setState(e.target.value)}
                      value={state}
                      name="state"
                    />
                    <label>State</label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className={phoneNumber ? 'active' : null}>
                    <input
                      className="form-control"
                      type="text"
                      onChange={e => setPhoneNumber(e.target.value)}
                      name="phoneNumber"
                      value={phoneNumber}
                    />
                    <label>Phone Number</label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className={city ? 'active' : null}>
                    <select className="form-control" onChange={e => setCountryId(e.target.value)}>
                      <option>{countryId}</option>
                      {props &&
                        props.allCountries &&
                        props.allCountries.countries &&
                        props.allCountries.countries.map(country => (
                          <option key={country.id} name="country" value={country.id}>
                            {country.name}
                          </option>
                        ))}
                    </select>
                    <label>City</label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className={zipCode ? 'active' : null}>
                    <input
                      className="form-control"
                      type="text"
                      onChange={e => setZipCode(e.target.value)}
                      name="zipCode"
                      value={zipCode}
                    />
                    <label>Zip Code</label>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="text-center mt-3 mb-0">
                <Button type="submit">Save Changes</Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpdateProfileModal
