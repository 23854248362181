import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { Container, Row, Col } from 'react-bootstrap'
import { GET_SITE_BY_ID, GET_ANALYTICS_BY_SITE_ID } from '../../../graphql/graphql'
import {
  Panel,
  Layout,
  PageHeader,
  LoadingModal,
  AnalyticsVisitsOverview
} from '../../../components'
import './analyticsdata.css'

const AnalyticsData = () => {
  const { siteId } = useParams()
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  const { data: analyticsData, loading: analyticsLoading } = useQuery(GET_ANALYTICS_BY_SITE_ID, {
    variables: {
      siteId
    }
  })

  if (analyticsLoading) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }
  const { visitsSummary, visitsOverTime, referrers } = analyticsData.analytics

  return (
    <Layout>
      <PageHeader
        pageName="Analytics"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Analytics', url: `/sites/${siteId}/analytics` },
          { order: 4, name: 'Webriq Analytics', url: `/sites/${siteId}/analytics/webriq-analytics` }
        ]}
      />
      <section id="form-inbox" className="component pt-3">
        <Container>
          <Row>
            <Col xs={12}>
              <Panel
                title="WebriQ Analytics"
                className="accordion-setting bg-transparent"
                classButton="text-left h6 w-100 shadow-none m-0 border-0"
              >
                <div id="webriq-analytics">
                  <p className="text-muted">
                    Powered by Matomo, we bring to you a much simpler way to view your site
                    analytics data.
                  </p>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div className="card-border">
                          <p>
                            <strong>Visits</strong>
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'nb_visits', '0')}
                              </p>
                              <label className="text-info text-small">Total</label>
                            </div>
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'nb_uniq_visitors', '0')}
                              </p>
                              <label className="text-info text-small">Unique</label>
                            </div>
                          </div>
                          <div className="data-visits">
                            <canvas id="data-visits" width="100%" className="d-none" height={50}>
                              Custom Code
                            </canvas>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div className="card-border">
                          <p>
                            <strong>Page views</strong>
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'nb_pageviews', '0')}
                              </p>
                              <label className="text-muted">Total</label>
                            </div>
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'nb_uniq_pageviews', '0')}
                              </p>
                              <label className="text-info text-small">Unique</label>
                            </div>
                          </div>
                          <div className="data-visits">
                            <canvas id="data-views" width="100%" className="d-none" height={50}>
                              Custom Code
                            </canvas>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div className="card-border">
                          <p>
                            <strong>Avg. Session Duration</strong>
                            <br />
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'avg_time_on_site', '0')}
                              </p>
                              <label className="text-info text-small">in seconds</label>
                            </div>
                          </div>
                          <canvas id="data-sessions" width="100%" className="d-none" height={50}>
                            Custom Code
                          </canvas>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div>
                          <p>
                            <strong>Bounce Rate</strong>
                            <br />
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="text-center">
                              <p className="data-counter mb-0">
                                {get(visitsSummary, 'bounce_rate', '0')}
                              </p>
                              <label className="text-info text-small">Total</label>
                            </div>
                          </div>
                          <canvas id="data-bounce-rate" width="100%" className="d-none" height={50}>
                            Custom Code
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Panel
                title="Visits Overview"
                className="accordion-setting bg-transparent"
                classButton="text-left h6 w-100 shadow-none m-0 border-0"
              >
                <div id="analytics-visits">
                  <canvas id="analytics-webriq" width="100%" height="65" className="d-none">
                    Custom Code
                  </canvas>
                </div>
                <AnalyticsVisitsOverview visitsOverTime={visitsOverTime} />
              </Panel>
            </Col>
            <Col xs={12}>
              <Panel
                title="Channel Types"
                className="accordion-setting bg-transparent"
                classButton="text-left h6 w-100 shadow-none m-0 border-0"
              >
                <div id="analytics-referrals">
                  <div className="table-responsive table-borderless">
                    <table className="table table-bordered">
                      <tbody>
                        <tr className="table-heading">
                          <td>Type</td>
                          <td className="text-center">Visits</td>
                          <td className="text-center">Actions</td>
                          <td className="text-center">Actions Per Visit (max)</td>
                          <td className="table-info text-center">Avg Time on Website (sec)</td>
                        </tr>
                        {referrers &&
                          referrers.map((referrer, i) => (
                            <tr key={i}>
                              <td>{referrer.label}</td>
                              <td className="text-center text-muted">{referrer.nb_visits}</td>
                              <td className="text-truncate text-center text-info">
                                {referrer.nb_actions}
                              </td>
                              <td className="text-truncate text-center text-info">
                                {referrer.max_actions}
                              </td>
                              <td className="table-info text-truncate text-center">
                                {referrer.sum_visit_length}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Panel>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default AnalyticsData
