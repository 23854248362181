import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import './Form.css'

function Forms(props) {
  useEffect(() => {
    // Update the document title using the browser API
    if (window) {
      function handleKeyUp(e) {
        if (e.target.value.length === 0) {
          this.setAttribute('class', 'form-group')
        } else {
          this.setAttribute('class', 'form-group active')
        }
      }
      var formField = document.getElementsByClassName('form-group')
      for (var i = 0; i < formField.length; i++) {
        formField[i].addEventListener('keyup', handleKeyUp)
      }
      for (var e = 0; e < formField.length; e++) {
        formField[e].addEventListener('change', handleKeyUp)
      }
    }
  })

  return <Form {...props}>{props.children}</Form>
}

export default Forms
