import React, { useState } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { ErrorPanel, CustomForm } from '@app/index'

const get = require('lodash.get')

export default function AssetOptimizations({ siteId }) {
  // Update Asset Optimizations
  const [showAssetOptimizationsModal, setShowAssetOptimizationsModal] = useState(false)
  const handleShowAssetOptimizationsModal = e => {
    e.preventDefault()
    setShowAssetOptimizationsModal(true)
  }
  const handleCloseAssetOptimizationsModal = () => setShowAssetOptimizationsModal(false)
  const handleUpdateAssetOptimizations = e => {}

  // GET_SITE_PROVIDER_ASSET_OPTIMIZATIONS
  const { data, error } = useQuery(GET_SITE_PROVIDER_ASSET_OPTIMIZATIONS, {
    variables: {
      siteId
    }
  })
  //loading
  if (error) {
    return <ErrorPanel title="Asset Optimizations" error={error} />
  }

  let providerAssetOptimizations = []
  if (data) {
    providerAssetOptimizations = data && data.site && data.site.providerAssetOptimizations
    console.log('providerAssetOptimizations', providerAssetOptimizations)
  }

  return (
    <Row className="pb-4 align-content-center">
      <Col xs={12}>
        <p className="font-weight-bold mb-0">Asset Optimizations</p>
        <span className="d-block text-muted small">
          Control over the post processing done aside from gzipping, setting content-addressable
          URL's, etc. that makes your sites so much faster.
        </span>
      </Col>

      <Col xs={`${12} text-right`}>
        <a className="text-base" href="/" onClick={e => handleShowAssetOptimizationsModal(e)}>
          Adjust Optimizations
        </a>
      </Col>

      <Modal
        show={showAssetOptimizationsModal}
        onHide={handleCloseAssetOptimizationsModal}
        centered
        animation={false}
        size="md"
      >
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-4 text-center">
              <h4 className="font-weight-normal">Adjust asset optimizations to your site</h4>
              <p className="text-muted">
                Control over the post processing done aside from gzipping, setting
                content-addressable URL's, etc that makes your sites so much faster.
              </p>
            </div>
            <CustomForm onSubmit={handleUpdateAssetOptimizations}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="URLS: Pretty URLs"
                  defaultChecked={get(providerAssetOptimizations, 'html.pretty_urls', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Rewrite link URLs to pretty URLs. e.g: /about.html -> /about, /about/index.html ->
                  /about/)
                </p>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="CSS: Bundle"
                  defaultChecked={get(providerAssetOptimizations, 'css.bundle', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Concatenate consecutive CSS files together to reduce HTTP requests.
                </p>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="CSS: Minify"
                  defaultChecked={get(providerAssetOptimizations, 'css.minify', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Run CSS through a minifier to reduce file size.
                </p>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="JS: Bundle"
                  defaultChecked={get(providerAssetOptimizations, 'js.bundle', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Concatenate consecutive JS files together to reduce HTTP requests.
                </p>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="JS: Minify"
                  defaultChecked={get(providerAssetOptimizations, 'js.minify', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Run JS through a minifier to reduce file size.
                </p>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Images: Compress Images"
                  defaultChecked={get(providerAssetOptimizations, 'images.optimize', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  Run all images through lossless image compression.
                </p>
              </Form.Group>
              <hr />
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Disable asset optimization"
                  defaultChecked={get(providerAssetOptimizations, 'skip', false)}
                />
                <p className="ml-5 mt-1 pl-1 text-muted small">
                  This skips all asset processing set above when defaultChecked.
                </p>
              </Form.Group>
              <Form.Group className="text-center mt-4 mb-0">
                <Button type="submit">Confirm Save</Button>
              </Form.Group>
            </CustomForm>
          </div>
        </Modal.Body>
      </Modal>
    </Row>
  )
}

const GET_SITE_PROVIDER_ASSET_OPTIMIZATIONS = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      providerAssetOptimizations
    }
  }
`
