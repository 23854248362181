import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import { DeleteIcon } from '@images/index'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { LoadingPanel, ErrorPanel, Panel, CustomForm } from '@app/index'
import ContentLoader from 'react-content-loader'

export default function DomainsPanel({ siteId }) {
  // Modal functionality
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  // Get all site domains
  const { data, loading, error } = useQuery(GET_SITE_DOMAIN_DETAILS, {
    variables: {
      siteId
    }
  })

  // New site domain
  let input
  const [newSiteDomain, { loading: newSiteDomainLoading }] = useMutation(ADD_NEW_DOMAIN, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_DOMAIN_DETAILS,
        variables: { siteId }
      }
    ]
  })
  const handleSubmit = e => {
    e.preventDefault()
    if (!subscription && !isSuperAdmin) {
      console.error('Oops... action requires site subscription!')
      return
    }

    setShow(false)
    newSiteDomain({ variables: { siteId, siteDomainName: input.value } })
    input.value = ''
  }

  // Remove site domain
  const [siteDomainId, setSiteDomainId] = useState(null)
  const [
    removeSiteDomain,
    { loading: removeSiteDomainLoading, error: removeSiteDomainError }
  ] = useMutation(REMOVE_SITE_DOMAIN, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_DOMAIN_DETAILS,
        variables: { siteId }
      }
    ]
  })
  const handleDelete = (e, siteDomainId) => {
    e.preventDefault()
    if (!subscription && !isSuperAdmin) {
      console.error('Oops... action requires site subscription!')
      return
    }

    setSiteDomainId(siteDomainId)
    removeSiteDomain({ variables: { siteId, siteDomainId } })
  }
  useEffect(() => {
    if (!removeSiteDomainLoading) {
      setSiteDomainId(null)
    }
  }, [removeSiteDomainLoading])

  // Set primary domain
  //
  // Modal functionality
  const [showPrimaryDomainModal, setShowPrimaryDomainModal] = useState(false)
  const handleShowPrimaryDomainModal = () => setShowPrimaryDomainModal(true)
  const handleClosePrimaryDomainModal = () => setShowPrimaryDomainModal(false)

  let primaryDomain
  const handleSubmitPrimaryDomain = e => {
    e.preventDefault()
  }

  // console.log('{ data, loading, error }', { data, loading, error })

  if (loading) {
    return <LoadingPanel title="Domains" />
  }

  if (error) {
    return <ErrorPanel title="Domains" error={error} />
  }

  const {
    site: { domains },
    site: { subscription },
    me: { isSuperAdmin }
  } = data

  return (
    <Panel
      title="Domains"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content py-4">
        <Row className="pb-4 align-items-center">
          <Col xs={9}>
            <p className="font-weight-bold mb-0">SSL</p>
            <span className="d-block text-muted small">
              {domains && domains.length < 0
                ? `A primary domain should be set to enable HTTPS.`
                : `SSL is automatically provisioned to domain(s) you added. Use toggle above to manually enable on set primary domain.`}
            </span>
          </Col>
          <Col xs={`${3} text-right`}>
            <Form.Label className="switch mb-0 ml-auto">
              <Form.Control type="checkbox" />
              <span className="slider round" />
            </Form.Label>
          </Col>
        </Row>

        <Row className="pb-4">
          <Col xs={12} className="text-left">
            <p className="font-weight-bold mb-4">Domains</p>
            {domains && domains.length < 1 && (
              <span className="d-block text-muted small">No domains added yet.</span>
            )}

            {domains &&
              domains.length >= 1 &&
              domains.map(domain => (
                <Row className="pb-3 align-items-center" key={domain.id}>
                  <Col xs={12} md={6}>
                    <span className="hyphens-auto">{domain.name}</span>
                  </Col>
                  <Col xs={`${9} text-center`} md={4}>
                    <span
                      className={
                        domain.isPrimary
                          ? 'bg-base text-white rounded p-1 d-block'
                          : 'bg-info text-white rounded p-1 d-block'
                      }
                    >
                      {domain.isPrimary ? 'PRIMARY' : 'ALIAS'}
                    </span>
                  </Col>
                  <Col className="col-2 text-center">
                    <a href="/" onClick={e => handleDelete(e, domain.id)}>
                      {((siteDomainId || removeSiteDomainLoading) && siteDomainId === domain.id && (
                        <Spinner animation="border" variant="primary" />
                      )) || (
                        <img
                          alt="delete"
                          className="img-fluid"
                          src={DeleteIcon}
                          width="24px"
                          height="24px"
                        />
                      )}
                    </a>
                  </Col>
                </Row>
              ))}

            {newSiteDomainLoading && (
              <Row className="pb-3 align-items-center">
                <Col xs={12} md={6}>
                  <ContentLoader height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="80%" height="20" />
                  </ContentLoader>
                </Col>
                <Col xs={`${9} text-center`} md={4}>
                  <ContentLoader height="30">
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="35" />
                  </ContentLoader>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {(isSuperAdmin || subscription) && (
          <Row className="mb-4">
            <Col xs={12} className="text-right">
              {domains && domains.length >= 2 && (
                <Button
                  variant="outline-primary"
                  onClick={handleShowPrimaryDomainModal}
                  className="mr-2"
                >
                  Set Primary Domain
                </Button>
              )}

              <Modal
                show={showPrimaryDomainModal}
                onHide={handleClosePrimaryDomainModal}
                centered
                animation={false}
              >
                <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
                  <p>{null}</p>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <div className="form-container bg-white border-0">
                    <div className="form-heading mb-4 text-center">
                      <h4 className="font-weight-normal">Update primary domain to your site</h4>
                      <p className="text-muted">
                        All domain aliases will redirect to primary domain set. Make sure that the
                        proper DNS settings has been configured so we can automatically propagate
                        SSL for your site.
                      </p>
                    </div>
                    <CustomForm onSubmit={handleSubmitPrimaryDomain}>
                      <Form.Group className="active">
                        <Form.Control
                          as="select"
                          ref={node => {
                            primaryDomain = node
                          }}
                        >
                          {domains
                            .filter(domain => !domain.isPrimary)
                            .map(domain => (
                              <option key={domain.id} value={domain.name}>
                                {domain.name}
                              </option>
                            ))}
                        </Form.Control>
                        <label>Choose a new primary domain</label>
                      </Form.Group>
                      <Form.Group className="text-center mt-4 mb-0">
                        <Button type="submit">Confirm Save</Button>
                      </Form.Group>
                    </CustomForm>
                  </div>
                </Modal.Body>
              </Modal>

              <Button variant="primary" onClick={handleShow}>
                New Domain
              </Button>

              <Modal show={show} onHide={handleClose} centered animation={false}>
                <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
                  <p>{null}</p>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <div className="form-container bg-white border-0">
                    <div className="form-heading mb-4 text-center">
                      <h4 className="font-weight-normal">Add a custom domain to your site</h4>
                      <p className="text-muted">
                        You can bring a domain name that you already own, or buy a new one. When you
                        buy the domain with us, we automatically configure your DNS settings and
                        provision a wildcard certificate for your domain.
                      </p>
                    </div>
                    <CustomForm onSubmit={handleSubmit}>
                      <Form.Group>
                        <input
                          className="form-control"
                          type="text"
                          required
                          ref={node => {
                            input = node
                          }}
                        />
                        <label>example.com</label>
                      </Form.Group>
                      <Form.Group className="text-center mt-4 mb-0">
                        <Button type="submit">Confirm Save</Button>
                      </Form.Group>
                    </CustomForm>
                  </div>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        )}

        {!isSuperAdmin && !subscription && (
          <Row>
            <Col md={`${12} text-center text-md-left`}>
              <Button variant="warning" onClick={e => window.open('google.com')}>
                Requires Plan Subscription
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </Panel>
  )
}

const ADD_NEW_DOMAIN = gql`
  mutation newSiteDomain($siteId: ID!, $siteDomainName: String!) {
    newSiteDomain(siteId: $siteId, siteDomainName: $siteDomainName) {
      id
      name
      isPrimary
      isSSL
    }
  }
`

const REMOVE_SITE_DOMAIN = gql`
  mutation removeSiteDomain($siteId: ID!, $siteDomainId: ID!) {
    removeSiteDomain(siteId: $siteId, siteDomainId: $siteDomainId) {
      id
    }
  }
`

const GET_SITE_DOMAIN_DETAILS = gql`
  query site($siteId: ID!) {
    me {
      id
      isSuperAdmin
    }
    site(id: $siteId) {
      id
      subscription {
        id
      }
      domains {
        id
        name
        isPrimary
        isSSL
      }
    }
  }
`
