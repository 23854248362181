import React, { useState } from 'react'
import { CustomForm, Modal } from '../index'
import { Form, Button } from 'react-bootstrap'

const NewPaymentMethodModal = () => {
  const [show, setShow] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setShow(true)
  }

  return (
    <Modal
      variant="none shadow-none aslink dropdown-item m-0 px-4 py-1"
      html="API"
      onHide={() => setShow(false)}
    >
      <div className="form-container bg-white border-0">
        <div className="form-heading mb-4 text-center">
          <h4 className="font-weight-normal">API Settings</h4>
        </div>
        <CustomForm onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control type="text" />
            <Form.Label>API Name</Form.Label>
          </Form.Group>
          <Form.Group className="text-center mt-4 mb-0">
            <Button type="submit">Save</Button>
            <Button type="button" variant="default shadow ml-md-3">
              Cancel
            </Button>
          </Form.Group>
        </CustomForm>
        {show ? (
          <p className="text-success text-center font-weight-medium">Successfully added!</p>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}

export default NewPaymentMethodModal
