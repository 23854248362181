import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { GET_FORM_BY_SITE_ID_FORM_ID } from '../../../graphql/graphql'
import { FakeFormDetails, UpdateForm, Error } from '../../../components'

const FormDetails = props => {
	const { data: formData, loading: formLoading, error: formError } = useQuery(
		GET_FORM_BY_SITE_ID_FORM_ID,
		{
			variables: {
				siteId: props.siteId,
				formId: props.formId
			}
		}
	)

	if (formLoading) {
		return <FakeFormDetails />
	}

	if (formError) {
		return <Error />
	}

	const subject = get(formData, 'form.notifications.email.subject') || null
	const siteUrls = get(formData, 'form.siteUrls')
	const webhooks = get(formData, 'form.notifications.webhooks[0].url') || null
	const formName = get(formData, 'form.name')
	const emailTo =
		get(formData, 'form.notifications.email.to').length > 0
			? get(formData, 'form.notifications.email.to')
			: null
	const testUrls = get(formData, 'form.testUrls').length > 0 ? get(formData, 'form.testUrls') : null

	return (
		<>
			<div className="row py-2 email-list">
				<div className="item-content col-6">
					<div className="overview-item mb-1">
						<p className="overview-label font-weight-medium text-base mb-0">
							<span className="text-small">Form ID: </span>
							<span className="font-weight-normal text-muted pr-2 mb-0">
								{get(formData, 'form.id', 'no ID')}
							</span>
						</p>
						<p className="overview-label font-weight-medium text-base mb-0">
							<span className="text-small">Subject: </span>
							<span className="font-weight-normal text-muted pr-2 mb-0">
								{subject || 'No Subject'}
							</span>
						</p>
						<p className="overview-label font-weight-medium text-base mb-0">
							<span className="text-small">Site Urls: </span>
							{siteUrls.map(siteurl => (
								<span className="font-weight-normal text-muted pr-2 mb-0 d-block" key={siteurl}>
									{siteurl}
								</span>
							)) || 'No Site Url'}
						</p>
					</div>

					<p className="overview-label font-weight-medium text-base mb-0">
						<span className="text-small">Webhooks: </span>
						<span className="font-weight-normal text-muted pr-2 mb-0">
							{webhooks || 'No Webhooks'}
						</span>
					</p>
				</div>

				<div className="item-content col-6">
					<p className="overview-label font-weight-medium text-base mb-0">
						<span className="text-small">Form Name: </span>
						<span className="font-weight-normal text-muted pr-2 mb-0">{formName}</span>
					</p>

					<p className="overview-label font-weight-medium text-base mb-0">
						<span className="text-small">Recipients: </span>

						{(emailTo &&
							emailTo.map(email => (
								<span className="font-weight-normal text-muted pr-2 mb-0 d-block" key={email}>
									{email}
								</span>
							))) || (
							<span className="font-weight-normal text-muted pr-2 mb-0">No Email Recipients</span>
						)}
					</p>
					<p className="overview-label font-weight-medium text-base mb-0">
						<span className="text-small">Test URLs: </span>

						{(testUrls &&
							testUrls.map(testurl => (
								<span className="font-weight-normal text-muted pr-2 mb-0 d-block" key={testurl}>
									{testurl}
								</span>
							))) || <span className="font-weight-normal text-muted pr-2 mb-0">No Test Urls</span>}
					</p>
				</div>
			</div>
			<UpdateForm
				siteId={props.siteId}
				formId={props.formId}
				subject={subject}
				webhooks={webhooks}
				emailTo={emailTo ? emailTo.join() : null}
				testUrls={testUrls ? testUrls.join() : null}
			/>
		</>
	)
}

export default FormDetails
