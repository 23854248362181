import React from 'react'
import { Panel } from '@app/index'

function ErrorPanel(props) {
  return (
    <Panel
      {...props}
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content mb-4">
        <div className="card-body card-body d-flex align-items-center justify-content-center">
          <div class="alert alert-danger" role="alert">
            {(props && props.error && props.error.message) || 'Something went wrong!'}
            {'. '}
            <a onClick={e => window.location.reload()} class="alert-link cursor-pointer" href="/">
              Click here to try again.
            </a>
          </div>
        </div>
      </div>
    </Panel>
  )
}

export default ErrorPanel
