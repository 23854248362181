import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import './CreateModal.css'

class CustomModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }
  handleOnHide() {
    if (this.props.onHide) {
      return this.props.onHide()
    }
  }

  render() {
    return (
      <>
        <Button
          className={this.props.className}
          as={this.props.as}
          onClick={this.handleShow}
          variant={this.props.variant}
          id={this.props.id}
          role="button"
          aria-label="button"
        >
          {this.props.html}
        </Button>
        <Modal
          size={this.props.size}
          show={this.state.show}
          onHide={() => {
            this.handleOnHide()
            this.handleClose()
          }}
          dialogClassName={this.props.mclass}
          centered
          animation={false}
        >
          <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
            <p>{this.props.stateStatus}</p>
          </Modal.Header>
          <Modal.Body className="p-0">{this.props.children}</Modal.Body>
        </Modal>
      </>
    )
  }
}

export default CustomModal
