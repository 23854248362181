import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

const FakeBuild = () => (
	<Row className="pb-4">
		<Col xs={6}>
			<div className="d-block pb-1">
				<p className="font-weight-bold pr-2 mb-0">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="d-block pb-1">
				<p className="font-weight-bold mb-0">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="150" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="d-block pb-1">
				<p className="font-weight-bold mb-0">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="80" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="d-block pb-1">
				<p className="font-weight-bold mb-0">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="350" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="d-block pb-1">
				<ContentLoader height={10}>
					<rect x="0" y="0" rx="3" ry="3" width="120" height="10" />
				</ContentLoader>
				<ContentLoader height={10}>
					<rect x="0" y="0" rx="3" ry="3" width="150" height="10" />
				</ContentLoader>
			</div>
		</Col>
		<Col xs={`${6} text-center text-lg-right`} />
	</Row>
)

export default FakeBuild
