import React from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Moment from 'react-moment'
import { GET_SITE_BY_ID, GET_BUILDS_BY_SITE_ID, NEW_BUILD } from '../../../graphql/graphql'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Layout, Panel, PageHeader, NewBuild, FakeBuild } from '../../../components' //DeployLog
import { ExternalIcon, SamplePreview } from '../../../images'

import './build.css'

function Build(props) {
  const { siteId } = useParams()

  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  const { data: buildData, loading: buildLoading } = useQuery(GET_BUILDS_BY_SITE_ID, {
    variables: {
      siteId
    }
  })

  const [newBuild, { loading: newBuildloading }] = useMutation(NEW_BUILD, {
    awaitRefetchQueries: true,
    variables: { siteId },
    refetchQueries: [{ query: GET_BUILDS_BY_SITE_ID, variables: { siteId } }]
  })

  console.log(buildData)

  return (
    <Layout>
      <PageHeader
        pageName="Builds"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Builds', url: `/sites/${siteId}/deploys` }
        ]}
      />
      <section id="site-build" className="component pt-3">
        <Container>
          <div className="data-section">
            <Panel
              title="Deploys for WebriQ Glue"
              className="accordion-setting bg-transparent"
              classButton="text-left h6 w-100 shadow-none m-0 border-0"
            >
              <div className="content pb-4">
                <Row>
                  <Col lg={3}>
                    <div
                      className="site-preview bg-base text-white d-flex align-items-center justify-content-center"
                      style={{ backgroundImage: `url(${SamplePreview})` }}
                    />
                  </Col>
                  <Col lg={9}>
                    <Row className="pb-4">
                      <Col xs={9}>
                        <a
                          className="text-base"
                          href={`//${this && props && props.data && props.data.subdomain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this && props && props.data && props.data.subdomain}
                        </a>
                        <span className="d-block text-muted small">
                          Deploys from github.com/webriq/webriqpage-premiumad, published master
                          @951736b
                        </span>
                      </Col>
                      <Col xs={`${3} text-right`}>
                        <a href="/">
                          <img
                            className="img-fluid"
                            src={ExternalIcon}
                            width="24px"
                            height="24px"
                            alt="external link"
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={9}>
                        <p className="font-weight-bold mb-0">Activate/Deactivate Auto Publishing</p>
                        <span className="d-block text-muted small">
                          Deploys from master are published automatically
                        </span>
                      </Col>
                      <Col xs={`${3} text-right`}>
                        <Form.Label className="switch mb-0 ml-auto">
                          <Form.Control type="checkbox" defaultChecked />
                          <span className="slider round" />
                        </Form.Label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Panel>

            <Panel
              title="Site Deploys"
              className="accordion-setting bg-transparent"
              classButton="text-left h6 w-100 shadow-none m-0 border-0"
              extraButton={<NewBuild newBuild={newBuild} />}
            >
              <div className="content">
                {buildLoading ? <FakeBuild /> : null}
                {newBuildloading ? <FakeBuild /> : null}

                {!buildLoading &&
                  buildData &&
                  buildData.builds.map(build => (
                    <Row className="pb-4" key={build.id}>
                      <Col xs={6}>
                        <div className="d-block pb-1">
                          <p className="font-weight-bold pr-2 mb-0">
                            Production:{' '}
                            {build.state === 'ready' ? (
                              <span>
                                <span className="font-weight-normal text-muted pr-2mb-0">
                                  {`${build.branch}@${(build &&
                                    build.commitRef &&
                                    build.commitRef.substring(0, 8)) ||
                                    undefined}`}
                                </span>
                                {` `}
                                <span className={`font-weight-bold text-success`}>Published</span>
                              </span>
                            ) : build.state === 'error' ? (
                              <span className="text-danger">Error on Build</span>
                            ) : build.state === 'building' ? (
                              <span className="text-success">Site is Building</span>
                            ) : build.state === 'enqueued' ? (
                              <span className="text-success">Initializing Build</span>
                            ) : null}
                          </p>
                        </div>

                        {build.state === 'ready' ? (
                          <div className="d-block pb-1">
                            <p className="font-weight-bold mb-0">
                              Branch:{' '}
                              <span className="font-weight-normal text-muted mb-0">
                                <a
                                  href={`https://${build.githubUrl}/tree/${build.branch}`}
                                  className="text-primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {build.branch}
                                </a>
                              </span>
                            </p>
                          </div>
                        ) : null}
                        {build && build.commitRef ? (
                          <div className="d-block pb-1">
                            <p className="font-weight-bold mb-0">
                              Commit:{' '}
                              <span className="font-weight-normal text-muted">
                                <a
                                  href={build.commitUrl}
                                  className="text-primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {build.commitRef.substring(0, 8)}
                                </a>
                              </span>
                            </p>
                          </div>
                        ) : null}

                        <div className="d-block pb-1">
                          <p className="font-weight-bold mb-0">
                            Message:{' '}
                            <span className="font-weight-normal text-muted">
                              {build.state === 'building'
                                ? 'Building'
                                : build.state === 'error'
                                ? build.errorMessage
                                : build.state === 'success'
                                ? build.message
                                : 'Enqueued'}
                            </span>
                          </p>
                        </div>
                        <div className="d-block pb-1">
                          <p className="font-weight-bold mb-0">
                            {build && build.createdAt ? <Moment>{build.createdAt}</Moment> : null}
                          </p>
                          <p className="text-muted d-block">
                            {build && build.createdAt ? (
                              <Moment fromNow>{build.createdAt}</Moment>
                            ) : null}
                          </p>
                        </div>
                      </Col>
                      <Col xs={`${6} text-center text-lg-right`}>
                        <div className="d-block d-lg-inline-block ml-lg-2 my-1">
                          {/*<DeployLog />*/}
                        </div>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Panel>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Build
