import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Panel } from '@app/index'
import gql from 'graphql-tag'

const get = require('lodash.get')

export default function ManageContentViaWebriQCMSPanel({ siteData }) {
  const loginWebriQCMS = (e, siteId) => {
    e.preventDefault()

    loginWebriQAPI({ variables: { siteId } })
  }

  const [
    loginWebriQAPI,
    { data: loginWebriQAPIData, loading: loginWebriQAPILoading }
  ] = useMutation(LOGIN_WEBRIQ_API)
  useEffect(() => {
    if (!loginWebriQAPILoading && loginWebriQAPIData) {
      let newWindow = window.open(
        loginWebriQAPIData && loginWebriQAPIData.siteContentAuthForWebriQAPI,
        '_blank'
      )
      newWindow.focus()
    }
  }, [loginWebriQAPILoading, loginWebriQAPIData])

  return (
    <Panel
      title="Manage Content"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div>
        <br />
        <div className="col-md-12">
          <div className="note js-note green">
            <h3 className="note-title2">Manage content via WebriQ CMS</h3>
            <br />
            <p>
              Hooray, just click the button below and automatically login and manage your content.
            </p>
            <p className="note-description">
              Site URL:{' '}
              <a href="//{{ $site->subdomain }}" target="_blank" rel="noopener noreferrer">
                {get(siteData, 'site.primaryDomain', 'site.subdomain')}
              </a>
            </p>
            <br />
            <div className="row col-md-6">
              <button
                onClick={e => loginWebriQCMS(e, get(siteData, 'site.id'))}
                className="btn btn-primary"
                disabled={loginWebriQAPILoading}
              >
                {loginWebriQAPILoading ? 'Logging You In...' : 'Manage Site Now!'}
              </button>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </Panel>
  )
}

const LOGIN_WEBRIQ_API = gql`
  mutation siteContentAuthForWebriQAPI($siteId: ID!) {
    siteContentAuthForWebriQAPI(siteId: $siteId)
  }
`
