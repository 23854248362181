import React from 'react'
import { Col } from 'react-bootstrap'
// import { Panel } from '../../'

function RecentTicketsComponent(props) {
	// const handleOnClick = () => {
	// 	window.ZohoHCAsap.Action('open', { tab: 'Tickets' })
	// }

	return (
		<Col md={6}>
			{/*
			<Panel
				title="Recent tickets"
				className="accordion-setting bg-transparent"
				classButton="text-left h6 w-100 shadow-none m-0 border-0"
			>
				<p className="text-muted">List of your current tickets</p>
				<div className="row my-4">
					<div className="d-none d-sm-inline-block text-left item-logo col-2">
						<p className="iconize iconize-xs">W</p>
					</div>
					<div className="item-info col-10 col-sm-8">
						<p className="mb-1">
							<strong>Redesign Your Outdated Business Website</strong>
						</p>
						<p className="text-truncate text-muted mb-1">
							Whether you have 100 or 10,000 visitors a month, your website download speeds remain
							the same, and your recurring
						</p>
						<p className="mb-1 text-date">September 09, 2019</p>
					</div>
					<div className="item-action col-2">
						<a href="/" className="text-primary btn-tool">
							<i className="fas fa-external-link-alt" />
						</a>
					</div>
				</div>
				<div className="row my-4">
					<div className="d-none d-sm-inline-block text-left item-logo col-2">
						<p className="iconize iconize-xs">R</p>
					</div>
					<div className="item-info col-10 col-sm-8">
						<p className="mb-1">
							<strong>Timeline requirements together</strong>
						</p>
						<p className="text-truncate text-muted mb-1">
							Having no active database, your website is immune to malicious injections and hacks
						</p>
						<p className="mb-1 text-date">September 09, 2019</p>
					</div>
					<div className="item-action col-2">
						<a className="text-primary btn-tool" href="/">
							<i className="fas fa-external-link-alt" />
						</a>
					</div>
				</div>
				<div className="row my-4">
					<div className="d-none d-sm-inline-block text-left item-logo col-2">
						<p className="iconize iconize-xs">R</p>
					</div>
					<div className="item-info col-10 col-sm-8">
						<p className="mb-1">
							<strong>Finding and managing a freelancer</strong>
						</p>
						<p className="text-truncate text-muted mb-1">
							With unlimited updates to content and design, your website always reflects the current
							state of your business
						</p>
						<p className="mb-1 text-date">September 09, 2019</p>
					</div>
					<div className="item-action col-2">
						<a className="text-primary btn-tool" href="/">
							<i className="fas fa-external-link-alt" />
						</a>
					</div>
				</div>
				<div className="row my-4">
					<div className="d-none d-sm-inline-block text-left item-logo col-2">
						<p className="iconize iconize-xs">W</p>
					</div>
					<div className="item-info col-10 col-sm-8">
						<p className="mb-1">
							<strong>I cannot find my form submissions</strong>
						</p>
						<p className="text-truncate text-muted mb-1">
							With unlimited updates to content and design, your website always reflects the current
							state of your business
						</p>
						<p className="mb-1 text-date">September 09, 2019</p>
					</div>
					<div className="item-action col-2">
						<a className="text-primary btn-tool" href="/">
							<i className="fas fa-external-link-alt" />
						</a>
					</div>
				</div>
			</Panel>
*/}
			<div className="card">
				<div className="card-heading">
					<h6>Recent tickets</h6>
				</div>
				<div className="card-body py-0">
					<p className="text-muted">No tickets found.</p>
				</div>
			</div>
		</Col>
	)
}

export default RecentTicketsComponent
