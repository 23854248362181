import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { WebriqAnalyticsComponent, VisitsOverviewComponent, ReferralsComponent } from '../../index'
import { Row, Col } from 'react-bootstrap'
import { GET_ANALYTICS_BY_SITE_ID, GET_SITE_DOMAIN_DETAILS } from '../../../graphql/graphql'
import { LoadingPanel } from '@app/index'

const AnalyticsSection = props => {
	const { data: analyticsData, loading: analyticsLoading } = useQuery(GET_ANALYTICS_BY_SITE_ID, {
		variables: {
			siteId: props.siteId
		}
	})

	const { data: domainsData, loading: domainsLoading } = useQuery(GET_SITE_DOMAIN_DETAILS, {
		variables: {
			siteId: props.siteId
		}
	})

	if (analyticsLoading) {
		return (
			<Row>
				<Col xs={12}>
					<LoadingPanel
						title="WebriQ Analytics"
						subTitle="Powered by Matomo, we bring to you a much simpler way to view your site analytics data."
					/>
				</Col>
				<Col xs={12} sm={6}>
					<LoadingPanel title="Visits overview" />
				</Col>
				<Col xs={12} sm={6}>
					<LoadingPanel title="Referrals" />
				</Col>
			</Row>
		)
	}

	if (domainsLoading) {
		return (
			<Row>
				<Col xs={12}>
					<LoadingPanel
						title="WebriQ Analytics"
						subTitle="Powered by Matomo, we bring to you a much simpler way to view your site analytics data."
					/>
				</Col>
				<Col xs={12} sm={6}>
					<LoadingPanel title="Visits overview" />
				</Col>
				<Col xs={12} sm={6}>
					<LoadingPanel title="Referrals" />
				</Col>
			</Row>
		)
	}

	const { visitsSummary, visitsOverTime, referrers } =
		(analyticsData && analyticsData.analytics) || []

	return (
		<div>
			<div id="data-analytics" className="analytics-section">
				<WebriqAnalyticsComponent
					siteId={props.siteId}
					visitsSummary={visitsSummary}
					domainsData={domainsData}
				/>
			</div>
			<div>
				<Row>
					<VisitsOverviewComponent
						visitsOverTime={visitsOverTime}
						siteId={props.siteId}
						domainsData={domainsData}
					/>
					<ReferralsComponent
						referrers={referrers}
						siteId={props.siteId}
						domainsData={domainsData}
					/>
				</Row>
			</div>
		</div>
	)
}

export default AnalyticsSection
