import React, { useState } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { CreateIcon, DeleteIcon } from '../../../images'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { ErrorPanel, CustomForm } from '@app/index'

export default function BuildHooks({ siteId }) {
  // New Build Hooks
  const [showBuildHooksModal, setShowBuildHooksModal] = useState(false)
  const handleShowBuildHooksModal = e => {
    e.preventDefault()
    setShowBuildHooksModal(true)
  }
  const handleCloseBuildHooksModal = () => setShowBuildHooksModal(false)

  const [newBuildHook, { loading: newBuildHookLoading }] = useMutation(ADD_NEW_CUSTOM_SCRIPT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_PROVIDER_BUILD_HOOKS,
        variables: { siteId }
      }
    ]
  })

  let newBuildHookTitle
  const handleSubmitBuildHooks = e => {
    e.preventDefault()

    setShowBuildHooksModal(false)
    newBuildHook({
      variables: {
        siteId,
        data: {
          title: newBuildHookTitle.value,
          repoBranch: 'master'
        }
      }
    })
    newBuildHookTitle.value = ''
  }

  // Get Build Hooks
  const { data, loading, error } = useQuery(GET_SITE_PROVIDER_BUILD_HOOKS, {
    variables: {
      siteId
    }
  })

  if (error) {
    return <ErrorPanel title="Build Hooks" error={error} />
  }

  let providerBuildHooks = []
  if (data) {
    providerBuildHooks = data && data.site && data.site.providerBuildHooks
  }

  return (
    <Row className="pb-4">
      <Col xs={9}>
        <p className="font-weight-bold mb-0">Build Hooks</p>
        <span className="d-block text-muted small mb-4">
          Create and manage incoming webhooks to trigger new builds and deploys.
        </span>

        {(loading || newBuildHookLoading) && !providerBuildHooks.length > 0 && (
          <span className="d-block text-muted small">No build hooks yet.</span>
        )}

        {providerBuildHooks.length > 0 &&
          providerBuildHooks.map(buildHook => (
            <Row className="pb-3 align-items-center" key={buildHook.id}>
              <Col xs={12} md={8}>
                <span className="hyphens-auto">{buildHook.title}</span>
                <span className="d-block text-muted small">{buildHook.url}</span>
              </Col>
              <Col className="col-2 text-right">
                <a href="/">
                  <img
                    alt="delete"
                    className="img-fluid"
                    src={DeleteIcon}
                    width="24px"
                    height="24px"
                  />
                </a>
              </Col>
            </Row>
          ))}

        {(loading || newBuildHookLoading) && (
          <Row className="pb-3 align-items-center">
            <Col xs={12} md={8}>
              <ContentLoader height="20">
                <rect x="0" y="0" rx="3" ry="3" width="80%" height="20" />
              </ContentLoader>
            </Col>
            <Col className="text-right">
              <ContentLoader height="50">
                <rect x="80" y="0" rx="3" ry="3" width="25%" height="85%" />
              </ContentLoader>
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={`${3} text-right`}>
        <a href="/" onClick={e => handleShowBuildHooksModal(e)}>
          <img alt="add" className="mb-0" src={CreateIcon} width={30} height={30} />
        </a>
      </Col>

      <Modal
        show={showBuildHooksModal}
        onHide={handleCloseBuildHooksModal}
        centered
        animation={false}
      >
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-4 text-center">
              <h4 className="font-weight-normal">Add a build hook to your site</h4>
              <p className="text-muted">
                Create new webhook to manually trigger new builds for your site. Just send a GET /
                POST request and a new site build will queued and processed in time. The webhook URL
                will be available once you confirm saved.
              </p>
            </div>
            <CustomForm onSubmit={handleSubmitBuildHooks}>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  ref={node => {
                    newBuildHookTitle = node
                  }}
                />
                <label>Build Hook Title</label>
              </Form.Group>
              <Form.Group className="text-center mt-4 mb-0">
                <Button type="submit">Confirm Save</Button>
              </Form.Group>
            </CustomForm>
          </div>
        </Modal.Body>
      </Modal>
    </Row>
  )
}

const GET_SITE_PROVIDER_BUILD_HOOKS = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      providerBuildHooks
    }
  }
`

const ADD_NEW_CUSTOM_SCRIPT = gql`
  mutation newProviderBuildHook($siteId: ID!, $data: BuildHookInput!) {
    newProviderBuildHook(siteId: $siteId, data: $data)
  }
`

// const REMOVE_CUSTOM_SCRIPT = gql`
//   mutation deleteProviderBuildHook($siteId: ID!, $BuildHookId: ID!) {
//     deleteProviderBuildHook(siteId: $siteId, BuildHookId: $BuildHookId)
//   }
// `
