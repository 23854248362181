import React from 'react'
import { Line } from 'react-chartjs-2'

const AnalyticsVisitsOverview = props => {
	const dates = Object.keys(props.visitsOverTime).map(label => label)

	const data = {
		labels: dates,
		datasets: [
			{
				label: 'Visits',
				fill: false,
				lineTension: 0.1,
				backgroundColor: 'rgba(6,88,255, 1)',
				borderColor: 'rgba(6,88,255, 1)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(6,88,255, 1)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(6,88,255, 1)',
				pointHoverBorderColor: 'rgba(6,88,255, 1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].nb_visits || 0)
			},
			{
				label: 'Unique Visitors',
				fill: false,
				lineTension: 0.1,
				backgroundColor: '#8EECC1',
				borderColor: '#8EECC1',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#8EECC1',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#8EECC1',
				pointHoverBorderColor: '#8EECC1',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].nb_uniq_visitors || 0)
			},
			{
				label: 'Users',
				fill: false,
				lineTension: 0.1,
				backgroundColor: 'rgba(245,139,17, 1)',
				borderColor: 'rgba(245,139,17, 1)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(245,139,17, 1)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(245,139,17, 1)',
				pointHoverBorderColor: 'rgba(245,139,17, 1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].nb_users || 0)
			},
			{
				label: 'Bounce Count',
				fill: false,
				lineTension: 0.1,
				backgroundColor: '#f6a3b7',
				borderColor: '#f6a3b7',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#f6a3b7',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#f6a3b7',
				pointHoverBorderColor: '#f6a3b7',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].bounce_count || 0)
			},
			{
				label: 'Actions per Visit',
				fill: false,
				lineTension: 0.1,
				backgroundColor: '#9CCBFB',
				borderColor: '#9CCBFB',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#9CCBFB',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#9CCBFB',
				pointHoverBorderColor: '#9CCBFB',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].nb_actions_per_visit || 0)
			},
			{
				label: 'Unique Page Views',
				fill: false,
				lineTension: 0.1,
				backgroundColor: '#A5AAF3',
				borderColor: '#A5AAF3',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: '#A5AAF3',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: '#A5AAF3',
				pointHoverBorderColor: '#A5AAF3',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: dates.map(date => props.visitsOverTime[date].nb_uniq_pageviews || 0)
			}
		]
	}

	// Visits
	// Unique Visitors
	// Users
	// Bounce Rate
	// Actions per Visit
	// Page

	return <Line data={data} />
}

export default AnalyticsVisitsOverview
