import React from 'react'
import Moment from 'react-moment'
import { DeleteFormSubmission } from '../../../components'

const FormSubmissionData = props => {
	return (
		<div className="row py-2 email-list" key={props.submission.id}>
			<div className="item-option col-8 col-md-4">
				<div className="form-check form-check-inline">
					{/*<input
						className="form-check-input custom-checkboxes"
						type="checkbox"
						id={props.submission.id}
					/>*/}
					<label className="form-check-label text-truncate" htmlFor={props.submission.id}>
						<p>
							<strong>ID:&nbsp;</strong>
							{props.submission.id}
						</p>
					</label>
				</div>
			</div>
			<div className="d-none d-sm-none d-md-inline-block item-info col-md-5" />
			<div className="d-none d-md-inline-block item-action col-md-3">
				<p className="text-muted">
					<Moment format="ddd MMM DD">{props.submission.createdAt}</Moment>
				</p>
			</div>
			<div className="item-info col-12">
				<div className="item-content col-12">
					<p className="text-date">
						<Moment format="MMM DD, YYYY - HH:MM A">{props.submission.createdAt}</Moment>
					</p>
					{Object.keys(props.submission.payload).map(title => (
						<div className="overview-item mb-1" key={title}>
							<p className="overview-label font-weight-medium text-base mb-0">
								<span className="text-small">{title}: </span>
								<span className="font-weight-normal text-muted pr-2 mb-0">
									{`${props.submission.payload[title]}`}
								</span>
							</p>
						</div>
					))}
					<DeleteFormSubmission
						siteId={props.siteId}
						formId={props.formId}
						submissionId={props.submission.id}
					/>
				</div>
			</div>
		</div>
	)
}

export default FormSubmissionData
