import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { Layout, CustomForm } from '../../components'

class ResetPassword extends React.Component {
  render() {
    return (
      <Layout>
        <section id="forgot-password" className="component">
          <Container fluid className="px-md-5">
            <Row className="justify-content-center">
              <Col xl={4} md={5}>
                <div className="form-container shadow bg-white border-0">
                  <div className="form-heading text-center mb-4">
                    <h3 className="font-weight-normal">Password Reset</h3>
                    <p className="text-secondary pb-3">
                      Please enter your registered email address to reset your password.
                    </p>
                  </div>
                  <CustomForm>
                    <Form.Group>
                      <Form.Control type="email" autoFocus={true} required />
                      <Form.Label>Email address</Form.Label>
                    </Form.Group>
                    <Form.Group className="text-center">
                      <Button type="submit">Send Password Reset Link</Button>
                    </Form.Group>
                  </CustomForm>
                  <div className="text-center">
                    <Link className="small text-muted" to="/auth/login">
                      Sign in to continue to Dashboard.
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default ResetPassword
