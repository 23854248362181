import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import MainMenu from './MainMenu'
import { WebriqLogo } from '../../images'

function Header(props) {
  return (
    <header className="bg-white fixed-top shadow">
      <div className="nav py-2">
        <Container fluid>
          <Row className="justify-content-start align-items-center">
            <Col xs="auto">
              <div className="d-flex justify-content-start align-items-center logo-container">
                <Link to="/">
                  <img
                    className="img-fluid mb-0"
                    src={WebriqLogo}
                    width={67}
                    height={67}
                    alt="webriq"
                  />
                  <h6 className="d-none d-md-inline-block mb-0 ml-2 font-weight-medium">WebriQ</h6>
                </Link>
              </div>
            </Col>
            <MainMenu />
          </Row>
        </Container>
      </div>
    </header>
  )
}

export default Header
