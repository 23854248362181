import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ExternalIcon } from '../../../images'
import { Panel } from '@app/index'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { LoadingPanel, ErrorPanel } from '@app/index'

export default function SiteGeneralPanel({ siteId }) {
  const { data, loading, error } = useQuery(GET_SITE_GENERAL_DETAILS, {
    variables: {
      siteId,
    },
  })

  console.log('data', data)
  // console.log('{ data, loading, error }', { data, loading, error })

  if (loading) {
    return <LoadingPanel title="General" />
  }

  if (error) {
    return <ErrorPanel title="General" error={error} />
  }

  const {
    site: { providerDetails },
    site: { subscription },
    site,
  } = data

  return (
    <Panel
      title="General"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content pb-5">
        {providerDetails &&
          providerDetails.published_deploy &&
          providerDetails.published_deploy.screenshot_url && (
            <Row className="pb-4 align-items-center">
              <Col xs={12}>
                <p className="font-weight-bold mb-2">Latest Screenshot</p>
                <img src={providerDetails.published_deploy.screenshot_url} className="img-fluid" />
              </Col>
            </Row>
          )}
        <Row className="pb-4 align-items-center">
          <Col xs={9}>
            <p className="font-weight-bold mb-0">Activate/Deactive Site</p>
          </Col>
          <Col xs={`${3} text-right`}>
            <Form.Label className="switch mb-0 ml-auto">
              <Form.Control type="checkbox" defaultChecked />
              <span className="slider round" />
            </Form.Label>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col xs={9}>
            <span className="font-weight-bold">Current Plan:</span>
            <span className="text-success pl-1">{!subscription ? 'FREE' : 'Premium Plan'}</span>
            <span className="d-block text-muted small">
              {(!subscription && 'Upgrade to enable more features.') ||
                'Fully unlocked with Premium features.'}
            </span>
          </Col>
          {!subscription && (
            <Col xs={`${3} text-right`}>
              <Link className="text-base" to={`/sites/${site.id}/plans`}>
                Upgrade Plan
              </Link>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={9}>
            <span className="font-weight-bold">Deployment Details</span>
            <span className="d-block text-muted long">
              <a
                className="text-base"
                href="https://github.com/webriq/webriqpage-premiummad"
                target="_blank"
                rel="noopener noreferrer"
              >
                {providerDetails.build_settings.repo_url || 'https://github.com/webriq'}
              </a>
            </span>
          </Col>
          <Col xs={`${3} text-right`}>
            <a
              href="https://github.com/webriq/webriqpage-premiummad"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="external link"
                className="img-fluid"
                src={ExternalIcon}
                width="24px"
                height="24px"
              />
            </a>
          </Col>
        </Row>
      </div>
    </Panel>
  )
}

const GET_SITE_GENERAL_DETAILS = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      subdomain
      subscription {
        id
        subscriptionPlan {
          id
          name
          label
          interval
        }
      }
      providerDetails
    }
  }
`
