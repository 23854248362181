import React from 'react'
import ContentLoader from 'react-content-loader'

function FakeForm(props) {
	return (
		<div className="row py-2 email-list">
			<div className="item-option col-8 col-md-4">
				<div className="d-none d-sm-none d-md-inline-block item-info col-md-5">
					<p className="m-0">
						<ContentLoader height={40}>
							<rect x="0" y="0" rx="3" ry="3" width="300" height="40" />
						</ContentLoader>
					</p>
				</div>
			</div>
			<div className="d-none d-sm-none d-md-inline-block item-info col-md-5">
				<p className="m-0">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="150" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="d-none d-md-inline-block item-action col-md-3">
				<p className="text-muted">
					<ContentLoader height={10}>
						<rect x="0" y="0" rx="3" ry="3" width="150" height="10" />
					</ContentLoader>
				</p>
			</div>
			<div className="item-info col-12">
				<div className="item-content col-12">
					<p className="text-date">
						<ContentLoader height={6}>
							<rect x="0" y="0" rx="3" ry="3" width="150" height="6" />
						</ContentLoader>
					</p>
					<p>
						<ContentLoader height={6}>
							<rect x="0" y="0" rx="3" ry="3" width="80" height="6" />
						</ContentLoader>
					</p>
					<p>
						<ContentLoader height={6}>
							<rect x="0" y="0" rx="3" ry="3" width="120" height="6" />
						</ContentLoader>
					</p>
					<p>
						<ContentLoader height={6}>
							<rect x="0" y="0" rx="3" ry="3" width="50" height="6" />
						</ContentLoader>
					</p>
					<p>
						<ContentLoader height={6}>
							<rect x="0" y="0" rx="3" ry="3" width="40" height="6" />
						</ContentLoader>{' '}
						<br />
						<ContentLoader height={40}>
							<rect x="0" y="5" width="350" height="40" />
						</ContentLoader>
					</p>
				</div>
			</div>
		</div>
	)
}

export default FakeForm
