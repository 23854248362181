import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'react-bootstrap'
import { GET_FORMS_SUBMISSIONS, DELETE_ALL_FORM_SUBMISSIONS } from '../../../graphql/graphql'

const DeleteAllFormSubmissions = props => {
	const { siteId, formId } = props

	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = () => setShow(false)

	const [deleteAllFormSubmissions, { loading: deleteAllFormSubmissionsLoading }] = useMutation(
		DELETE_ALL_FORM_SUBMISSIONS
	)

	const handleDeleteAllFormSubmissions = e => {
		deleteAllFormSubmissions({
			variables: { siteId, formId },
			refetchQueries: [{ query: GET_FORMS_SUBMISSIONS, variables: { siteId, formId } }]
		})
		setShow(false)
	}

	if (deleteAllFormSubmissionsLoading) {
		return (
			<button className="btn btn-danger">
				<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
				&nbsp;
				<i className="fas fa-trash" />
				<span className="d-none d-sm-inline-block">&nbsp; Delete All</span>
			</button>
		)
	}

	return (
		<>
			<button className="btn btn-danger" onClick={handleShow}>
				<i className="fas fa-trash" />
				<span className="d-none d-sm-inline-block">&nbsp; Delete All</span>
			</button>
			<Modal size="md" show={show} onHide={handleClose} centered animation={false}>
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					<div className="form-container bg-white border-0">
						<div className="form-heading mb-4 text-center">
							<h3 className="font-weight-normal">Are you sure?</h3>
							<p className="text-secondary pb-3">
								All form submissions will be cleared after this.
							</p>
						</div>
						<div className="d-flex justify-content-center flex-wrap">
							<Button
								type="submit"
								className="mr-3"
								onClick={e => handleDeleteAllFormSubmissions(e)}
							>
								Yes
							</Button>
							<Button type="button" variant="default shadow mr-md-3" onClick={handleClose}>
								Cancel
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default DeleteAllFormSubmissions
