import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import { SIGNUP_MUTATION } from '../../graphql/graphql'
import { GithubIcon } from '../../images'

function SignUp(props) {
	const [signup, { loading, error }] = useMutation(SIGNUP_MUTATION, {
		onCompleted({ signup }) {
			localStorage.setItem('token', signup.token)
			props.history.push('/dashboard')
		}
	})

	if (loading) return <SignUpForm signup={signup} loading={true} />
	if (error) return <SignUpForm signup={signup} error={true} />

	return <SignUpForm signup={signup} />
}

function SignUpForm(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [verifyPassword, setVerifyPassword] = useState('')
	const [passwordMatch, setPasswordMatch] = useState(true)

	const [isTyping, setIsTyping] = useState()

	function VerifyPassword(e) {
		setIsTyping(true)
		setVerifyPassword(e)
		setTimeout(() => {
			setIsTyping(false)
		}, 1000)
	}

	function handleVerifyPassword() {
		!isTyping && verifyPassword === password ? setPasswordMatch(true) : setPasswordMatch(false)
	}

	useEffect(() => {
		handleVerifyPassword()
	})

	return (
		<section id="signup-form" className="component">
			<Container fluid className="px-md-5">
				<Row className="justify-content-center">
					<Col md={5} xl={4}>
						<div className="form-container shadow bg-white border-0">
							<div className="form-heading text-center mb-4">
								<h3 className="font-weight-normal">Create your account</h3>
								<p className="text-secondary pb-3">to continue to Dashboard</p>
							</div>
							<div>
								{props.loading && <Alert color="light">Please wait!</Alert>}
								{props.error && (
									<Alert variant="danger">Your email is already taken. Try again!</Alert>
								)}
								{isTyping !== undefined && !isTyping && !passwordMatch ? (
									<Alert variant="danger">Password did not match!</Alert>
								) : null}

								<Form.Group className="active">
									<Form.Control
										type="email"
										name="email"
										value={email}
										autoFocus={true}
										placeholder="johndoe@example.com"
										data-testid="login-input"
										onChange={e => setEmail(e.target.value)}
										required
									/>
									<Form.Label>Email address</Form.Label>
								</Form.Group>
								<Form.Group className="active">
									<Form.Control
										required
										type="password"
										name="password"
										value={password}
										placeholder="Enter your password"
										data-testid="login-input"
										onChange={e => setPassword(e.target.value)}
									/>
									<Form.Label>Password</Form.Label>
								</Form.Group>
								<Form.Group className="active">
									<Form.Control
										required
										type="password"
										name="password"
										value={verifyPassword}
										placeholder="Confirm Password"
										data-testid="login-input"
										onChange={e => VerifyPassword(e.target.value)}
									/>
									<Form.Label>Password</Form.Label>
								</Form.Group>
								<label className="text-secondary">
									<input type="checkbox" name="checkbox" value="value" />
									&nbsp;I agree to the <Link to="/">Terms of Use</Link>.
								</label>

								<Row className="justify-content-between pt-4">
									<Col md={6} className="order-1 order-md-2 text-center">
										<Button
											className={`w-100 d-block mb-2 ${
												isTyping !== undefined && !isTyping && !passwordMatch ? 'disabled' : null
											}`}
											type="submit"
											onClick={e =>
												isTyping !== undefined && !isTyping && !passwordMatch
													? null
													: props.signup({ variables: { email, password } })
											}
										>
											Sign Up
										</Button>
										<Link className="text-secondary small" to="#">
											or register <img src={GithubIcon} alt="github" width={20} /> with GitHub
										</Link>
									</Col>
									<Col md={`${6} order-md-1 pt-2 text-center text-md-left`}>
										<Link className="text-base" to="/signin/">
											Sign in instead.
										</Link>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default SignUp
