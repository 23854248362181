import React from 'react'
import { Link } from 'react-router-dom'
import { ExternalIcon } from '../../images'
import { Breadcrumb, Dropdown } from 'react-bootstrap'
import { GoBack, BreadcrumbList, APIComponent } from '../../components'
import ContentLoader from 'react-content-loader'
import './pageheader.css'

function PageHeader(props) {
	return (
		<div className="header-bottom">
			<div className="container">
				<div className="row site-title-container mb-3">
					<div className="col">
						{props.siteData === 'fetching' ? (
							<ContentLoader height={10}>
								<rect x="0" y="0" rx="3" ry="3" width="200" height="10" />
							</ContentLoader>
						) : (
							<a
								className={`preview-url`}
								href={`https://${
									props.siteData && props.siteData.site && props.siteData.site.primaryDomain
										? props.siteData.site.primaryDomain
										: props.siteData && props.siteData.site && props.siteData.site.subdomain
										? props.siteData.site.subdomain
										: 'www.webriq-website.webriq.me'
								}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<h3 className="float-left">
									{props.siteData && props.siteData.site && props.siteData.site.primaryDomain
										? props.siteData.site.primaryDomain
										: props.siteData && props.siteData.site && props.siteData.site.subdomain
										? props.siteData.site.subdomain
										: 'www.webriq-website.webriq.me'}
									<img className="mx-3" src={ExternalIcon} alt="external" width={18} height={18} />
								</h3>
							</a>
						)}
					</div>
				</div>
				{/*Breadcrumb and Go back section*/}
				<div className="row breadcrumbs">
					<div className="col-9">
						<Breadcrumb
							className=" bg-transparent pl-0 mb-0"
							listProps={{ className: 'bg-light pl-0' }}
						>
							<BreadcrumbList list={props.list} />
						</Breadcrumb>
					</div>

					{props.pageName === 'Site Management' ? (
						<div className="col-3 text-right advance-setting">
							<Dropdown>
								<Dropdown.Toggle as="button" bsPrefix="btn btn-default hide-caret shadow">
									<span className="d-none d-md-inline">Advanced Settings</span>{' '}
									<i className="fas fa-cog" />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Link className="dropdown-item" to={`/sites/${props.siteId}`}>
										Manage Site
									</Link>
									<Link className="dropdown-item" to={`/sites/${props.siteId}/analytics`}>
										Analytics
									</Link>
									<Link className="dropdown-item" to={`/sites/${props.siteId}/deploys`}>
										Builds
									</Link>
									<APIComponent />
									<Link className="dropdown-item" to={`/sites/${props.siteId}/plans`}>
										Plan
									</Link>
									<Dropdown.Divider />
									<Link className="dropdown-item" to={`/sites/${props.siteId}/settings`}>
										Settings
									</Link>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					) : (
						<div className="col-3 options">
							<div className="options dropdown">
								<GoBack />
							</div>
						</div>
					)}
				</div>
				{/*Breadcrumb and go back end*/}
			</div>
		</div>
	)
}

export default PageHeader
