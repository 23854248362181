import React from 'react'
import { Link } from 'react-router-dom'

import { ExternalLinkImage } from '../../images'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Breadcrumb, APIComponent } from '../index'

export function HeaderBottom(props) {
  return (
    <div className="header-bottom mb-4 pt-5">
      <Container>
        <div className="site-title-container mb-4">
          <a
            className="preview-url"
            href={`${props.siteInfo}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="float-left">{props && props.siteInfo}</h3>
            <img className="mx-3" src={ExternalLinkImage} alt="external" width={18} height={18} />
          </a>
        </div>
        <Row className="align-items-center">
          <Col md={`${6} page-breadcrumb`}>
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={props.breadcrumb.previousUrl}>
                  <span className="text-secondary">{props.breadcrumb.previous}</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={props.breadcrumb.currentUrl} activeclassname="active">
                  <span className="font-weight-medium">{props.breadcrumb.current}</span>
                </Link>
              </li>
            </Breadcrumb>
          </Col>
          {props.advanceSettings ? (
            <div className="col-md-6 text-right advance-setting">
              <Dropdown>
                <Dropdown.Toggle as="button" bsPrefix="btn btn-default hide-caret shadow">
                  <span className="d-none d-md-inline">Advanced Settings</span>{' '}
                  <i className="fas fa-cog" />
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <Link className="dropdown-item" to={`/sites/${props.siteId}`}>
                    Manage Site
                  </Link>
                  <Link className="dropdown-item" to={`/sites/${props.siteId}/analytics`}>
                    Analytics
                  </Link>
                  <Link className="dropdown-item" to={`/sites/${props.siteId}/deploys`}>
                    Builds
                  </Link>
                  <APIComponent />
                  <Link className="dropdown-item" to={`/sites/${props.siteId}/plans`}>
                    Plan
                  </Link>
                  <Dropdown.Divider />
                  <Link className="dropdown-item" to={`/sites/${props.siteId}/settings`}>
                    Settings
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </Row>
      </Container>
    </div>
  )
}

export default HeaderBottom
