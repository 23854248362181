import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Container, Col, Row, Alert } from 'react-bootstrap' //Dropdown
import styled from 'styled-components'
import { Breadcrumb, Error, LoadingModal } from '../index'
import { GET_USER_PROFILE, RESEND_VERIFICATION } from '../../graphql/graphql'

function Filter(props) {
  const { data, loading, error } = useQuery(GET_USER_PROFILE)
  const [
    resendVerification,
    { data: resendVerificationData, loading: resendVerificationLoading }
  ] = useMutation(RESEND_VERIFICATION)
  if (loading) {
    return (
      <Container>
        <LoadingModal />
      </Container>
    )
  }

  if (error) {
    return <Error />
  }

  if (resendVerificationData) {
    console.log('true')
  }

  return (
    <div id="filter-sites" className="mt-5 mb-4 pt-5">
      <Container>
        {!loading && data && data.userProfile && !data.userProfile.isVerified ? (
          <Alert variant="warning" className="mb-3">
            Please check your inbox to verify{' '}
            <strong>{data && data.userProfile && data.userProfile.email} </strong>
            as your primary email or&nbsp;
            <span onClick={resendVerification} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
              request a new confirmation email.
            </span>
            {resendVerificationLoading ? (
              <Loader>
                <div />
                <div />
                <div />
                <div />
              </Loader>
            ) : null}
          </Alert>
        ) : null}

        <div className="site-title-container mb-4">
          <h3 className={`${loading ? 'blur-text' : null}`}>
            Welcome Back{' '}
            {(data && data.userProfile && data.userProfile.firstname) || 'user firstname'}
          </h3>
        </div>
        <Row className="align-items-center">
          <Col xs={`${6} page-breadcrumb`}>
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={props.breadcrumb.currentUrl} activeclassname="active">
                  <span className="font-weight-medium">{props.breadcrumb.current}</span>
                </Link>
              </li>
            </Breadcrumb>
          </Col>
          <Col xs={6} className="text-right advance-setting">
            {/*
          <Dropdown>
            <Dropdown.Toggle variant="default dropdown-toggle hide-caret shadow">
              <span className="d-inline-block">Filter by</span>
              <i className="ml-2 fas fa-chevron-down" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item href="/">All</Dropdown.Item>
              <Dropdown.Item href="/">Status</Dropdown.Item>
              <Dropdown.Item href="/">Plan</Dropdown.Item>
              <Dropdown.Item href="/">Name</Dropdown.Item>
              <Dropdown.Item href="/">Owned</Dropdown.Item>
              <Dropdown.Item href="/">Member</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>*/}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Filter

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;
  margin-left: 10px;
  div {
    position: absolute;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0c5dfe;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
