import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Form, Col, Button, Modal, Alert } from 'react-bootstrap'
import './CreateSite.css'
import { CreateIcon } from '../../images'
import check from '../../images/check.png'
import {
  GET_ALL_SITES,
  GET_SITE_TEMPLATES,
  CREATE_NEW_SITE,
  CHECK_SITE_NAME
} from '../../graphql/graphql'

function CreateServices(props) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  // const [service] = useState({ id: 'EKekWeRrRqPQ', name: 'pages' })
  const [siteTemplateId, setSiteTemplateId] = useState('w8OArjmWnpxE')
  const [siteName, setSiteName] = useState('')

  // const { data: AllServices, loading: AllServicesLoading } = useQuery(GET_SERVICES)
  const { data: AllSiteTemplates, loading: AllSiteTemplatesLoading } = useQuery(GET_SITE_TEMPLATES)
  const [createSite, { loading: CreateSiteLoading }] = useMutation(CREATE_NEW_SITE)
  const [
    checkSiteName,
    { data: checkSiteNameData, loading: checkSiteNameLoading, error: checkSiteNameError }
  ] = useMutation(CHECK_SITE_NAME)

  if (AllSiteTemplatesLoading) {
    return (
      <Button
        className="className"
        onClick={handleShow}
        variant="link p-0"
        role="button"
        aria-label="button"
      >
        <img className="mb-0" src={CreateIcon} width={25} height={25} alt="create" />
        <span className="d-none d-md-inline-block ml-1 mr-2">Create/Add New Services</span>
      </Button>
    )
  }

  // if (AllServicesLoading) {
  //   return <span className="d-none d-md-inline-block ml-1 mr-2">Create/Add New Services</span>
  // }
  // const dataServices =
  //   AllServices &&
  //   AllServices.services &&
  //   AllServices.services.sort((a, b) => (a.id > b.id ? 1 : -1))

  let sitenameAvailability
  const siteTemplates =
    AllSiteTemplates &&
    AllSiteTemplates.siteTemplates.filter(service => service.serviceId === 'EKekWeRrRqPQ')

  const handleSubmit = e => {
    createSite({
      awaitRefetchQueries: true,
      variables: { type: 'pages', siteName, siteTemplateId },
      refetchQueries: [{ query: GET_ALL_SITES }],
      onCompleted(createSite) {
        props.history.push('/dashboard')
      }
    })
    setShow(false)
  }

  let siteNameValue

  const handleCheckSiteName = e => {
    checkSiteName({
      variables: { siteName: siteNameValue }
    })
  }

  const handleSiteNameChange = e => {
    setSiteName(e.target.value.replace(/\s/g, ''))
    siteNameValue = e.target.value.replace(/\s/g, '')
    handleCheckSiteName()
  }
  if (checkSiteNameError && siteName) {
    sitenameAvailability = 'not available'
  }
  if (checkSiteNameData && siteName) {
    sitenameAvailability = 'available'
  }
  if (checkSiteNameLoading && siteName) {
    sitenameAvailability = 'loading'
  }
  if (CreateSiteLoading) {
    return <span className="d-none d-md-inline-block ml-1 mr-2">Create/Add New Services</span>
  }

  console.log(siteTemplateId)

  return (
    <>
      <Button
        className="className"
        onClick={handleShow}
        variant="link p-0"
        role="button"
        aria-label="button"
      >
        {props && props.html ? (
          props.html
        ) : (
          <img src={CreateIcon} alt="create" width={70} height={70} />
        )}
      </Button>

      <Modal size="md" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-5 text-center">
              <h4 className="font-weight-normal">Create your website</h4>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className={siteName ? 'active' : null}>
                <Form.Control
                  type="text"
                  name="Your preferred website name"
                  value={siteName}
                  onChange={e => {
                    handleSiteNameChange(e)
                  }}
                />

                <Form.Label>Your preferred website name</Form.Label>
                {sitenameAvailability === 'loading' ? (
                  <Alert variant="primary">Checking site name availability...&nbsp;</Alert>
                ) : null}

                {sitenameAvailability === 'not available' ? (
                  <Alert variant="danger">
                    {siteName} is already <strong>taken!</strong> Please select another site name
                  </Alert>
                ) : null}

                {sitenameAvailability === 'available' ? (
                  <Alert variant="success">
                    {siteName} is <strong>available!</strong> Please proceed to selecting site
                    templates
                  </Alert>
                ) : null}

                <p className="text-small">
                  <span className="text-muted">
                    Enable more features such as custom domain and more or get
                  </span>{' '}
                  .webriq.me <span className="text-muted">address.</span>
                </p>
              </Form.Group>
              <fieldset className="mb-4" id="template-selection">
                <legend>Choose a template</legend>
                <Form.Row>
                  {siteTemplates &&
                    siteTemplates.map(
                      sitetemplate =>
                        (
                          <Col key={sitetemplate.id} md={`${6} text-center mb-4`}>
                            <Form.Control
                              type="radio"
                              id={sitetemplate.id}
                              name="Template"
                              defaultChecked={siteTemplateId === sitetemplate.id ? true : false}
                              onClick={e => setSiteTemplateId(e.target.id)}
                            />
                            <Form.Label>
                              <img src={check} alt="check" className="formchecked" />
                              <img
                                className="img-fluid"
                                src={sitetemplate.screenshotUrl}
                                alt="template"
                              />
                              <span className="text-center text-muted text-small">
                                {sitetemplate.name}
                              </span>
                            </Form.Label>
                          </Col>
                        ) || 'no site templates'
                    )}
                </Form.Row>
              </fieldset>

              <Form.Group className="mt-4 mb-0">
                <Button onClick={handleSubmit}>Create Site</Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateServices
