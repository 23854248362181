import React from 'react'
import { Col } from 'react-bootstrap'
import { Panel } from '../../index'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

const ReferralsComponent = props => (
	<Col xs={12} sm={6}>
		<Panel
			title="Referrals"
			className="accordion-setting bg-transparent"
			classButton="text-left h6 w-100 shadow-none m-0 border-0"
		>
			{get(props.domainsData, 'site.domains.length') === 0 ? (
				<p className="text-muted">
					Please add{' '}
					<Link className="text-primary" to={`sites/${props.siteId}/settings`}>
						new domain
					</Link>{' '}
					to access Webriq Analytics.
				</p>
			) : null}

			{get(props.domainsData, 'site.domains.length') > 0 && !props.referrers ? (
				<p className="text-muted">
					Please enable{' '}
					<Link className="text-primary" to={`/sites/${props.siteId}/analytics`}>
						Webriq Analytics
					</Link>{' '}
					to access Webriq Analytics data.
				</p>
			) : null}

			{props && props.referrers ? (
				<div className="table-responsive table-borderless">
					<table className="table table-bordered">
						<tbody>
							<tr className="table-heading">
								<td>Type</td>
								<td>Visits</td>
								<td>Actions</td>
								<td className="text-center">Actions Per Visit (max)</td>
								<td className="table-info text-center">Avg Time on Website (sec)</td>
							</tr>
							{props.referrers.map((referrer, i) => (
								<tr key={i}>
									<td>{referrer.label}</td>
									<td className="text-center text-muted">{referrer.nb_visits}</td>
									<td className="text-truncate text-center text-muted">{referrer.nb_actions}</td>
									<td className="text-truncate text-center text-muted">{referrer.max_actions}</td>
									<td className="table-info text-truncate text-center">
										{referrer.sum_visit_length}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : null}
		</Panel>
	</Col>
)

export default ReferralsComponent
