import React, { useState } from 'react'
import { CustomForm } from '../index'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'

function NewPaymentMethodModal(props) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const [number, setNumber] = useState('')
  const [expMonthYear, setExpMonthYear] = useState('')
  const [cvc, setCVC] = useState('')
  const [name, setName] = useState('')

  const monthyear = expMonthYear.split('/')
  const expMonth = parseInt(monthyear[0])
  const expYear = '20' + monthyear[1]

  const handleSubmit = e => {
    e.preventDefault()
    props.addBilling({
      variables: {
        number,
        expMonth,
        cvc,
        expYear,
        name
      },
      onCompleted(createSite) {
        props.history.push('/payment')
      }
    })
    setShow(false)
    setNumber('')
    setExpMonthYear('')
    setCVC('')
    setName('')
  }

  return (
    <div>
      <Button
        className="className"
        onClick={handleShow}
        variant="none shadow-none h6 p-0 m-0"
        role="button"
        aria-label="button"
      >
        {props.html}
      </Button>

      <Modal size="md" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-4 text-center">
              <h4 className="font-weight-normal">New payment method</h4>
              <p className="text-secondary pb-3">Please enter your card details.</p>
            </div>

            <CustomForm onSubmit={handleSubmit}>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                />
                <label>Credit Card Number</label>
              </Form.Group>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <label>Cardholder Name </label>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={cvc}
                      onChange={e => setCVC(e.target.value)}
                    />
                    <label>Security Code</label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={expMonthYear}
                      onChange={e => setExpMonthYear(e.target.value)}
                    />
                    <label>
                      Expiration Date
                      <sup>
                        <small> (MM/YY)</small>
                      </sup>
                    </label>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="text-center mt-4 mb-0">
                <Button type="submit">Add Payment Method</Button>
              </Form.Group>
            </CustomForm>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NewPaymentMethodModal
