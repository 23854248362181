import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { WarningIcon } from '../../../images'

function NewBuildModal(props) {
	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = () => setShow(false)

	const handlenewBuild = e => {
		props.newBuild()
		setShow(false)
	}

	return (
		<NewBuild>
			<Button type="submit" onClick={handleShow} className="d-flex ">
				Manual Build
			</Button>

			<Modal size="md" show={show} onHide={handleClose} centered animation={false}>
				<Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
					<p>{null}</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					{' '}
					<div className="form-container bg-white border-0">
						<div className="form-heading mb-4 text-center">
							<img
								src={WarningIcon}
								alt="warning"
								className="img-fluid mb-3"
								width={100}
								height={100}
							/>
							<h3 className="font-weight-normal">Are you sure?</h3>
							<p className="text-secondary pb-3">
								A new build will be triggered and publish your site afterwards
							</p>
						</div>
						<div className="d-flex justify-content-center flex-wrap">
							<Button type="submit" onClick={e => handlenewBuild(e)} className="mr-3">
								Yes, build it!
							</Button>
							<Button type="button" variant="default shadow mr-md-3" onClick={handleClose}>
								Cancel
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</NewBuild>
	)
}

export default NewBuildModal

const NewBuild = styled.div`
	button {
		position: absolute;
		top: 13px;
		right: 70px;
	}
`
