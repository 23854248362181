import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GET_SITE_BY_ID } from '../../../graphql/graphql'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { Layout, Tooltips, PageHeader } from '../../../components'
import { InfoIcon, CheckedIcon } from '../../../images'
import './plan.css'

function PlanPricing() {
  const { siteId } = useParams()
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })
  return (
    <Layout>
      <PageHeader
        pageName="Plans"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Plans', url: `/sites/${siteId}/plans` }
        ]}
      />
      <section id="site-plan" className="component pt-3">
        <Container>
          <div className="data-section">
            <div className="d-block d-md-none">
              <Row className="justify-content-center">
                <Col md={`${6} mt-5`} lg={4}>
                  <div className="bg-white shadow h-100 plan-box">
                    <div className="px-5 py-4 bordered-heading text-center bg-light">
                      <h5>Free</h5>
                      <p className="font-weight-normal small mb-0">
                        Default Plan to get you started
                      </p>
                    </div>
                    <div className="px-5 py-4">
                      <ul className="checked-list plan-table">
                        <li>
                          Standard Template
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Unlimited number of WebriQ forms"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Unlimited number of WebriQ forms"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Pages
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Up to 4 rich media pages with text, graphics, pictures, audio and video"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Up to 4 rich media pages with text, graphics, pictures, audio and video"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Blogs and Articles
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Limited number of blog and article posts"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Limited number of blog and article posts"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          WebriQ Forms
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One single WebriQ form"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One single WebriQ form"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          SNI SSL
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Enable HTTPS for your site. A free SNI SSL Certificate is included in your plan."
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Enable HTTPS for your site. A free SNI SSL Certificate is included in your plan."
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Continuous Deployment
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="WebriQ managed deployment of all content and design changes."
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="WebriQ managed deployment of all content and design changes."
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Subdomain
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Subdomain on www.webriq.me"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Subdomain on www.webriq.me"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Global CDN network
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Hosting on a global super fast content delivery network (CDN)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Hosting on a global super fast content delivery network (CDN)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Private Git repository
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Links your website with a WebriQ Github private repository"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Links your website with a WebriQ Github private repository"
                            />
                          </Tooltips>
                        </li>
                      </ul>
                    </div>
                    <div className="py-3 text-center bg-light">
                      <h5 className="d-inline-block text-muted font-weight-bold">Current Plan</h5>
                    </div>
                  </div>
                </Col>
                <Col md={`${6} mt-5`} lg={4}>
                  <div className="bg-white shadow h-100 plan-box">
                    <div className="px-5 py-4 bordered-heading text-center bg-secondary text-white">
                      <h5>Mad Maintenance</h5>
                      <p className="font-weight-normal small">
                        Maintenance Plan for low traffic Madder and Maddest websites
                      </p>
                      <div>
                        <a
                          className="plan-pricing d-inline-block"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          <h3 className="d-inline-block text-white font-weight-bold">$1500</h3>
                          <span className="yearly-upgrade text-white-50 font-weight-bold">
                            yearly
                            <br />
                            upgrade
                            <br />
                          </span>
                        </a>
                        <a
                          className="font-size-12 d-block mt-1 text-white"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          Upgrade Monthly at $149
                        </a>
                      </div>
                    </div>
                    <div className="px-5 py-4">
                      <ul className="checked-list plan-table">
                        <li>Everying in FREE</li>
                        <li>
                          Custom Design
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-designed responsive homepage"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-designed responsive homepage"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Custom Template
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-developed responsive theme"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-developed responsive theme"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Web Forms
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Web forms integrated with other SaaS services"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Web forms integrated with other SaaS services"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Newsletter Sign-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Build a list of customers that you can reach through e-mail and newsletter marketing"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Build a list of customers that you can reach through e-mail and newsletter marketing"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Google Page Set-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Google Page set-up (optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Google Page set-up (optional)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Yelp Page Set-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Google Page set-up (optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Google Page set-up (optional)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Social Media
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Your website is integrated with social media assets, like Twitter, Facebook, Instagram, YouTube"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Your website is integrated with social media assets, like Twitter, Facebook, Instagram, YouTube"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          E-mail and Helpdesk Support
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Send your request to helpdesk@webriq.us and we will get back to you in 24 hours"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Send your request to helpdesk@webriq.us and we will get back to you in 24 hours"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Custom Domain
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="DNS Hosting on WebriQ CNAME"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="DNS Hosting on WebriQ CNAME"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Domain Aliases
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Point more domains to 1 website (maximum 5)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Point more domains to 1 website (maximum 5)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Additional team members
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Roles and permissions for additional team members (up to 10)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Roles and permissions for additional team members (up to 10)"
                            />
                          </Tooltips>
                        </li>
                      </ul>
                    </div>
                    <div className="py-3 text-center bg-light">
                      <a
                        className="plan-pricing d-inline-block"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        <h3 className="d-inline-block text-secondary font-weight-bold">$1500</h3>
                        <span className="yearly-upgrade text-muted font-weight-bold">
                          yearly
                          <br />
                          upgrade
                          <br />
                        </span>
                      </a>
                      <a
                        className="font-size-12 d-block mt-1"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        Upgrade Monthly at $149
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={`${6} mt-5`} lg={4}>
                  <div className="bg-white shadow h-100 plan-box">
                    <div className="px-5 py-4 bordered-heading text-center bg-info text-white">
                      <h4>Mad</h4>
                      <p className="font-weight-normal small">
                        Small Business Website for Digital Starters
                      </p>
                      <div>
                        <a
                          className="plan-pricing d-inline-block text-white"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          <h3 className="d-inline-block font-weight-bold">$1500</h3>
                          <span className="yearly-upgrade text-white-50 font-weight-bold">
                            yearly
                            <br />
                            upgrade
                            <br />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="px-5 py-4">
                      <ul className="checked-list plan-table">
                        <li>
                          Standard Template
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Standard mobile responsive template"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Standard mobile responsive template"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Custom Design
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-designed responsive homepage"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-designed responsive homepage"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Pages
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Up to 4 rich media pages with text, graphics, pictures, audio and video"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Up to 4 rich media pages with text, graphics, pictures, audio and video"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Blogs and Articles
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Limited number of blog and article posts"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Limited number of blog and article posts"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          WebriQ Forms
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One single WebriQ form"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One single WebriQ form"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Web Forms
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Web forms integrated with other SaaS services"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Web forms integrated with other SaaS services"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Newsletter Sign-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Build a list of customers that you can reach through e-mail and newsletter marketing"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Build a list of customers that you can reach through e-mail and newsletter marketing"
                            />
                          </Tooltips>
                        </li>

                        <li>
                          Social Media
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Your website is integrated with social media assets, like Twitter, Facebook, Instagram, YouTube"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Your website is integrated with social media assets, like Twitter, Facebook, Instagram, YouTube"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          SNI SSL
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Enable HTTPS for your site. A free SNI SSL Certificate is included in your plan."
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Enable HTTPS for your site. A free SNI SSL Certificate is included in your plan."
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Continuous Deployment
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="WebriQ managed deployment of all content and design changes."
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="WebriQ managed deployment of all content and design changes."
                            />
                          </Tooltips>
                        </li>
                        <li>
                          E-mail and Helpdesk Support
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Send your request to helpdesk@webriq.us and we will get back to you in 24 hours"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Send your request to helpdesk@webriq.us and we will get back to you in 24 hours"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Custom Domain
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="DNS hosting on WebriQ CNAME"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="DNS hosting on WebriQ CNAME"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Domain Aliases
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Point more domains to 1 website (maximum 5)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Point more domains to 1 website (maximum 5)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Subdomain
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Subdomain on www.webriq.me"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Subdomain on www.webriq.me"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Global CDN network
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Hosting on a global super fast content delivery network (CDN)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Hosting on a global super fast content delivery network (CDN)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Private Git repository
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Links your website with a WebriQ Github private repository"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Links your website with a WebriQ Github private repository"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Additional team members
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Roles and permissions for additional team members (up to 10)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Roles and permissions for additional team members (up to 10)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Google Analytics
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="High-level analytics support"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="High-level analytics support"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          WebriQ Analytics
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="High-level analytics support"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="High-level analytics support"
                            />
                          </Tooltips>
                        </li>
                      </ul>
                    </div>
                    <div className="py-3 text-center bg-light">
                      <a
                        className="plan-pricing d-inline-block"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        <h3 className="d-inline-block text-info font-weight-bold">$1500</h3>
                        <span className="yearly-upgrade text-muted font-weight-bold">
                          yearly
                          <br />
                          upgrade
                          <br />
                        </span>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={`${6} mt-5`} lg={4}>
                  <div className="bg-white shadow h-100 plan-box">
                    <div className="px-5 py-4 bordered-heading text-center bg-success text-white">
                      <h4>Madder</h4>
                      <p className="font-weight-normal small">
                        Web Design as a Service for growing Businesses
                      </p>
                      <div>
                        <a
                          className="plan-pricing d-inline-block"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          <h3 className="d-inline-block font-weight-bold text-white">$3990</h3>
                          <span className="yearly-upgrade text-white-50 font-weight-bold">
                            yearly
                            <br />
                            upgrade
                            <br />
                          </span>
                        </a>
                        <a
                          className="font-size-12 d-block mt-1 text-white"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          Upgrade Monthly at $399
                        </a>
                      </div>
                    </div>
                    <div className="px-5 py-4">
                      <ul className="checked-list plan-table">
                        <li>Everything in MAD</li>
                        <li>
                          Custom Template
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-developed responsive theme"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-developed responsive theme"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Google Page Set-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Google Page Set-up (Optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Google Page Set-up (Optional)"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Yelp Page Set-up
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Yelp Page Set-up (Optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Yelp Page Set-up (Optional)"
                            />
                          </Tooltips>
                        </li>
                      </ul>
                    </div>
                    <div className="py-3 text-center bg-light">
                      <a
                        className="plan-pricing d-inline-block"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        <h3 className="d-inline-block text-success font-weight-bold">$3990</h3>
                        <span className="yearly-upgrade text-muted font-weight-bold">
                          yearly
                          <br />
                          upgrade
                          <br />
                        </span>
                      </a>
                      <a
                        className="font-size-12 d-block mt-1"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        Upgrade Monthly at $399
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={`${6} mt-5`} lg={4}>
                  <div className="bg-white shadow h-100 plan-box">
                    <div className="px-5 py-4 bordered-heading text-center bg-danger text-white">
                      <h4>Maddest</h4>
                      <p className="font-weight-normal small">
                        Websites as a Service and Content Marketing for the mid market
                      </p>
                      <div>
                        <a
                          className="plan-pricing d-inline-block"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          <h3 className="d-inline-block font-weight-bold text-white">$6990</h3>
                          <span className="yearly-upgrade text-white-50 font-weight-bold">
                            yearly
                            <br />
                            upgrade
                            <br />
                          </span>
                        </a>
                        <a
                          className="font-size-12 d-block mt-1 text-white"
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          Upgrade Monthly at $699
                        </a>
                      </div>
                    </div>
                    <div className="px-5 py-4">
                      <ul className="checked-list plan-table">
                        <li>Everything in MADDER</li>
                        <li>
                          Trello Support
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Request new builds and other related service issues through Trello project Management Software"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Request new builds and other related service issues through Trello project Management Software"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Slack Support
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Request new builds and other related issues through a secure and private Slack channel"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Request new builds and other related issues through a secure and private Slack channel"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Chat Bot
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Development of a Facebook Messenger Chat Bot integrated to the website"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Development of a Facebook Messenger Chat Bot integrated to the website"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          SEO/Content Marketing
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Publishing of up to 2,000 words a month of relevant blog, forum and social media posts"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Publishing of up to 2,000 words a month of relevant blog, forum and social media posts"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Landing Pages
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Up to 5 custom-designed landing pages for campaign marketing"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Up to 5 custom-designed landing pages for campaign marketing"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Newsletter Marketing
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Design, management and marketing of 1 newsletter per month"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Design, management and marketing of 1 newsletter per month"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Keyword Ranking Report
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Bi-monthly reporting on keyword rankings for search engines"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Bi-monthly reporting on keyword rankings for search engines"
                            />
                          </Tooltips>
                        </li>
                        <li>
                          Google Search Console
                          <Tooltips
                            placement="left"
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Bi-monthly reporting on Google Search Console impressions, clicks and rankings"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Bi-monthly reporting on Google Search Console impressions, clicks and rankings"
                            />
                          </Tooltips>
                        </li>
                      </ul>
                    </div>
                    <div className="py-3 text-center bg-light">
                      <a
                        className="plan-pricing d-inline-block"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        <h3 className="d-inline-block text-danger font-weight-bold">$6990</h3>
                        <span className="yearly-upgrade text-muted font-weight-bold">
                          yearly
                          <br />
                          upgrade
                          <br />
                        </span>
                      </a>
                      <a
                        className="font-size-12 d-block mt-1"
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        Upgrade Monthly at $699
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="d-none d-md-block">
              <div className="table-responsive shadow pricing-table">
                <Table responsive striped hover>
                  <thead>
                    <tr className="border-danger">
                      <th className="w-25" />
                      <th className="text-center w-15">
                        <h6 className="font-weight-bold">Free</h6>
                        <p className="font-weight-normal small">Default Plan to get you started</p>
                      </th>
                      <th className="text-center w-15">
                        <h4>Mad</h4>
                        <p className="font-weight-normal small">
                          Small Business Website for Digital Starters
                        </p>
                      </th>
                      <th className="text-center w-15">
                        <h4>Madder</h4>
                        <p className="font-weight-normal small mb-0">
                          Web Design as a Service for growing Businesses
                        </p>
                      </th>
                      <th className="text-center w-15">
                        <h4>Maddest</h4>
                        <p className="font-weight-normal small mb-0">
                          Web Design as a Service and Content Marketing for the mid market
                        </p>
                      </th>
                      <th className="text-center w-15">
                        <h6 className="font-weight-bold">Mad Maintenance</h6>
                        <p className="font-weight-normal small">
                          Maintenance Plan for low traffic Madder and Maddest websites
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        <span className="pr-2">Features</span>
                      </td>
                      <td className="text-center w-15 align-middle">
                        <span className="text-muted">Free</span>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-secondary font-weight-bold">
                              $1500
                            </h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-info font-weight-bold">$3990</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $399
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-success font-weight-bold">$6990</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $699
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-danger font-weight-bold">$1500</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $149
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Standard Template</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Standard mobile responsive template"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Standard mobile responsive template"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Custom Design</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-designed responsive homepage/theme"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-designed responsive homepage"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <span className="text-small">Home Page Only</span>
                      </td>
                      <td className="text-center bg-success" colSpan="3">
                        <span className="text-small text-white">Custom Theme Design</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Custom Template</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="One custom-developed responsive theme"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="One custom-developed responsive theme"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img
                          className="img-fluid mx-auto d-block"
                          src={CheckedIcon}
                          alt="checked"
                          width="18px"
                        />
                      </td>
                      <td className="text-center">
                        <img
                          className="img-fluid mx-auto d-block"
                          src={CheckedIcon}
                          alt="checked"
                          width="18px"
                        />
                      </td>
                      <td className="text-center">
                        <img
                          className="img-fluid mx-auto d-block"
                          src={CheckedIcon}
                          alt="checked"
                          width="18px"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Pages</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Rich Media Pages with text, graphics, pictures, audio and video"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Rich Media Pages with text, graphics, pictures, audio and video"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <span className="text-small">Limited Pages</span>
                      </td>
                      <td className="text-center bg-success" colSpan="3">
                        <span className="text-small text-white">Unlimited Number of Pages</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Blogs and Articles</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Blog and Article Posts"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Blog and Article Posts"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <span className="text-small">Limited Posts Only</span>
                      </td>
                      <td className="text-center bg-success" colSpan="3">
                        <span className="text-small text-white">Unlimited Number of Posts</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">WebriQ Forms</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="WebriQ forms"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="WebriQ forms"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <span className="text-small">Limited Form Only</span>
                      </td>
                      <td className="text-center bg-success" colSpan="3">
                        <span className="text-small text-white">
                          Unlimited Number of WebriQ Forms
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Web Forms</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Web forms integrated with other SaaS services"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Web forms integrated with other SaaS services"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Newsletter Sign-up</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Build a list of customers that you can reach through E-mail and Newsletter Marketing"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Build a list of customers that you can reach through E-mail and Newsletter Marketing"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Google Page Set-up</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Google Page Set-up (Optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Google Page Set-up (Optional)"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Yelp Page Set-up</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Yelp Page Set-up (Optional)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Yelp Page Set-up (Optional)"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Social Media</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Your website is integrated with your social media assets, like Twitter, Facebook, Instagram, Youtube"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Your website is integrated with your social media assets, like Twitter, Facebook, Instagram, Youtube"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">SNI SSL</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Enable HTTPS for your site. A Free SNI SSL Certificate is included in your plan"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Enable HTTPS for your site. A Free SNI SSL Certificate is included in your plan"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Continuous Deployment</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="WebriQ managed deployment of all content and design changes"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="WebriQ managed deployment of all content and design changes"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">E-mail and Helpdesk Support</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Sent your request to helpdesk@webriq.us and we will get back to you within 24 hours "
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Sent your request to helpdesk@webriq.us and we will get back to you within 24 hours "
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Trello Support</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Request new builds and other related service issues through Trello project Management Software"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Request new builds and other related service issues through Trello project Management Software"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Slack Support</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Request new builds and other related service issues through a secure and private Slack channel"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Request new builds and other related service issues through a secure and private Slack channel"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Custom Domain</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="DNS hosting on WebriQ CNAME"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="DNS hosting on WebriQ CNAME"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Domain Aliases</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Point more domains to one website (maximum 5)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Point more domains to one website (maximum 5)"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Subdomain</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Subdomain on webriq.me"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Subdomain on webriq.me"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Global CDN Network</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Hosting on a Global super fast Content Delivery Network (CDN)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Hosting on a Global super fast Content Delivery Network (CDN)"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Private Git Repository</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Links your website with WebriQ Github Private Repository"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Links your website with WebriQ Github Private Repository"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Additional Team Members</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Roles and permissions for additional team members (up to 10)"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Roles and permissions for additional team members (up to 10)"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Chat Bot</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="CHAT BOT integrated in to the website or Facebook Messenger"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="CHAT BOT integrated in to the website or Facebook Messenger"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">SEO/Content Marketing</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Publishing of up to 2,000 words a month of relevant blog, forum and social media posts"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Publishing of up to 2,000 words a month of relevant blog, forum and social media posts"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Landing Pages</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Up to 5 Custom Designed landing pages for campaign marketing"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Up to 5 Custom Designed landing pages for campaign marketing"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Newsletter Marketing</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Design, management and marketing of 1 newsletter per month"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Design, management and marketing of 1 newsletter per month"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Google Analytics</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="High Level Analytics Support"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="High Level Analytics Support"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">WebriQ Analytics</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="High Level Analytics Support"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="High Level Analytics Support"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Keyword Ranking Report</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Bi-monthly reporting on Keyword Rankings for search engines"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Bi-monthly reporting on Keyword Rankings for search engines"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td>
                        <div className="plan-detail">
                          <span className="pr-2">Google Search Console</span>
                          <Tooltips
                            className="link btn-tooltip shadow-none p-0 m-0"
                            text="Bi-monthly reporting on Google Search console Impressions, Clicks and Rankings"
                          >
                            <img
                              className="img-fluid"
                              src={InfoIcon}
                              width="16px"
                              alt="Bi-monthly reporting on Google Search console Impressions, Clicks and Rankings"
                            />
                          </Tooltips>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center">
                        <img className="img-fluid" src={CheckedIcon} alt="checked" width="18px" />
                      </td>
                      <td className="text-center" />
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <h5>Choose your plan:</h5>
                      </td>
                      <td className="text-center w-15 align-middle">
                        <span className="text-muted">Current Plan</span>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-secondary font-weight-bold">
                              $1500
                            </h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-info font-weight-bold">$3990</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $399
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-success font-weight-bold">$6990</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $699
                          </a>
                        </div>
                      </td>
                      <td className="text-center w-15">
                        <div>
                          <a
                            className="plan-pricing d-inline-block"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            <h3 className="d-inline-block text-danger font-weight-bold">$1500</h3>
                            <span className="yearly-upgrade text-muted font-weight-bold">
                              yearly
                              <br />
                              upgrade
                              <br />
                            </span>
                          </a>
                          <a
                            className="font-size-12 d-block mt-1"
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                            }}
                          >
                            Upgrade Monthly at $149
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PlanPricing
