import React from 'react'
import { Col } from 'react-bootstrap'
import { Panel, AnalyticsVisitsOverview } from '../../index'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

const VisitsOverviewComponent = props => (
	<Col xs={12} sm={6}>
		<Panel
			title="Visits overview"
			className="accordion-setting bg-transparent"
			classButton="text-left h6 w-100 shadow-none m-0 border-0"
		>
			{get(props.domainsData, 'site.domains.length') === 0 ? (
				<p className="text-muted">
					Please add{' '}
					<Link className="text-primary" to={`/sites/${props.siteId}/settings`}>
						new domain
					</Link>{' '}
					to access Webriq Analytics.
				</p>
			) : null}

			{get(props.domainsData, 'site.domains.length') > 0 && !props.visitsOverTime ? (
				<p className="text-muted">
					Please enable{' '}
					<Link className="text-primary" to={`/sites/${props.siteId}/analytics`}>
						Webriq Analytics
					</Link>{' '}
					to access Webriq Analytics data.
				</p>
			) : null}

			{props.visitsOverTime ? (
				<AnalyticsVisitsOverview visitsOverTime={props.visitsOverTime} />
			) : null}
		</Panel>
	</Col>
)

export default VisitsOverviewComponent
