import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { Panel } from '@app/index'
import { DeleteIcon } from '../../../images'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { LoadingPanel, ErrorPanel, CustomForm } from '@app/index'

export default function MembersPanel({ siteId }) {
  // Modal functionality
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  // Invite new member
  let input
  let role
  const [newSiteMember, { loading: newSiteMemberLoading }] = useMutation(NEW_SITE_MEMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_MEMBERS_DETAILS,
        variables: { siteId }
      }
    ]
  })
  const handleSubmit = e => {
    e.preventDefault()
    if (!subscription && !isSuperAdmin) {
      console.error('Oops... action requires site subscription!')
      return
    }

    setShow(false)
    newSiteMember({ variables: { siteId, email: input.value, role: role.value } })
    input.value = ''
    role.value = ''
  }

  // Delete member
  const [siteMemberId, setSiteMemberId] = useState(null)
  const [
    removeSiteMember,
    { loading: removeSiteMemberLoading, error: removeSiteMemberError }
  ] = useMutation(REMOVE_SITE_MEMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SITE_MEMBERS_DETAILS,
        variables: { siteId }
      }
    ]
  })
  const handleDelete = (e, memberId) => {
    e.preventDefault()
    if (!subscription && !isSuperAdmin) {
      console.error('Oops... action requires site subscription!')
      return
    }

    setSiteMemberId(memberId)
    removeSiteMember({ variables: { siteId, memberId } })
  }
  useEffect(() => {
    if (!removeSiteMemberLoading) {
      setSiteMemberId(null)
    }
  }, [removeSiteMemberLoading])

  // Get all members
  const { data, loading, error } = useQuery(GET_SITE_MEMBERS_DETAILS, {
    variables: {
      siteId
    }
  })

  if (loading) {
    return <LoadingPanel title="Members" />
  }

  if (error) {
    return <ErrorPanel title="Members" error={error} />
  }

  const {
    site: { members },
    site: { subscription },
    me: { isSuperAdmin }
  } = data

  const invitedMembers = members.filter(member => member && member.status === 'sent')
  const acceptedMembers = members.filter(member => member && member.status === 'accepted')

  return (
    <Panel
      title="Members"
      className="accordion-setting bg-transparent"
      classButton="text-left h6 w-100 shadow-none m-0 border-0"
    >
      <div className="content">
        <Row>
          <Col md={12} lg={6}>
            {!invitedMembers.length > 0 && (
              <Row className="pb-4">
                <Col xs={9}>
                  <p className="font-weight-bold mb-0">Invitations</p>
                  <span className="d-block text-muted small">
                    No invited members yet. Why not <a href="#">invite one?</a>
                  </span>
                </Col>
              </Row>
            )}

            {invitedMembers.length > 0 && (
              <React.Fragment>
                <Row className="pb-0 align-content-center">
                  <Col xs={12}>
                    <p className="font-weight-bold mb-0">Invitations</p>
                    <Row className="pb-4 align-content-center">
                      <Col xs={5}>
                        <span className="text-muted">Email</span>
                      </Col>
                      <Col xs={`${4} text-center`}>
                        <span className="text-muted">Role</span>
                      </Col>
                      <Col xs={`${3} text-center`}>
                        <span className="text-muted">Actions</span>
                      </Col>
                    </Row>

                    {invitedMembers.map(member => (
                      <Row className="pb-3 align-items-center" key={member.id}>
                        <Col xs={12} md={5}>
                          <span className="hyphens-auto">{member.email}</span>
                        </Col>
                        <Col xs={`${9} text-center`} md={4}>
                          <span className="bg-base text-white rounded p-1 d-block">
                            {member.role.name}
                          </span>
                        </Col>
                        <Col className="col-3 text-center">
                          <a href="/" onClick={e => handleDelete(e, member.id)}>
                            {(siteMemberId && siteMemberId === member.id && (
                              <Spinner animation="border" variant="primary" />
                            )) || (
                              <img
                                alt="delete"
                                className="img-fluid"
                                src={DeleteIcon}
                                width="24px"
                                height="24px"
                              />
                            )}
                          </a>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </React.Fragment>
            )}

            {newSiteMemberLoading && (
              <Row className="pb-3 align-items-center">
                <Col xs={12} md={5}>
                  <ContentLoader height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="80%" height="20" />
                  </ContentLoader>
                </Col>
                <Col xs={`${9} text-center`} md={4}>
                  <ContentLoader height="30">
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="35" />
                  </ContentLoader>
                </Col>
              </Row>
            )}
          </Col>

          <Col md={`${12} pl-lg-5`} lg={6}>
            {!acceptedMembers.length > 0 && (
              <Row className="pb-4">
                <Col xs={9}>
                  <p className="font-weight-bold mb-0">Members</p>
                  <span className="d-block text-muted small">
                    No members yet. Users who accepted invitation to collaborate will be listed
                    here.
                  </span>
                </Col>
              </Row>
            )}

            {acceptedMembers.length > 0 && (
              <React.Fragment>
                <Row className="pb-4 align-content-center">
                  <Col xs={5}>
                    <span className="text-muted">Email</span>
                  </Col>
                  <Col xs={`${4} text-center`}>
                    <span className="text-muted">Role</span>
                  </Col>
                  <Col xs={`${3} text-center`}>
                    <span className="text-muted">Actions</span>
                  </Col>
                </Row>
                {acceptedMembers.map(member => (
                  <Row className="pb-3 align-items-center" key={member.id}>
                    <Col xs={12} md={5}>
                      <span className="hyphens-auto">{member.email}</span>
                    </Col>
                    <Col xs={`${9} text-center`} md={4}>
                      <span className="bg-base text-white rounded p-1 d-block">
                        {member && member.role && member.role.name}
                      </span>
                    </Col>
                    <Col className="col-3 text-center">
                      <a href="/">
                        <img
                          alt="delete"
                          className="img-fluid"
                          src={DeleteIcon}
                          width="24px"
                          height="24px"
                        />
                      </a>
                    </Col>
                  </Row>
                ))}
              </React.Fragment>
            )}
          </Col>
        </Row>

        {(isSuperAdmin || subscription) && (
          <Row className="mt-4 pb-4">
            <Col xs={12} className="text-right">
              <Button variant="primary" onClick={handleShow}>
                New Member Invitation
              </Button>

              <Modal show={show} onHide={handleClose} centered animation={false}>
                <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
                  <p>{null}</p>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <div className="form-container bg-white border-0">
                    <div className="form-heading mb-4 text-center">
                      <h4 className="font-weight-normal">Invite site member to collaborate</h4>
                      <p className="text-muted">
                        An email will be sent to user's inbox when invited successfully.
                      </p>
                    </div>
                    <CustomForm onSubmit={handleSubmit}>
                      <Form.Group>
                        <input
                          className="form-control"
                          type="email"
                          required
                          ref={node => {
                            input = node
                          }}
                        />
                        <label>Email address: john@example.com</label>
                      </Form.Group>
                      {/** @todo: Make roles dynamic when you get the time */}
                      <Form.Group>
                        <Form.Label>Select role</Form.Label>
                        <Form.Control
                          as="select"
                          ref={node => {
                            role = node
                          }}
                        >
                          <option value="owner">Owner</option>
                          <option value="admin">Admin</option>
                          <option value="developer">Developer</option>
                          <option value="editor">Editor</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className="text-center mt-4 mb-0">
                        <Button type="submit">Confirm Send Invitation</Button>
                      </Form.Group>
                    </CustomForm>
                  </div>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        )}

        {!isSuperAdmin && !subscription && (
          <Row>
            <Col md={`${12} text-center text-md-left mb-3`}>
              <Button variant="warning">Requires Plan Subscription</Button>
            </Col>
          </Row>
        )}
      </div>
    </Panel>
  )
}

const NEW_SITE_MEMBER = gql`
  mutation inviteSiteMember($siteId: ID!, $email: String!, $role: String!) {
    inviteSiteMember(siteId: $siteId, email: $email, role: $role) {
      id
      email
      status
    }
  }
`

const REMOVE_SITE_MEMBER = gql`
  mutation removeSiteMemberOrInvite($siteId: ID!, $memberId: ID!) {
    removeSiteMemberOrInvite(siteId: $siteId, memberId: $memberId)
  }
`

const GET_SITE_MEMBERS_DETAILS = gql`
  query site($siteId: ID!) {
    me {
      id
      isSuperAdmin
    }
    site(id: $siteId) {
      id
      subscription {
        id
      }
      members {
        id
        email
        status
        role {
          id
          name
        }
      }
    }
  }
`
