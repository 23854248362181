import React from 'react'
import { Container, Alert } from 'react-bootstrap'

function Error(props) {
	return (
		<Container>
			<Alert variant="danger">Opppss! Something went wrong!</Alert>
		</Container>
	)
}

export default Error
