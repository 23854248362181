import React from "react"
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap"

function Tooltips(props) {
  return (
    <OverlayTrigger
      trigger={props.trigger ? props.trigger : "click"}
      placement={props.placement ? props.placement : "top"}
      overlay={
        <Tooltip id="tooltip-top">
          {props.text}
        </Tooltip>
      }
    >
      <Button variant={props.className}>
        {props.children}
      </Button>
    </OverlayTrigger>
  )
}

export default Tooltips