import React, { useState } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { DeleteIcon } from '../../../images'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { LoadingPanel, ErrorPanel, CustomForm } from '@app/index'

export default function BuildEnvironmentVariables({ siteId }) {
  // Update Build Environment Variables
  const [showBuildEnvironmentVariablesModal, setShowBuildEnvironmentVariablesModal] = useState(
    false
  )
  const handleShowBuildEnvironmentVariablesModal = e => {
    e.preventDefault()
    setShowBuildEnvironmentVariablesModal(true)
  }
  const handleCloseBuildEnvironmentVariablesModal = () =>
    setShowBuildEnvironmentVariablesModal(false)

  let newBuildEnvironmentVariableKey, newBuildEnvironmentVariableValue
  const handleUpdateBuildEnvironmentVariables = e => {}

  // Get environment variables
  const { data, loading, error } = useQuery(GET_SITE_PROVIDER_BUILD_ENVIRONMENT_VARIABLES, {
    variables: {
      siteId
    }
  })

  if (error) {
    return <ErrorPanel title="Build Environment Variables" error={error} />
  }

  let providerBuildEnvironmentVariables = []
  if (data) {
    providerBuildEnvironmentVariables =
      data && data.site && data.site.providerBuildEnvironmentVariables
  }

  return (
    <Row className="pb-4 align-content-center">
      <Col xs={12}>
        <p className="font-weight-bold mb-0">Build Environment Variables</p>
        <span className="d-block text-muted small mb-4">
          {' '}
          Update variables that describe the environment of which your site builds. Useful for
          settings such as API secret keys and any other sensitive data that should not be committed
          on your repo.
        </span>

        {loading && !Object.keys(providerBuildEnvironmentVariables).length > 0 && (
          <span className="d-block text-muted small">No environment variables yet.</span>
        )}

        {!loading &&
          Object.keys(providerBuildEnvironmentVariables).length > 0 &&
          Object.keys(providerBuildEnvironmentVariables).map(key => (
            <Row className="pb-3 align-items-center" key={key}>
              <Col xs={12} md={6}>
                <span className="hyphens-auto">{key}</span>
              </Col>
              <Col xs={12} md={4}>
                <span className="d-block text-muted">{providerBuildEnvironmentVariables[key]}</span>
              </Col>
              <Col className="col-2 text-right">
                <a href="/">
                  <img
                    alt="delete"
                    className="img-fluid"
                    src={DeleteIcon}
                    width="24px"
                    height="24px"
                  />
                </a>
              </Col>
            </Row>
          ))}

        {loading && (
          <Row className="pb-3 align-items-center">
            <Col xs={12} md={6}>
              <ContentLoader height="20">
                <rect x="0" y="0" rx="3" ry="3" width="80%" height="30" />
              </ContentLoader>
            </Col>
            <Col>
              <ContentLoader height="20">
                <rect x="0" y="0" rx="3" ry="3" width="60%" />
              </ContentLoader>
            </Col>
          </Row>
        )}
      </Col>

      <Col xs={`${12} text-right`}>
        <a
          className="text-base"
          href="/"
          onClick={e => handleShowBuildEnvironmentVariablesModal(e)}
        >
          New Env Variable
        </a>
      </Col>

      <Modal
        show={showBuildEnvironmentVariablesModal}
        onHide={handleCloseBuildEnvironmentVariablesModal}
        centered
        animation={false}
      >
        <Modal.Header bsPrefix="modal-header border-0 bg-transparent" closeButton>
          <p>{null}</p>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="form-container bg-white border-0">
            <div className="form-heading mb-4 text-center">
              <h4 className="font-weight-normal">Setup build environment variables to your site</h4>
              <p className="text-muted">
                Describe the environment of which your site builds. Useful for settings such as api
                secret keys and any other sensitive data that should not be committed on your site's
                repo.
              </p>
            </div>
            <CustomForm onSubmit={handleUpdateBuildEnvironmentVariables}>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  ref={node => {
                    newBuildEnvironmentVariableKey = node
                  }}
                />
                <label>Key</label>
              </Form.Group>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  required
                  ref={node => {
                    newBuildEnvironmentVariableValue = node
                  }}
                />
                <label>Value</label>
              </Form.Group>
              <Form.Group className="text-center mt-4 mb-0">
                <Button type="submit">Confirm Save</Button>
              </Form.Group>
            </CustomForm>
          </div>
        </Modal.Body>
      </Modal>
    </Row>
  )
}

const GET_SITE_PROVIDER_BUILD_ENVIRONMENT_VARIABLES = gql`
  query site($siteId: ID!) {
    site(id: $siteId) {
      id
      providerBuildEnvironmentVariables
    }
  }
`
