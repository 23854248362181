import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GET_SITE_BY_ID } from '../../../graphql/graphql'
import {
  Layout,
  PageHeader,
  SiteMembersPanel,
  SiteDeveloperSettingsPanel,
  SiteGeneralPanel,
  SiteDomainsPanel
  // LoadingPanel
} from '../../../components'
import { Container, Row, Col } from 'react-bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import './setting.css'

function Settings(props) {
  const { siteId } = useParams()
  const { data: siteData, loading: siteLoading } = useQuery(GET_SITE_BY_ID, {
    variables: {
      siteId
    }
  })

  return (
    <Layout>
      <PageHeader
        pageName="Settings"
        siteData={siteLoading ? 'fetching' : siteData}
        siteId={siteId}
        list={[
          { order: 1, name: 'Dashboard', url: '/dashboard' },
          { order: 2, name: 'Site Management', url: `/sites/${siteId}` },
          { order: 3, name: 'Settings', url: `/sites/${siteId}/settings` }
        ]}
      />
      <section id="site-settings" className="component pt-3">
        <Container>
          <div className="data-section">
            <Row>
              <Col md={`${12} mb-5 mb-md-0`} lg={6}>
                <SiteGeneralPanel siteId={siteId} />
              </Col>

              <Col md={`${12} mb-5 mb-md-0`} lg={6}>
                <SiteDomainsPanel siteId={siteId} />
              </Col>

              <Col md={`${12} mb-5 mb-md-0`}>
                <SiteMembersPanel siteId={siteId} />
              </Col>

              <Col md={`${12}`}>
                <SiteDeveloperSettingsPanel siteId={siteId} />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Settings
