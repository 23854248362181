import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { MenuIcon } from '../../images'

class CardComponent extends React.Component {
  render() {
    const {
      siteImgData,
      siteUrlId,
      siteLinkData,
      siteTitle,
      sitePrefix,
      siteText,
      siteId,
      sitePlan,
      siteData,
      siteRole
    } = this.props

    let status, anchorLink, logoLink
    if (
      siteLinkData.innerUrl !== '' &&
      siteLinkData.innerUrl !== undefined &&
      siteLinkData.innerUrl !== null
    ) {
      anchorLink = (
        <Link className={sitePrefix === 'b-colored' ? 'text-white' : ''} to={siteLinkData.innerUrl}>
          {siteTitle}
        </Link>
      )
      logoLink = (
        <Link className="item-logo-text text-primary text-uppercase" to={siteLinkData.innerUrl}>
          {siteImgData.imgSrc ? (
            <img
              className="logo img-fluid"
              src={siteImgData.imgSrc}
              width={siteImgData.width ? siteImgData.width : '70px'}
              height={siteImgData.height ? siteImgData.height : '70px'}
              alt={siteImgData.imgAlt}
            />
          ) : (
            siteTitle.charAt(0)
          )}
        </Link>
      )
    } else if (
      siteLinkData.linkUrl !== '' &&
      siteLinkData.linkUrl !== undefined &&
      siteLinkData.linkUrl !== null
    ) {
      anchorLink = (
        <a
          className={sitePrefix === 'b-colored' ? 'text-white' : ''}
          href={`//${siteLinkData.linkUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {siteTitle}
        </a>
      )
      logoLink = (
        <a
          className="item-logo-text text-primary"
          href={`//${siteLinkData.linkUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {siteImgData.imgSrc ? (
            <img
              className="logo img-fluid"
              src={siteImgData.imgSrc}
              width={siteImgData.width ? siteImgData.width : '70px'}
              height={siteImgData.height ? siteImgData.height : '70px'}
              alt={siteImgData.imgAlt}
            />
          ) : (
            siteTitle.charAt(0)
          )}
        </a>
      )
    } else {
      logoLink = <div>{siteLinkData.modalLink}</div>
      anchorLink = siteTitle
    }

    status = (
      <React.Fragment>
        <div className="text-truncate item-domain">
          <a
            className="text-primary"
            href={`//${siteLinkData.linkUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {siteLinkData.linkUrl}
          </a>
        </div>
        <div className="item-status">
          <p className="text-secondary text-small">
            <i
              className={`
                fas fa-circle text-small text-danger
                ${siteData.siteStatus === 'Compiling error' && 'text-danger'}
                ${siteData.siteStatus === 'Building site' && 'text-primary'}
                ${siteData.siteStatus === 'Site not live' && 'text-warning'}
                ${siteData.siteStatus === 'Site not live' && 'text-warning'}
                ${!['Compiling error', 'Building site', 'Site not live'].includes(
                  siteData.siteStatus
                ) && 'text-success'}
              `}
            />
            &nbsp; {siteData.siteStatus}
          </p>
        </div>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <div className={`card shadow border-0 ${sitePrefix ? sitePrefix : 'bg-white'}`} id={siteId}>
          <div className="card-resizer" />
          <div className={`card-heading ${siteData.siteStatus ? '' : 'invisible'}`}>
            <Link className="item-plan" to={`/sites/${siteUrlId}/plans`}>
              <span
                className={`badge float-left mt-2 ${
                  sitePlan === 'Free' ? 'badge-success' : 'badge-primary'
                }`}
              >
                {sitePlan}
              </span>
            </Link>
            <Link className="item-plan" to={`/sites/${siteUrlId}/settings`}>
              <span className="badge float-left mt-2 badge-info ml-1 text-capitalize">
                {siteRole}
              </span>
            </Link>
            <Dropdown className="ml-auto text-right">
              <Dropdown.Toggle
                className="shadow-none bg-transparent border-0 p-0"
                style={{ width: '20px' }}
              >
                <img className="img-fluid" src={MenuIcon} alt="menu" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="border rounded border-white shadow">
                <Link className="dropdown-item" to={`/sites/${siteUrlId}/content`}>
                  Edit Content
                </Link>
                <Link className="dropdown-item" to={`/sites/${siteUrlId}/forms`}>
                  Manage Form
                </Link>
                <Link className="dropdown-item" to={`/sites/${siteUrlId}/analytics`}>
                  View Analytics
                </Link>
                <Link className="dropdown-item" to={`/sites/${siteUrlId}/settings`}>
                  Settings
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card-body">
            <div className="item-logo">{logoLink}</div>
            <div className="item-name">
              <h6 className="text-truncate">{anchorLink}</h6>
            </div>
            {siteData.siteStatus ? (
              status
            ) : (
              <div className="item-status">
                <p className={`mb-0 ${sitePrefix === 'b-colored' ? 'text-white' : ''}`}>
                  {siteText}
                </p>
              </div>
            )}
          </div>
          <div
            className={`card-footer pt-0 bg-white border-0 ${
              !siteData.siteStatus ? 'invisible' : ''
            }`}
          >
            <div className="row hide">
              <div className="col-6">
                <h6 className="mb-0">
                  <a href="/site/analytics.html">
                    {siteData.analytics ? siteData.analytics : 0}
                    {siteData.currentAnalytics ? (
                      <span className="text-success">+{siteData.currentAnalytics}</span>
                    ) : (
                      ''
                    )}
                  </a>
                </h6>
                <p className="text-muted text-small mb-0">Analytics</p>
              </div>
              <div className="col-6">
                <h6 className="mb-0">
                  <a href="/site/forms.html">
                    {siteData.formSubmissions ? siteData.formSubmissions : 0}
                    {siteData.currentSubmissions ? (
                      <span className="text-success data-preview-current font-weight-medium">
                        +{siteData.currentSubmissions}
                      </span>
                    ) : (
                      ''
                    )}
                  </a>
                </h6>
                <p className="text-truncate text-muted text-small mb-0">Forms Submissions</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CardComponent
