import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Panel } from '../../index'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

const WebriqAnalyticsComponent = props => {
	return (
		<Panel
			title="WebriQ Analytics"
			subTitle="Powered by Matomo, we bring to you a much simpler way to view your site analytics data."
			className="accordion-setting bg-transparent"
			classButton="text-left h6 w-100 shadow-none m-0 border-0"
		>
			{get(props.domainsData, 'site.domains.length') === 0 ? (
				<p className="text-muted">
					Please add{' '}
					<Link className="text-primary" to={`/sites/${props.siteId}/settings`}>
						new domain
					</Link>{' '}
					to access Webriq Analytics.
				</p>
			) : null}

			{get(props.domainsData, 'site.domains.length') > 0 && !props.visitsSummary ? (
				<p className="text-muted">
					Please enable{' '}
					<Link className="text-primary" to={`/sites/${props.siteId}/analytics`}>
						Webriq Analytics
					</Link>{' '}
					to access Webriq Analytics data.
				</p>
			) : null}

			{props.visitsSummary ? (
				<Row className="mb-4">
					<Col xs={12} sm={6} lg={3}>
						<div className="card-border">
							<p>
								<strong>Visits</strong>
							</p>
							<div className="d-flex justify-content-between">
								<div className="text-center">
									<p className="data-counter mb-0">{get(props.visitsSummary, 'nb_visits', '0')}</p>
									<label className="text-muted text-small">Total</label>
								</div>
								<div className="text-center">
									<p className="data-counter mb-0">
										{get(props.visitsSummary, 'nb_uniq_visitors', '0')}
									</p>
									<label className="text-muted text-small">Unique</label>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} lg={3}>
						<div className="card-border">
							<p>
								<strong>Page views</strong>
							</p>
							<div className="d-flex justify-content-between">
								<div className="text-center">
									<p className="data-counter mb-0">
										{get(props.visitsSummary, 'nb_pageviews', '0')}
									</p>
									<label className="text-muted">Total</label>
								</div>
								<div className="text-center">
									<p className="data-counter mb-0">
										{get(props.visitsSummary, 'nb_uniq_pageviews', '0')}
									</p>
									<label className="text-muted text-small">Unique</label>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} lg={3}>
						<div className="card-border">
							<p>
								<strong>Avg. Session Duration</strong>
								<br />
							</p>
							<div className="d-flex justify-content-between">
								<div className="text-center">
									<p className="data-counter mb-0">
										{get(props.visitsSummary, 'avg_time_on_site', '0')}
									</p>
									<label className="text-muted text-small">in seconds</label>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} lg={3}>
						<div>
							<p>
								<strong>Bounce Rate</strong>
								<br />
							</p>
							<div className="d-flex justify-content-between">
								<div className="text-center">
									<p className="data-counter mb-0">67%</p>
									<label className="text-muted text-small">Total</label>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			) : null}
		</Panel>
	)
}

export default WebriqAnalyticsComponent
